import { gql } from '@apollo/client';

export const UPDATE_INIT_TEXT = gql`
  mutation updateInitText(
    $id: ID!
    $editedText: String!
    $reConverted: Boolean
  ) {
    updateInitText(
      id: $id
      editedText: $editedText
      reConverted: $reConverted
    ) {
      id
      textbookId
      text
      completed
      reConverted
    }
  }
`;
