import PropTypes from 'prop-types';
import React from 'react';

export const SelectorCloseIcon = ({
  width = 16,
  height = 11,
  onClick,
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      className={className}
    >
      <path
        d='M8 0.879028L0.938995 7.93903L3.061 10.061L8 5.12103L12.939 10.061L15.061 7.93903L8 0.879028Z'
        fill='#0B486B'
      />
    </svg>
  );
};

SelectorCloseIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
