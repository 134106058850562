import { gql } from '@apollo/client';

export const CONCERTINH_INIT_TEXT_STATUS = gql`
  subscription convertingInitTextStatus($textbookId: ID!) {
    convertingInitTextStatus(textbookId: $textbookId) {
      finished
      percent_done
      msg
    }
  }
`;
