import { theme } from 'constants/index';
import { makeStyles } from '@material-ui/core';

export default makeStyles({
  menu: {
    position: 'absolute',
    height: '100%',
    top: 0,
    maxWidth: 496,
    width: '35vw',
    backgroundColor: theme.colors.paleBlue,
    padding: 16,
    paddingLeft: 0,
  },
  titleWrapper: {
    margin: '15px 20px',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    lineHeight: '28px',
    fontSize: '24px',
  },
  paragraphIcon: {
    marginRight: '6px',
  },
  input: {
    background: 'rgba(255, 255, 255, 0.1)',
    borderColor: 'rgba(255, 255, 255, 0.1)',
    color: 'white',
    borderRadius: '8px',
  },
  classNameOutline: {
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
  chapterWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px 20px 0px 40px',
    color: '#D6DDE0',
    fontSize: '13px',
    fontWeight: 'normal',
    lineHeight: '160%',
  },
  iconArrow: {
    cursor: 'pointer',
  },
  paragraphWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px 20px 0px 75px',
    color: '#D6DDE0',
    fontSize: '13px',
    fontWeight: 'normal',
    lineHeight: '160%',
    '&:hover': {
      textDecorationLine: 'underline',
    },
    cursor: 'pointer',
  },
});
