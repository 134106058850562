import { ButtonCustom } from 'components';
import { Box } from '@material-ui/core';
import useStyles from './styles';
import { ArrowLeftDarkIcon, Addicon } from 'icons';
import { useCreateBookContext } from 'context';

export const ButtonSave = () => {
  const classes = useStyles();
  const { addInsetr, setAddInsert, createInsert } = useCreateBookContext();

  // eslint-disable-next-line no-unused-vars
  const contentState = createInsert?.lengthSelectSubstring > 0;

  return (
    <Box style={{ display: 'flex' }}>
      {addInsetr && (
        <ButtonCustom
          classNameCustom={classes.buttonCustom}
          reverseStyle={true}
          onClick={() => {
            setAddInsert(false);
          }}
        >
          <ArrowLeftDarkIcon
            className={classes.iconButton}
            width={20}
            height={20}
          />
          К списку примеров
        </ButtonCustom>
      )}
      <ButtonCustom
        type='submit'
        onClick={() => {
          // if (!contentState) return;
          setAddInsert(true);
        }}
        classNameCustom={classes.buttonCustom}
        style={{ marginLeft: 'auto' }}
      >
        <Addicon color='white' style={{ marginRight: '10px' }} />
        {'Добавить'}
      </ButtonCustom>
    </Box>
  );
};
