import { makeStyles } from '@material-ui/core';
import { theme } from 'constants/index';

export default makeStyles({
  button: {
    backgroundColor: theme.colors.blue,
    color: theme.colors.white,
    minWidth: '140px',
    minHeight: '32px',
    '&:hover': {
      backgroundColor: theme.colors.white,
      color: theme.colors.blue,
      border: `1px solid ${theme.colors.blue}`,
    },
    whiteSpace: 'nowrap',
    border: `1px solid ${theme.colors.blue}`,
  },
  buttonReverse: {
    backgroundColor: theme.colors.white,
    color: theme.colors.blue,
    minWidth: '140px',
    height: '32px',
    border: `1px solid ${theme.colors.blue}`,
    '&:hover': {
      backgroundColor: theme.colors.blue,
      color: theme.colors.white,
      border: `1px solid ${theme.colors.blue}`,
    },
    whiteSpace: 'nowrap',
  },
});
