import { makeStyles } from '@material-ui/core/styles';
// import { theme } from 'constants/index';

export const useStyles = makeStyles({
  soundWrapper: {
    marginTop: '20px',
    marginBottom: '25px',
  },
  soundWrapperButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  soundIcon: {
    marginLeft: '10px',
  },
  buttonCustom: {
    width: '190px',
    height: '40px',
    fontSize: '14px',
  },
  playButton: {
    minWidth: '48px',
    height: '51px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  playIcon: { marginLeft: '3px' },
  nameWrapper: {
    width: '200px',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '16px',
  },
  nameWrapperEnd: {
    marginRight: '15px',
  },
  nameWrapperStart: {
    marginLeft: '15px',
  },
  nextBottonWrapper: {
    display: 'flex',
    textAlign: 'center',
  },
  titleButton: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.4)',
  },
  titleButtonEnd: {
    textAlign: 'end',
    marginRight: '35px',
  },
  titleButtonStart: {
    marginLeft: '30px',
  },
  timeWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
    marginBottom: '15px',
  },
});
