import PropTypes from 'prop-types';
import React, { createContext } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { ROUTES } from 'constants/index';
import { useAuthContext } from 'context';

import {
  NotFound,
  Home,
  DevUiPage,
  Login,
  CreateBookStepTwo,
  CreateBookStepOne,
  CreateBookStepTree,
  CreateBookStepFour,
  CreateBookStepFive,
  CreateBookStepSix,
} from 'pages/index';

const RouterContext = createContext();

const routes = [
  { path: ROUTES.home, component: Home, exact: true },
  {
    path: ROUTES.createBookStepOne + ':id?',
    component: CreateBookStepOne,
    exact: true,
  },
  {
    path: ROUTES.createBookStepTwo + ':id?',
    component: CreateBookStepTwo,
    exact: true,
  },
  {
    path: ROUTES.createBookStepTree + ':id?',
    component: CreateBookStepTree,
    exact: true,
  },
  {
    path: ROUTES.createBookStepFour + ':id?',
    component: CreateBookStepFour,
    exact: true,
  },
  {
    path: ROUTES.createBookStepFive + ':id?',
    component: CreateBookStepFive,
    exact: true,
  },
  {
    path: ROUTES.createBookStepSix + ':id?',
    component: CreateBookStepSix,
    exact: true,
  },

  { path: ROUTES.login, component: Login, exact: true },
  { path: ROUTES.public, component: Home, exact: true },
  { path: ROUTES.devUiPage, component: DevUiPage, exact: true },
];

export function RouterProvider() {
  return (
    <RouterContext.Provider value={{}}>
      <BrowserRouter>
        <Switch>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              render={(props) => <ProtectedRoute {...props} {...route} />}
            />
          ))}
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </RouterContext.Provider>
  );
}

const ProtectedRoute = ({ path, component: Component, ...props }) => {
  const { isAuth } = useAuthContext();

  if (path === ROUTES.login) {
    if (isAuth) return <Redirect to={ROUTES.home} />;
    return <Component {...props} />;
  }
  if (isAuth) {
    return <Component {...props} />;
  }
  return <Redirect to={ROUTES.login} />;
};

RouterProvider.propTypes = {
  children: PropTypes.node,
};

ProtectedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.any.isRequired,
};
