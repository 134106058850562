import useStyles from './styles';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { TextField, SelectCustom } from 'components';
import { SearchIcon2 } from 'icons/index';
import { optionConstructor } from 'utils';

export const HomeFilter = ({ filter, handleSetFilter, dataSelect }) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapperFilter}>
      <TextField
        value={filter.inputValue}
        placeholder='Название, автор, тема'
        onChange={handleSetFilter}
        filterKey='inputValue'
        className={classes.input}
        icon={<SearchIcon2 />}
        name='inputValue'
      />
      <SelectCustom
        items={optionConstructor(
          dataSelect?.subjects,
          { label: 'Категория', value: '' },
          'name',
          'id',
        )}
        value={filter.category}
        filterKey='category'
        handleSelectChange={handleSetFilter}
        className={classes.select}
        width={320}
      />
      <SelectCustom
        items={optionConstructor(
          dataSelect?.years,
          { label: 'Год выпуска', value: '' },
          '',
          '',
        )}
        value={filter.year}
        filterKey='year'
        handleSelectChange={handleSetFilter}
        className={classes.select}
        width={320}
      />
    </Box>
  );
};

HomeFilter.propTypes = {
  filter: PropTypes.object,
  handleSetFilter: PropTypes.func,
  dataSelect: PropTypes.object,
};
