import {
  Layout,
  ShowStepCreate,
  EditTextTextarea,
  EditTextPreview,
  NextButton,
  RequestHandler,
} from 'components';
import { useStyles } from './styles';
import { Box, Grid } from '@material-ui/core';
import { ROUTES } from 'constants/index';
import { useAddOrUpdateParagraph } from 'hooks/use-mutations';
import { useGetTextbookById } from 'hooks/use-queries';
import { useParagraph, useParagraphAsaide, useCurrentText } from 'hooks';
import { useParams } from 'react-router';
import { wirishApi } from 'utils';

export const CreateBookStepTree = () => {
  const { id } = useParams();
  const {
    data: dataBook,
    loading: loadingBook,
    error: errorBook,
  } = useGetTextbookById(id);

  const { paragraphList, currentParagraph, setCurrentParagraph } = useParagraph(
    dataBook?.getTextbookById,
  );

  const { textValue, setTextValue } = useCurrentText(
    currentParagraph,
    paragraphList,
    paragraphList[currentParagraph],
  );

  useParagraphAsaide(dataBook, setCurrentParagraph);

  const [
    mutationParagraph,
    { loading: loadingParagraph, error: errorParagraph },
  ] = useAddOrUpdateParagraph(() => {});

  const classes = useStyles();

  const hancleSetText = (value) => {
    setTextValue(value);
  };

  const handleSubmit = () => {
    mutationParagraph({
      variables: {
        id: paragraphList[currentParagraph].id,
        name: paragraphList[currentParagraph].name,
        chapterId: paragraphList[currentParagraph].chapterId,
        content: wirishApi.parseAfterEditor(textValue),
        parsed: true,
      },
    });
  };

  return (
    <Layout>
      <Box className={classes.wrapperOuter}>
        <RequestHandler
          loading={loadingBook || loadingParagraph}
          error={errorBook || errorParagraph}
        >
          <ShowStepCreate
            bookName={dataBook?.getTextbookById?.title}
            onSubmit={handleSubmit}
          />
          <Box className={classes.wrapperInner} sx={{ flexGrow: 1 }}>
            <Grid container>
              <Grid item xs={6} md={6}>
                <EditTextTextarea
                  setTextValue={hancleSetText}
                  textValue={textValue}
                  setCurrentParagraph={setCurrentParagraph}
                  paragraphList={paragraphList}
                  currentParagraph={currentParagraph}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <EditTextPreview textValue={textValue} />
              </Grid>
            </Grid>
          </Box>
          <NextButton
            back={{
              path: ROUTES.createBookStepTwo,
              text: 'Структура книги',
            }}
            next={{
              path: ROUTES.createBookStepFour,
              text: 'Формулы',
            }}
          />
        </RequestHandler>
      </Box>
    </Layout>
  );
};
