import { GET_INSERTS } from 'graphql/query/index';
import { useQuery } from '@apollo/client';

export const useGetInserts = (paragraphId, type = undefined) => {
  return useQuery(GET_INSERTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      paragraphId: parseInt(paragraphId),
      types: type,
    },
    skip: paragraphId ? false : true,
  });
};
