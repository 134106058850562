import { theme } from 'constants/index';
import { makeStyles } from '@material-ui/core';

export default makeStyles({
  aside: {
    display: 'flex',
    backgroundColor: theme.colors.paleBlue,
    // height: '100%',
    padding: '24px 12px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    zIndex: 2,
    bottom: '0px',
  },
  item: {
    marginBottom: 16,
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  itemButton: {
    cursor: 'pointer',
  },
});
