import PropTypes from 'prop-types';
import useStyles from 'components/table-of-contents/styles';
import { Box } from '@material-ui/core';
import { EditIcon, TrashIcon } from 'icons';

export const EditDropdown = ({ setEditMode, deleteAction }) => {
  const classes = useStyles();

  return (
    <Box className={classes.dropdownContent}>
      <Box
        className={classes.dropdownContentRow}
        onClick={() => {
          setEditMode(true);
        }}
      >
        <EditIcon width={15} height={15} />
        <Box component='span' className={classes.dropdownContentText}>
          редактировать
        </Box>
      </Box>

      <Box
        onClick={() => {
          deleteAction();
        }}
        className={classes.dropdownContentRow}
      >
        <TrashIcon width={15} height={15} />
        <Box component='span' className={classes.dropdownContentText}>
          удалить
        </Box>
      </Box>
    </Box>
  );
};

EditDropdown.propTypes = {
  setEditMode: PropTypes.func,
  deleteAction: PropTypes.func,
};
