import { gql } from '@apollo/client';

export const FIND_PARAGRAPHS = gql`
  query findParagraphs($search: String!) {
    findParagraphs(search: $search) {
      id
      name
      content
      audio
      audioDuration
      chapterId
      chapter {
        id
        name
      }
      bookmark {
        id
        paragraphId
        time
      }
      inserts {
        id
      }

      parsed
    }
  }
`;
