import PropTypes from 'prop-types';
import React from 'react';

export const CloseIcon = ({
  width = 10,
  height = 10,
  color = 'white',
  className,
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M6.0575 4.9999L9.2825 1.7824C9.42373 1.64117 9.50307 1.44962 9.50307 1.2499C9.50307 1.05017 9.42373 0.858623 9.2825 0.717395C9.14127 0.576167 8.94973 0.496826 8.75 0.496826C8.55027 0.496826 8.35873 0.576167 8.2175 0.717395L5 3.9424L1.7825 0.717395C1.64127 0.576167 1.44973 0.496826 1.25 0.496826C1.05027 0.496826 0.858728 0.576167 0.7175 0.717395C0.576272 0.858623 0.496931 1.05017 0.496931 1.2499C0.496931 1.44962 0.576272 1.64117 0.7175 1.7824L3.9425 4.9999L0.7175 8.2174C0.647204 8.28712 0.591408 8.37007 0.553332 8.46146C0.515255 8.55286 0.495651 8.65089 0.495651 8.7499C0.495651 8.8489 0.515255 8.94693 0.553332 9.03833C0.591408 9.12972 0.647204 9.21267 0.7175 9.28239C0.787222 9.35269 0.870173 9.40849 0.961567 9.44656C1.05296 9.48464 1.15099 9.50424 1.25 9.50424C1.34901 9.50424 1.44704 9.48464 1.53843 9.44656C1.62983 9.40849 1.71278 9.35269 1.7825 9.28239L5 6.0574L8.2175 9.28239C8.28722 9.35269 8.37017 9.40849 8.46157 9.44656C8.55296 9.48464 8.65099 9.50424 8.75 9.50424C8.84901 9.50424 8.94704 9.48464 9.03843 9.44656C9.12983 9.40849 9.21278 9.35269 9.2825 9.28239C9.3528 9.21267 9.40859 9.12972 9.44667 9.03833C9.48475 8.94693 9.50435 8.8489 9.50435 8.7499C9.50435 8.65089 9.48475 8.55286 9.44667 8.46146C9.40859 8.37007 9.3528 8.28712 9.2825 8.2174L6.0575 4.9999Z'
        fill={color}
      />
    </svg>
  );
};

CloseIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
