/* eslint-disable no-useless-escape */
// eslint-disable-next-line prettier/prettier
export const separatorMathjaxBefore = `\(`;
export const separatorMathjaxAfter = `\)`;
export const LIMIT = 10;

export const SERVER_ERROR_LIST = [
  {
    type: 'Default error',
    text: 'Произошла ошибка :(',
  },
  {
    type: 'This login is already in use.',
    text: 'Такой email уже есть в системе',
  },
];

export const separatorMathjaxBeforeNew = '&#8291;';
export const separatorMathjaxAfterNew = '&#8192;';

export const startParagraph = '#%';
export const endParagraph = '%#';
