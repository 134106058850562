import { makeStyles } from '@material-ui/core';

export default makeStyles({
  wrapperFilter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '30px',
  },
  input: {
    width: '800px',
    height: '32px',
    ['@media (max-width:810px)']: {
      width: '320px',
    },
  },
  select: {
    width: '200px',
  },
});
