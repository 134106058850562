import PropTypes from 'prop-types';
import React from 'react';

export const LinkIcon = ({
  width = 18,
  height = 19,
  color = '#0B486B',
  className,
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M10.4533 13.5953C10.4181 13.5604 10.3705 13.5408 10.3209 13.5408C10.2713 13.5408 10.2237 13.5604 10.1885 13.5953L7.46504 16.3187C6.2041 17.5797 4.07598 17.7133 2.68379 16.3187C1.28926 14.9242 1.42285 12.7984 2.68379 11.5375L5.40723 8.81404C5.47988 8.74139 5.47988 8.62186 5.40723 8.5492L4.47441 7.61639C4.43917 7.58149 4.39159 7.56192 4.34199 7.56192C4.2924 7.56192 4.24481 7.58149 4.20957 7.61639L1.48613 10.3398C-0.49668 12.3226 -0.49668 15.5312 1.48613 17.5117C3.46895 19.4922 6.67754 19.4945 8.65801 17.5117L11.3814 14.7883C11.4541 14.7156 11.4541 14.5961 11.3814 14.5234L10.4533 13.5953ZM16.5143 2.48592C14.5314 0.503107 11.3229 0.503107 9.34238 2.48592L6.6166 5.20936C6.58171 5.2446 6.56213 5.29219 6.56213 5.34178C6.56213 5.39137 6.58171 5.43896 6.6166 5.4742L7.54707 6.40467C7.61973 6.47733 7.73926 6.47733 7.81191 6.40467L10.5354 3.68123C11.7963 2.42029 13.9244 2.2867 15.3166 3.68123C16.7111 5.07576 16.5775 7.20154 15.3166 8.46248L12.5932 11.1859C12.5583 11.2212 12.5387 11.2687 12.5387 11.3183C12.5387 11.3679 12.5583 11.4155 12.5932 11.4508L13.526 12.3836C13.5986 12.4562 13.7182 12.4562 13.7908 12.3836L16.5143 9.66014C18.4947 7.67733 18.4947 4.46873 16.5143 2.48592ZM11.2994 6.72576C11.2642 6.69087 11.2166 6.67129 11.167 6.67129C11.1174 6.67129 11.0698 6.69087 11.0346 6.72576L5.72598 12.032C5.69108 12.0673 5.67151 12.1148 5.67151 12.1644C5.67151 12.214 5.69108 12.2616 5.72598 12.2969L6.6541 13.225C6.72676 13.2976 6.84629 13.2976 6.91895 13.225L12.2252 7.91873C12.2979 7.84608 12.2979 7.72654 12.2252 7.65389L11.2994 6.72576Z'
        fill={color}
      />
    </svg>
  );
};

LinkIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
