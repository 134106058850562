/* eslint-disable no-unused-vars */
import lumin from 'lumin';
import { useState, useEffect } from 'react';
import { useCreateBookContext } from 'context';

export const useLuminator = (
  refText,
  currentParagraph,
  isPlaying,
  duration,
  currentTime,
  audioPlayer,
) => {
  const [luminator, setLuminator] = useState(null);
  const [changeAction, setChangeAction] = useState(true);
  const { createInsert } = useCreateBookContext();
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    luminator?.stop();
    setChangeAction(true);
    setLuminator(lumin(refText.current));
  }, [currentParagraph]);

  const playLiminator = () => {
    luminator.stop(); // fix nonstop
    if (isPlaying) {
      luminator.start(duration * 1000);
    } else {
      luminator.stop();
    }
  };

  // reinitialize luminator for change select formula
  useEffect(() => {
    if (!luminator) return;
    playLiminator();
    let currentTimeLocal = audioPlayer?.current.currentTime;
    if (currentValue === createInsert?.contentPositionStart) return;
    setCurrentValue(createInsert?.contentPositionStart);
    const luminLocal = lumin(refText.current);
    setLuminator(luminLocal);
    let procent = duration / 100;
    let currentProcent = currentTimeLocal / procent;
    luminLocal.progress = currentProcent;
  }, [createInsert?.contentPositionStart]);

  useEffect(() => {
    setChangeAction(false);
    if (!luminator || changeAction) return;
    playLiminator();
  }, [isPlaying, duration]);

  const setLuminatorProgress = (changeValue) => {
    if (!changeValue) return;
    let procent = duration / 100;
    let currentProcent = changeValue / procent;
    luminator.progress = currentProcent;
    playLiminator(); // fix notstart
  };
  return { setLuminatorProgress };
};
