import { theme } from 'constants/index';
import { makeStyles } from '@material-ui/core';

export default makeStyles({
  wrapperOuter: {
    backgroundColor: theme.colors.white,
    border: '1px solid rgba(0, 0, 0, 0.2)',
    padding: '24px',
    marginRight: '10px',
  },
  wrapperHead: {
    height: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.colors.black,
    fontSize: '18px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    marginBottom: '15px',
  },
  icon: {
    cursor: 'pointer',
  },
  wrapperText: {
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    backgroundColor: '#F6F6F6',

    height: '460px',
    // padding: '20px 8px 8px 8px',
    overflowY: 'auto',
    scrollbarColor: '#9FB6C1 #EAE8E8',
    scrollbarWidth: 'thin !important',
    '&::-webkit-scrollbar': {
      width: '4px',
      backgroundColor: '#EAE8E8',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#9FB6C1',
      borderRadius: 2,
    },
    '&::selection': {
      color: theme.colors.black,
      background: theme.colors.lightBlue,
    },
  },
  wrapperButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '8px',
  },
  textarea: {
    height: '100%',
    backgroundColor: '#F6F6F6',
  },
  outline: {
    border: 'none',
    padding: '0px',
  },
  iconSave: {
    marginRight: '10px',
  },
});
