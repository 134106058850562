import { Box } from '@material-ui/core';
import useStyles from './styles';
import { ArrowRightDarkIcon, ArrowLeftDarkIcon } from 'icons';
import PropTypes from 'prop-types';
import { nextParagraph, createInsertList } from 'utils';
import { useCreateBookContext } from 'context';

export const AddExamples = ({
  textValue = '',
  setCurrentParagraph,
  paragraphList,
  currentParagraph,
}) => {
  const classes = useStyles();
  const { insertList } = useCreateBookContext();

  return (
    <Box className={classes.wrapperOuter}>
      <Box className={classes.wrapperHead}>
        <ArrowLeftDarkIcon
          className={classes.icon}
          onClick={() =>
            setCurrentParagraph(
              nextParagraph(paragraphList, currentParagraph, 'back'),
            )
          }
        />
        {paragraphList[currentParagraph]?.name}
        <ArrowRightDarkIcon
          className={classes.icon}
          onClick={() =>
            setCurrentParagraph(
              nextParagraph(paragraphList, currentParagraph, 'next'),
            )
          }
        />
      </Box>
      <Box className={classes.wrapperText}>
        <Box
          dangerouslySetInnerHTML={{
            __html: String.raw`${createInsertList(textValue, insertList)}`,
          }}
        />
      </Box>
    </Box>
  );
};

AddExamples.propTypes = {
  textValue: PropTypes.string,
  setCurrentParagraph: PropTypes.func,
  paragraphList: PropTypes.object,
  currentParagraph: PropTypes.any,
};
