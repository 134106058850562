import { makeStyles } from '@material-ui/core/styles';
import { theme } from 'constants/index';

export const useStyles = makeStyles({
  wrapperOuter: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
    border: '1px solid rgba(0, 0, 0, 0.1)',
    padding: '24px',
  },
  navigationWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '15px',
  },
  navigationText: {
    color: theme.colors.black,
    fontSize: '18px',
    lineHeight: '21px',
  },
  line: {
    height: '1px',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    marginBottom: '15px',
  },
  textWrapper: {
    position: 'relative',
    height: '320px',
    overflowY: 'auto',
    scrollbarColor: '#9FB6C1 #EAE8E8',
    scrollbarWidth: 'thin !important',
    '&::-webkit-scrollbar': {
      width: '4px',
      backgroundColor: '#EAE8E8',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#9FB6C1',
      borderRadius: 2,
    },
    '&::selection': {
      color: theme.colors.black,
      background: theme.colors.lightBlue,
    },
    whiteSpace: 'pre-wrap',
  },
  infoWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  infoText: {
    marginLeft: '10px',
    color: theme.colors.black,
    fontSize: '14px',
    lineHeight: '16px',
  },
  iconWrapper: {
    cursor: 'pointer',
  },
});
