import React from 'react';

export const DeleteMarkerIcon = () => {
  return (
    <svg
      width='16'
      height='15'
      viewBox='0 0 16 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.6667 8.1666C13.3333 8.1666 14.6667 9.49994 14.6667 11.1666C14.6667 12.8333 13.3333 14.1666 11.6667 14.1666C9.99999 14.1666 8.66666 12.8333 8.66666 11.1666C8.66666 9.49994 9.99999 8.1666 11.6667 8.1666ZM11.6667 9.1666C11.2933 9.1666 10.9467 9.2666 10.6667 9.4466L13.3867 12.1666C13.5667 11.8866 13.6667 11.5399 13.6667 11.1666C13.6667 10.6362 13.4559 10.1275 13.0809 9.75239C12.7058 9.37732 12.1971 9.1666 11.6667 9.1666ZM9.66666 11.1666C9.66666 11.697 9.87737 12.2057 10.2524 12.5808C10.6275 12.9559 11.1362 13.1666 11.6667 13.1666C12.04 13.1666 12.3867 13.0666 12.6667 12.8866L9.94666 10.1666C9.76666 10.4466 9.66666 10.7933 9.66666 11.1666ZM12.3333 0.266602C12.6667 0.266602 13 0.393268 13.26 0.653268L15.1533 2.53993C15.6667 3.0666 15.6667 3.9066 15.1533 4.4266L12.6333 6.93994C11.7891 6.74945 10.9072 6.81443 10.1 7.1266L7.50666 4.53327L11.38 0.653268C11.64 0.393268 11.98 0.266602 12.3333 0.266602ZM6.86666 5.1666L9.25999 7.5666C8.6672 7.96158 8.18116 8.49687 7.84504 9.12491C7.50893 9.75294 7.33315 10.4543 7.33332 11.1666C7.33332 11.4999 7.37332 11.8333 7.43999 12.1333L6.66666 12.9133C6.14666 13.4266 5.31332 13.4333 4.79332 12.9266L4.21999 13.4999H0.446655L2.90666 11.0399C2.37332 10.4999 2.37332 9.65993 2.89332 9.13994L6.86666 5.1666Z'
        fill='#0B486B'
      />
    </svg>
  );
};
