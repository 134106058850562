import { makeStyles } from '@material-ui/core';
import { theme } from 'constants/index';

export default makeStyles({
  wrapperOuter: {
    marginTop: '20px',
    width: '214px',
    height: '284px',
    backgroundColor: theme.colors.dropdownBackground,
    borderRadius: '16px',
    padding: '10px',
  },
  wrapperInner: {
    width: '100%',
    height: '100%',
    border: `1px dashed ${theme.colors.blue}`,
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  dropzone: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
  },
  icon: {
    marginBottom: '30px',
  },
  open: {
    color: theme.colors.paleBlue,
    cursor: 'pointer',
    marginTop: '5px',
  },
  iconClose: {
    cursor: 'pointer',
    marginTop: '10px',
  },
  wrapperOuterBook: {
    marginTop: '20px',
    width: '100%',
    height: '204px',
    backgroundColor: theme.colors.dropdownBackground,
    borderRadius: '16px',
    padding: '10px',
  },
  wrapperOuterFlex: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  andText: { margin: '24px 24px' },
  textDescription: {
    color: 'rgba(0, 0, 0, 0.4)',
    textAlign: 'center',
    marginTop: '24px',
  },
  fileSuccess: {
    backgroundColor: theme.colors.dropdownBackground,
    width: '100%',
    height: '110px',
    padding: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '16px',
  },
  fileSuccessInner: {
    display: 'flex',
    color: theme.colors.black,
    fontSize: '12px',
  },
  fileSize: {
    color: 'rgba(0, 0, 0, 0.4)',
    paddingTop: '8px',
    textAlign: 'start',
    marginLeft: '30px',
  },
  fileName: {
    marginLeft: '30px',
  },
  fileDownloadWrapper: {
    width: '100%',
  },
  fileDownload: {
    width: '100%',
    margin: '14px 0px 24px 0px',
    color: theme.colors.gray,
  },
  wrapperOuterFlexRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  wrapperOuterImage: {
    marginTop: '20px',
    width: '260px',
    height: '108px',
    backgroundColor: theme.colors.dropdownBackground,
    borderRadius: '16px',
    padding: '10px',
  },

  iconImage: {
    marginBottom: '10px',
    marginTop: '5px',
  },
  linkInput: {
    width: '100%',
  },
  fileSuccessInsert: {
    backgroundColor: theme.colors.dropdownBackground,
    width: '100%',
    height: '85px',
    padding: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '16px',
  },
});
