import { gql } from '@apollo/client';

export const PARAGRAPH = gql`
  fragment FragmentParagraphs on Paragraph {
    id
    name
    content
    audio
    audioDuration
    chapterId
    parsed
    chapter {
      id
      name
    }
    bookmark {
      id
      paragraphId
      time
    }
    inserts {
      id
      paragraphId
      audioStart
      audioEnd
      contentPositionStart
      contentPositionEnd
      name
      content
      source
    }
  }
`;
