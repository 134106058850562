import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';

export const ButtonCustom = ({
  children,
  onClick,
  reverseStyle,
  disabled = false,
  classNameCustom,
  style,
  type,
}) => {
  const classes = useStyles();
  return (
    <Button
      onClick={onClick}
      className={[
        reverseStyle ? classes.buttonReverse : classes.button,
        classNameCustom ? classNameCustom : '',
      ].join(' ')}
      type={type}
      variant='contained'
      disabled={disabled}
      style={style}
    >
      {children}
    </Button>
  );
};

ButtonCustom.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  reverseStyle: PropTypes.bool,
  disabled: PropTypes.bool,
  classNameCustom: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
};
