import useStyles from 'components/table-of-contents/styles';
import { Box } from '@material-ui/core';
import { SelectorOpenIcon, SelectorCloseIcon, Addicon } from 'icons';
import PropTypes from 'prop-types';
import { Paragraph } from 'components/table-of-contents/paragraph/paragraph';
import { EditDropdown } from 'components/table-of-contents/edit-dropdown/edit-dropdown';
import { AddForm } from 'components/table-of-contents/paragraph/add-form';
import { useState } from 'react';
import { Dropdown } from 'components';
import { useDeleteChapter } from 'hooks';

export const Chapter = ({ chapterItem }) => {
  const [addChapter, setAddChapter] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const classes = useStyles();

  const [openChildren, handleOpen] = useState(false);
  const [deleteChaptersMutation] = useDeleteChapter(chapterItem.id);

  const handleDelete = () => {
    deleteChaptersMutation({
      variables: {
        id: chapterItem.id,
      },
    }).catch((error) => {
      console.info(error);
    });
  };

  return (
    <Box component='span'>
      <Box className={classes.parargaphLine}>
        <Box>{`${chapterItem?.name}`}</Box>
        {openChildren ? (
          <Box className={classes.dropdownWrappr} component='span'>
            {editMode ? (
              <AddForm
                setAdd={setEditMode}
                type='Chapter'
                chapterId={chapterItem.id}
              />
            ) : (
              <Dropdown classOuter={classes.dropdownWrapprInner}>
                <EditDropdown
                  deleteAction={handleDelete}
                  id={chapterItem.id}
                  setEditMode={setEditMode}
                />
              </Dropdown>
            )}

            <SelectorCloseIcon
              onClick={() => {
                handleOpen(false);
              }}
              className={[classes.icon, classes.ml_10].join(' ')}
            />
          </Box>
        ) : (
          <SelectorOpenIcon
            onClick={() => {
              handleOpen(true);
            }}
            className={classes.icon}
          />
        )}
      </Box>
      <Box
        className={classes.parargaphChildWrapper}
        style={{
          display: openChildren ? 'block' : 'none',
        }}
      >
        {chapterItem?.paragraphs?.map((chapterItem, index) => {
          return (
            <Paragraph
              key={index + 'ehild'}
              className={classes.parargaphChildLine}
              paragraphItem={chapterItem}
              chapterId={chapterItem.id}
            />
          );
        })}
        {addChapter ? (
          <AddForm
            setAdd={setAddChapter}
            type='Paragraph'
            chapterId={chapterItem.id}
          />
        ) : (
          <Box
            className={classes.addElement}
            onClick={() => setAddChapter(!addChapter)}
          >
            <Addicon
              className={classes.addElementIcon}
              onClick={() => setAddChapter(!addChapter)}
            />
            Добавить главу
          </Box>
        )}
      </Box>
    </Box>
  );
};

Chapter.propTypes = {
  chapterItem: PropTypes.object,
  openChildren: PropTypes.object,
  handleOpen: PropTypes.func,
  chapterId: PropTypes.string,
};
