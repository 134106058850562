import { Box } from '@material-ui/core';
import useStyles from './styles';
import { InsertList } from './inser-list/insert-list';
import { AddInsertForm } from './add-insert-form/add-insert-form';
import { useCreateBookContext } from 'context';
import PropTypes from 'prop-types';

export const AddList = ({ refetchInsert }) => {
  const classes = useStyles();

  const height = '630px';
  const { addInsetr } = useCreateBookContext();

  return (
    <Box className={classes.wrapperOuter} style={{ height: height }}>
      {addInsetr ? (
        <AddInsertForm height={height} refetchInsert={refetchInsert} />
      ) : (
        <InsertList height={height} />
      )}
    </Box>
  );
};

AddList.propTypes = {
  refetchInsert: PropTypes.func,
};
