import { theme } from 'constants/index';
import { makeStyles } from '@material-ui/core';

export default makeStyles({
  preloadeWrapper: {
    position: 'relative',
    height: 580,
  },
  wrapperOuter: {
    backgroundColor: theme.colors.white,
    border: '1px solid rgba(0, 0, 0, 0.2)',
    padding: '24px',
    marginRight: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  wrapperHead: {
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.black,
    fontSize: '18px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    marginBottom: '15px',
  },
  icon: {
    cursor: 'pointer',
  },
  wrapperText: {
    overflow: 'auto',
    height: '470px',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    paddingTop: '10px',
    overflowY: 'auto',
    scrollbarColor: '#9FB6C1 #EAE8E8',
    scrollbarWidth: 'thin !important',
    '&::-webkit-scrollbar': {
      width: '4px',
      backgroundColor: '#EAE8E8',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#9FB6C1',
      borderRadius: 2,
    },
    '&::selection': {
      color: theme.colors.black,
      background: theme.colors.lightBlue,
    },
  },
  wrapperInsert: {
    color: '#4D6E7D',
    marginBottom: '10px',
  },
  textTitle: {
    color: 'rgba(0, 0, 0, 0.4)',
    fontSize: '14px',
    lineHeight: '16px',
    maxHeight: '32px',
  },
  buttonSearchWrapper: {
    display: 'flex',
    justifyContent: 'center',
    margin: '13px 0px',
    width: '100%',
  },
  buttonCustomLeft: {
    borderRadius: '8px 0px 0px 8px',
    borderColor: '#4D6E7D',
    width: '50%',
    textTransform: 'capitalize',
    background: (props) =>
      props.typeDropzone === 'imageInsert' ? '#4D6E7D' : 'white',
    color: (props) =>
      props.typeDropzone === 'imageInsert' ? 'white' : '#5C5C5C',
    '&:hover': {
      background: (props) =>
        props.typeDropzone === 'imageInsert' ? '#4D6E7D' : 'white',
      color: (props) =>
        props.typeDropzone === 'imageInsert' ? 'white' : '#5C5C5C',
    },
  },
  buttonCustomRight: {
    borderRadius: '0px 8px 8px 0px',
    borderColor: '#4D6E7D',
    width: '50%',
    textTransform: 'capitalize',
    background: (props) =>
      props.typeDropzone === 'videoInsert' ? '#4D6E7D' : 'white',
    color: (props) =>
      props.typeDropzone === 'videoInsert' ? 'white' : '#5C5C5C',
    '&:hover': {
      background: (props) =>
        props.typeDropzone === 'videoInsert' ? '#4D6E7D' : 'white',
      color: (props) =>
        props.typeDropzone === 'videoInsert' ? 'white' : '#5C5C5C',
    },
  },
  buttonCustom: {
    textTransform: 'inherit',
    height: '32px',
  },
  inputDescription: {
    height: '50px',
    border: '1px solid #C5D7E0',
    borderRadius: '8px',
    padding: '5px',
  },
  iconButton: {
    marginRight: '10px',
  },
  titleSearch: {
    color: '#303030',
    lineHeight: '146%',
    fontSize: '16px',
    fontWeight: 'normal',
  },
  inputSong: {
    width: '100%',
    margin: '0px',
  },
});
