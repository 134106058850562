import PropTypes from 'prop-types';
import React from 'react';

export const DropzoneFileIcon = ({ width = 40, height = 38, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 40 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20 0C16.7006 0.0103463 13.5137 1.20034 11.015 3.355C9.1 5.005 7.7125 7.155 7.355 9.3125C3.165 10.2375 0 13.8875 0 18.295C0 23.415 4.27 27.5 9.4525 27.5H18.75V14.2675L13.385 19.635C13.1503 19.8697 12.8319 20.0016 12.5 20.0016C12.1681 20.0016 11.8497 19.8697 11.615 19.635C11.3803 19.4003 11.2484 19.0819 11.2484 18.75C11.2484 18.4181 11.3803 18.0997 11.615 17.865L19.115 10.365C19.2311 10.2486 19.3691 10.1562 19.5209 10.0932C19.6728 10.0302 19.8356 9.99777 20 9.99777C20.1644 9.99777 20.3272 10.0302 20.4791 10.0932C20.6309 10.1562 20.7689 10.2486 20.885 10.365L28.385 17.865C28.6197 18.0997 28.7516 18.4181 28.7516 18.75C28.7516 19.0819 28.6197 19.4003 28.385 19.635C28.1503 19.8697 27.8319 20.0016 27.5 20.0016C27.1681 20.0016 26.8497 19.8697 26.615 19.635L21.25 14.2675V27.5H31.72C36.255 27.5 40 23.925 40 19.4325C40 15.3425 36.895 12.01 32.915 11.4475C32.3075 4.9975 26.725 0 20 0ZM18.75 36.25V27.5H21.25V36.25C21.25 36.5815 21.1183 36.8995 20.8839 37.1339C20.6495 37.3683 20.3315 37.5 20 37.5C19.6685 37.5 19.3505 37.3683 19.1161 37.1339C18.8817 36.8995 18.75 36.5815 18.75 36.25Z'
        fill='#0B486B'
      />
    </svg>
  );
};

DropzoneFileIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
};
