import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

export const CreateBookContext = createContext();
export const useCreateBookContext = () => {
  const context = useContext(CreateBookContext);
  if (!context) {
    throw new Error(
      'useCreateBookContext must be used within a CreateBookProvider',
    );
  }
  return context;
};

export const CreateBookProvider = ({ children }) => {
  const [createChapter, setCreateChapter] = useState({ number: '', name: '' });
  const [searchText, setSearchText] = useState('');
  const [textbookId, setTextbookId] = useState('');
  const [fullText, setFullText] = useState();

  const [createInsert, setCreateInsert] = useState({
    paragraphId: '',
    contentPositionStart: '',
    contentPositionEnd: '',
    lengthSelectSubstring: 0,
    textLength: 0,
    audio: '',
    audioDuration: 0,
    cleanContentPositionStart: 0,
    cleanContentPositionEnd: 0,
  });
  const [insertList, setInsertList] = useState([]);
  const [addInsetr, setAddInsert] = useState(false);

  const handleCreateChapter = (key, value) => {
    setCreateChapter((prevfilter) => ({
      ...prevfilter,
      [key]: value,
    }));
  };

  const handleCreateInsert = (key, value) => {
    setCreateInsert((prevfilter) => ({
      ...prevfilter,
      [key]: value,
    }));
  };

  const [awaitVoised, setAwaitVoised] = useState(false);

  return (
    <CreateBookContext.Provider
      value={{
        createChapter: createChapter,
        handleCreateChapter: handleCreateChapter,
        searchText: searchText,
        setSearchText: setSearchText,
        setTextbookId: setTextbookId,
        textbookId: textbookId,
        createInsert: createInsert,
        handleCreateInsert: handleCreateInsert,
        insertList: insertList,
        setInsertList: setInsertList,
        addInsetr: addInsetr,
        setAddInsert: setAddInsert,
        fullText: fullText,
        setFullText: setFullText,
        awaitVoised: awaitVoised,
        setAwaitVoised: setAwaitVoised,
      }}
    >
      {children}
    </CreateBookContext.Provider>
  );
};

CreateBookProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
