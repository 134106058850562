import { gql } from '@apollo/client';
import { PARAGRAPH } from '../fragment';

export const SEARCH_PARAGRAP_IN_TEXTBOOK = gql`
  ${PARAGRAPH}
  query searchParagrapInTextbook($id: ID!, $search: String!) {
    searchParagrapInTextbook(id: $id, search: $search) {
      id
      name
      paragraphs {
        ...FragmentParagraphs
      }
    }
  }
`;
