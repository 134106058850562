import useStyles from 'components/table-of-contents/styles';
import { EditDropdown } from 'components/table-of-contents/edit-dropdown/edit-dropdown';

import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Dropdown, RequestHandler } from 'components';
import { Addicon, SaveDarkIcon } from 'icons';
import { useCreateBookContext } from 'context';
import { useState } from 'react';
import {
  useAddOrUpdateParagraph,
  useUpdateInitText,
} from 'hooks/use-mutations';
import { startParagraph, endParagraph } from 'constants/index';
import { AddForm } from './add-form';
import { useDeleteParagraph } from 'hooks';

const markersParagraphText = (searchText, paragranhName) => {
  const fullText =
    searchText.firstSubstring +
    `${startParagraph}${paragranhName}start${endParagraph}${searchText.selectSubstring}${startParagraph}${paragranhName}${endParagraph}${searchText.endSubstring}`;
  return fullText;
};

export const Paragraph = ({ paragraphItem, chapterId }) => {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(
    paragraphItem?.content?.length > 0 ? 'view' : 'init',
  );
  const { searchText, createChapter, textbookId, fullText } =
    useCreateBookContext();

  const [
    mutationParagraph,
    { loading: loadingParagraph, error: errorParagraph },
  ] = useAddOrUpdateParagraph(setEditMode);

  const [mutationInitText, { loading: loadingInitText, error: errorInitText }] =
    useUpdateInitText();

  const [deleteParagraphMutation] = useDeleteParagraph(
    chapterId,
    paragraphItem.id,
  );

  const handleDelete = () => {
    deleteParagraphMutation({
      variables: {
        id: paragraphItem.id,
      },
    })
      .then(() => {
        mutationInitText({
          variables: {
            id: parseInt(textbookId),
            editedText: fullText
              .replace(
                `${startParagraph}${paragraphItem.id}start${endParagraph}`,
                '',
              )
              .replace(
                `${startParagraph}${paragraphItem.id}${endParagraph}`,
                '',
              ),
          },
        });
      })
      .catch((error) => {
        console.info(error);
      });
  };

  return (
    <Box className={classes.parargaphChildLine}>
      <Box>
        <a href={`#${paragraphItem.id}`.replace(' ', '')}>
          {paragraphItem.name}
        </a>
      </Box>
      {editMode === 'init' ? (
        <RequestHandler
          loading={loadingParagraph || loadingInitText}
          error={errorParagraph || errorInitText}
        >
          <SaveDarkIcon
            onClick={() => {
              handleSave(
                mutationParagraph,
                searchText,
                paragraphItem,
                chapterId,
                mutationInitText,
                textbookId,
                setEditMode,
              );
            }}
            className={classes.saveIcon}
          />
        </RequestHandler>
      ) : editMode === 'view' ? (
        <Box className={classes.dropdownWrappr} component='span'>
          {createChapter.name === paragraphItem.name ? (
            <Addicon
              onClick={() => {
                console.log('aaa');
              }}
            />
          ) : (
            <Dropdown classOuter={classes.dropdownWrapprInner}>
              <EditDropdown
                id={paragraphItem.id}
                setEditMode={setEditMode}
                deleteAction={handleDelete}
              />
            </Dropdown>
          )}
        </Box>
      ) : (
        <AddForm
          setAdd={() => {
            setEditMode('view');
          }}
          type='Paragraph'
          chapterId={chapterId}
          paragraphId={paragraphItem.id}
        />
      )}
    </Box>
  );
};

Paragraph.propTypes = {
  paragraphItem: PropTypes.object,
  chapterId: PropTypes.string,
};

export const handleSave = (
  mutationParagraph,
  searchText,
  paragraphItem,
  chapterId,
  mutationInitText,
  textbookId,
  setEditMode,
) => {
  if (!searchText?.selectSubstring || searchText?.selectSubstring?.length < 1)
    return;
  mutationParagraph({
    variables: {
      id: paragraphItem.id,
      name: paragraphItem.name,
      chapterId: chapterId,
      content: searchText.selectSubstring,
    },
  })
    .then((response) => {
      mutationInitText({
        variables: {
          id: parseInt(textbookId),
          editedText: markersParagraphText(
            searchText,
            response.data.addOrUpdateParagraph.id,
          ),
        },
      })
        .then(() => {
          setEditMode('view');
        })
        .catch((error) => {
          console.info(error);
        });
    })
    .catch((error) => console.info(error));
};
