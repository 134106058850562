import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { SelectorCloseIcon, SelectorOpenIcon } from 'icons';
import { useState } from 'react';
import { theme } from 'constants/index';

export const SelectCustom = ({
  items,
  handleSelectChange,
  value,
  filterKey,
  width,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 32,
      width: width,
      minHeight: 32,
      borderRadius: '8px',
      border: `1px solid ${theme.colors.lightBlue}`,
    }),
    indicatorSeparator: () => ({ display: 'none' }),
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {isOpen ? <SelectorCloseIcon /> : <SelectorOpenIcon />}
      </components.DropdownIndicator>
    );
  };

  return (
    <Select
      components={{ DropdownIndicator }}
      value={value}
      styles={customStyles}
      options={items}
      onChange={(e) => handleSelectChange(e, filterKey)}
      onMenuOpen={() => setIsOpen(true)}
      onMenuClose={() => setIsOpen(false)}
    />
  );
};

SelectCustom.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.array,
  value: PropTypes.object,
  handleSelectChange: PropTypes.func,
  filterKey: PropTypes.string,
  width: PropTypes.number,
};
