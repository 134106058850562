export const textForDuration = ({
  handleCreateInsert,
  firstSubstring,
  selectSubstring,
  endSubstring,
}) => {
  handleCreateInsert(
    'textLength',
    firstSubstring.length + selectSubstring.length + endSubstring.length,
  );
  handleCreateInsert('cleanContentPositionStart', firstSubstring.length);
  handleCreateInsert(
    'cleanContentPositionEnd',
    firstSubstring.length + selectSubstring.length,
  );
};

export const replaceHtml = (value) => {
  // eslint-disable-next-line no-useless-escape
  return value.replace(/(\<(\/?[^>]+)>)/g, ' ');
};
