import { Box, FormHelperText } from '@material-ui/core';
import { TextFieldFormik, ButtonCustom } from 'components';
import { Form, Formik } from 'formik';
import { useStyles } from './styles';
import { loginValidationSchema } from 'validation';
import { EyeIcon } from 'icons';
import { useSignInQuery } from 'hooks/use-queries';

export const LoginForm = () => {
  const classes = useStyles();

  const [singInQuery, { loading, error }] = useSignInQuery();
  console.info(loading, '...loading');

  const handleFormSubmit = (values) => {
    singInQuery({
      variables: {
        email: values.email,
        password: values.password,
        role: 'AUTHOR',
      },
    });
  };

  return (
    <Box className={classes.wrapperOuter}>
      <Box className={classes.title}>Добро пожаловать!</Box>
      <Formik
        enableReinitialize={true}
        initialValues={{ email: '', password: '' }}
        validationSchema={loginValidationSchema}
        onSubmit={handleFormSubmit}
      >
        <Form className={classes.form}>
          <TextFieldFormik
            name='email'
            placeholder='Логин'
            rootClass={classes.input}
            type='text'
          />

          <TextFieldFormik
            name='password'
            placeholder='Пароль'
            rootClass={classes.input}
            type='password'
            icon={<EyeIcon />}
          />
          {error ? (
            <FormHelperText style={{ textAlign: 'center' }} error>
              Неверный логин или пароль
            </FormHelperText>
          ) : null}
          <Box className={classes.buttonWrapper}>
            <ButtonCustom type='submit' variant='contained'>
              Отправить
            </ButtonCustom>
          </Box>
        </Form>
      </Formik>
    </Box>
  );
};
