import { useRef, useState } from 'react';
import { Layout } from 'components/index';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import {
  ShowStepCreate,
  TextFieldFormik,
  SelectFormik,
  Dropzone,
  NextButton,
  RequestHandler,
} from 'components';
import { ROUTES } from 'constants/index';
import { useGetSelectingList } from 'hooks/use-queries';

import { bookStep0ne, editbookStep0ne } from 'validation';
import { Form, Formik } from 'formik';
import { optionConstructor, submitRefForm } from 'utils';
import { useCreateTextbook } from 'hooks/use-mutations';
import { useParams } from 'react-router';
import { useGetTextbookById, useEditTextbook } from 'hooks';

export const CreateBookStepOne = () => {
  const classes = useStyles();
  const formRef = useRef();
  const { id } = useParams();
  const [nextId, setNextId] = useState();
  const [createBook, { error: errorCreate }] = useCreateTextbook(setNextId);
  const [editBook, { error: errorEdit }] = useEditTextbook(setNextId);

  const { data: dataBook, refetch } = useGetTextbookById(id);

  const handleSubmit = (ref) => {
    const data = submitRefForm(ref);
    if (data === 'NotValid') return;
    if (id) {
      editBook({
        variables: {
          data: {
            id: id,
            title: data.bookName,
            authors: data.authors,
            issueYear: parseInt(data.year),
            subjectId: data.subject.value,
            description: data.description,
            coverFile: data.titleImage ? data.titleImage : undefined,
          },
        },
      })
        .then(() => {
          refetch();
        })
        .catch((error) => {
          console.info(error);
        });
    } else {
      createBook({
        variables: {
          data: {
            title: data.bookName,
            authors: data.authors,
            issueYear: parseInt(data.year),
            subjectId: data.subject.value,
            textFile: data.fileBook,
            description: data.description,
            coverFile: data.titleImage,
          },
        },
      }).catch((error) => {
        console.info(error);
      });
    }
  };

  const {
    data: dataSelect,
    loading: loadingSelect,
    error: loadingError,
  } = useGetSelectingList();

  const initValue = () => {
    if (id && dataBook) {
      const select = dataSelect?.selectingsList?.subjects.filter(
        (e) => e.id === dataBook?.getTextbookById?.subjectId,
      );
      return {
        bookName: dataBook?.getTextbookById?.title,
        authors: dataBook?.getTextbookById?.authors,
        year: dataBook?.getTextbookById?.issueYear,
        subject: {
          label: select ? select[0]?.name : 'Предмет',
          value: dataBook?.getTextbookById?.subjectId,
        },
        description: dataBook?.getTextbookById?.description,
        titleImage: undefined,
        fileBook: null,
      };
    }
    return {
      bookName: '',
      authors: '',
      year: '',
      subject: { label: 'Предмет', value: '' },
      description: '',
      titleImage: '',
      fileBook: '',
    };
  };

  return (
    <Layout hideAsaid={true}>
      <Box className={classes.wrapperOuter}>
        <RequestHandler error={errorCreate || errorEdit}>
          <ShowStepCreate
            bookName={dataBook?.getTextbookById?.title}
            onSubmit={handleSubmit}
            formRef={formRef}
          />
          <Box className={classes.wrapperInner}>
            <Formik
              enableReinitialize={true}
              innerRef={formRef}
              initialValues={initValue()}
              validationSchema={id ? editbookStep0ne : bookStep0ne}
              onSubmit={() => {}}
            >
              <RequestHandler loading={loadingSelect} error={loadingError}>
                <Form className={classes.form}>
                  <TextFieldFormik
                    name='bookName'
                    placeholder='Название учебника'
                  />
                  <Box className={classes.twoline}>
                    <TextFieldFormik
                      className={classes.tnputAuthors}
                      name='authors'
                      placeholder='Автор(ы)'
                    />
                    <TextFieldFormik
                      className={classes.inputYear}
                      name='year'
                      placeholder='Год издания'
                    />
                  </Box>
                  <Box className={classes.selectorWrappr}>
                    <SelectFormik
                      items={optionConstructor(
                        dataSelect?.selectingsList?.subjects,
                        { label: 'Предмет', value: '' },
                        'name',
                        'id',
                      )}
                      name='subject'
                      width={664}
                    />
                  </Box>
                  <Box className={classes.selectorWrappr}>
                    <Dropzone
                      name='titleImage'
                      validation={'image/jpeg, image/png'}
                    />
                    <TextFieldFormik
                      className={classes.inputDescription}
                      name='description'
                      placeholder='Краткое описание'
                      multiline
                      rows={14}
                    />
                  </Box>
                  <Box className={classes.selectorWrappr}>
                    <Box className={classes.line}></Box>
                  </Box>
                  <Box className={classes.selectorWrappr}>
                    {id ? null : (
                      <Dropzone
                        type='fileBook'
                        name='fileBook'
                        validation={'.doc, .docx, .txt, .pdf'}
                      />
                    )}
                  </Box>
                  <NextButton
                    back={{
                      path: ROUTES.home,
                      text: 'Отмена',
                      icon: ' ',
                      disabled: false,
                    }}
                    next={{
                      path: `${ROUTES.createBookStepTwo}${
                        nextId ? nextId : null
                      }`.replace('null', ''),
                      text: 'Структура книги',
                      disabled: nextId || id ? false : true,
                    }}
                  />
                </Form>
              </RequestHandler>
            </Formik>
          </Box>
        </RequestHandler>
      </Box>
    </Layout>
  );
};
