import { Layout } from 'components/index';
import { Box } from '@material-ui/core';
import useStyles from './styles';
import {
  Addicon,
  AddMarkerIcon,
  ArrowRightIcon,
  BookIcon,
  BookmarkIcon,
  BookmarksActiveIcon,
  BookmarksDisabledIcon,
  CloseIcon,
  CloseModalIcon,
  DeleteMarkerIcon,
  DropdownIcon,
  NextTrackIcon,
  OpenModalIcon,
  ParagraphIcon,
  PauseIcon,
  PlayIcon,
  PrevTrackIcon,
  SearchIcon2,
  SearchIcon,
  SelectorCloseIcon,
  SelectorOpenIcon,
  SettingsIcon,
  SnowFlake,
  SunIcon,
  UserIcon,
  EditIcon,
  PublicIcon,
  TrashIcon,
  Doneicon,
  EyeIcon,
  DropzoneIcon,
  FileIcon,
  DropzoneFileIcon,
  SaveIcon,
  ArrowRightDarkIcon,
  ArrowLeftDarkIcon,
  SaveDarkIcon,
  InfoIcon,
  SoundIcon,
  LinkIcon,
} from 'icons';

export const DevUiPage = () => {
  const classes = useStyles();

  return (
    <Layout hideAsaid={true}>
      <Box className={classes.wrapper}>
        <Addicon />
        <AddMarkerIcon />
        <ArrowRightIcon />
        <BookIcon />
        <BookmarkIcon />
        <BookmarksActiveIcon />
        <BookmarksDisabledIcon />
        <CloseIcon />
        <CloseModalIcon />
        <DeleteMarkerIcon />
        <DropdownIcon />
        <NextTrackIcon />
        <OpenModalIcon />
        <ParagraphIcon />
        <PauseIcon />
        <PlayIcon />
        <PrevTrackIcon />
        <SearchIcon2 />+
        <SearchIcon />
        <SelectorCloseIcon />
        <SelectorOpenIcon />
        <SettingsIcon />
        <SnowFlake />
        <SunIcon />
        <UserIcon />
        <EditIcon />
        <PublicIcon />
        <TrashIcon />
        <Doneicon />
        <EyeIcon />
        <DropzoneIcon />
        <FileIcon />
        <DropzoneFileIcon />
        <SaveIcon />
        <ArrowRightDarkIcon />
        <ArrowLeftDarkIcon />
        <SaveDarkIcon />
        <InfoIcon />
        <SoundIcon />
        <LinkIcon />
      </Box>
    </Layout>
  );
};
