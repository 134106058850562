import { gql } from '@apollo/client';

export const ADD_INSERT = gql`
  mutation addInsert($data: CreateInsert!) {
    addInsert(data: $data) {
      id
      paragraphId
      audioStart
      audioEnd
      contentPositionStart
      contentPositionEnd
      name
      content
      source
      paragraph {
        id
      }
      type
    }
  }
`;
