import { Avatar, Box } from '@material-ui/core';
import { UserIcon, LogOffIcon } from 'icons';
import useStyles from './styles';
import { useComponentVisible } from 'hooks/useComponentVisible';
import { useAuthContext } from 'context';

export const HeaderAvatar = () => {
  const classes = useStyles();
  const { ref, setIsComponentVisible, isComponentVisible } =
    useComponentVisible();
  const { onLogout, userData } = useAuthContext();

  return (
    <Box className={classes.wrapperAvatar}>
      <Avatar
        onClick={() => setIsComponentVisible(true)}
        className={classes.avatar}
      >
        <UserIcon />
      </Avatar>
      {isComponentVisible ? (
        <Box ref={ref} className={classes.dropdown}>
          <Box className={classes.name}>{userData.fullName}</Box>
          <Box className={classes.line}></Box>
          <Box onClick={onLogout} className={classes.logOff}>
            <LogOffIcon onClick={onLogout} className={classes.iconOut} /> Выход
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};
