import { SEARCH_PARAGRAP_IN_TEXTBOOK } from 'graphql/query/index';
import { useQuery } from '@apollo/client';

export const searchParagrapInTextbook = (id, search) => {
  return useQuery(SEARCH_PARAGRAP_IN_TEXTBOOK, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: id,
      search: search,
    },
    skip: search ? false : true,
  });
};
