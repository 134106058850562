import { gql } from '@apollo/client';
import { PARAGRAPH } from './paragraph';

export const CHAPTER = gql`
  ${PARAGRAPH}
  fragment FragmentChapter on Chapter {
    id
    name
    paragraphs {
      ...FragmentParagraphs
    }
  }
`;
