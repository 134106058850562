import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import useStyles from './styles';
import {
  DropzoneIcon,
  FileIcon,
  CloseIcon,
  DropzoneFileIcon,
  LinkIcon,
} from 'icons';
import { Box } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { ButtonCustom, TextFieldFormik } from 'components';

export const Dropzone = ({ type, name, validation }) => {
  const classes = useStyles();

  const [selectState, setSelectState] = useState(false);
  const { setFieldValue } = useFormikContext();

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: validation,
    maxSize: 8388608000, // 100mb
  });

  useEffect(() => {
    setSelectState(acceptedFiles.length !== 0);
    setFieldValue(name, acceptedFiles[0]);
  }, [acceptedFiles, setFieldValue, name]);

  const removeFile = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    setFieldValue(name, acceptedFiles);
    setSelectState(false);
  };

  if (type === 'fileBook')
    return (
      <>
        {selectState ? (
          <Box className={classes.fileDownloadWrapper}>
            <Box className={classes.fileDownload}>Загружен файл:</Box>
            <Box className={classes.fileSuccess}>
              <Box className={classes.fileSuccessInner}>
                <FileIcon />
                <Box className={classes.text}>
                  <Box className={classes.fileName}>
                    {acceptedFiles[0]?.name}
                  </Box>
                  <Box className={classes.fileSize}>
                    {acceptedFiles[0]?.size / 1000} Кб
                  </Box>
                </Box>
              </Box>

              <CloseIcon
                onClick={removeFile}
                className={classes.iconClose}
                color='red'
              />
            </Box>
          </Box>
        ) : (
          <Box className={classes.wrapperOuterFlex}>
            <Box className={classes.wrapperOuterBook}>
              <Box className={classes.wrapperInner}>
                <Box {...getRootProps({ className: classes.dropzone })}>
                  <input {...getInputProps()} />
                  <DropzoneFileIcon className={classes.icon} />
                  <Box className={classes.text}>
                    Перетащите файл с текстом книги сюда
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.andText}>или</Box>
            <ButtonCustom onClick={open}>Выберите файл</ButtonCustom>
            <Box className={classes.textDescription}>
              поддерживаемые форматы: <br /> .doc, .docx, .txt, .pdf
            </Box>
            <Box className={classes.textDescription}>
              Размер файла - до 100 Мб
            </Box>
          </Box>
        )}
      </>
    );

  if (type === 'audioInsert')
    return (
      <>
        {selectState ? (
          <Box className={classes.fileDownloadWrapper}>
            <Box className={classes.fileDownload}>Загружен файл:</Box>
            <Box className={classes.fileSuccessInsert}>
              <Box className={classes.fileSuccessInner}>
                <FileIcon />
                <Box className={classes.text}>
                  <Box className={classes.fileName}>
                    {acceptedFiles[0]?.name}
                  </Box>
                  <Box className={classes.fileSize}>
                    {acceptedFiles[0]?.size / 1000} Мб
                  </Box>
                </Box>
              </Box>

              <CloseIcon
                onClick={removeFile}
                className={classes.iconClose}
                color='red'
              />
            </Box>
          </Box>
        ) : (
          <Box className={classes.wrapperOuterFlexRow}>
            <Box className={classes.wrapperOuterImage}>
              <Box className={classes.wrapperInner}>
                <Box {...getRootProps({ className: classes.dropzone })}>
                  <input {...getInputProps()} />
                  <DropzoneFileIcon className={classes.iconImage} />
                  <Box className={classes.text}>Перетащите файл сюда</Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.andText}>или</Box>
            <ButtonCustom onClick={open}>Загрузите файл</ButtonCustom>
          </Box>
        )}
      </>
    );

  if (type === 'imageInsert')
    return (
      <>
        {selectState ? (
          <Box className={classes.fileDownloadWrapper}>
            <Box className={classes.fileDownload}>Загружен файл:</Box>
            <Box className={classes.fileSuccessInsert}>
              <Box className={classes.fileSuccessInner}>
                <FileIcon />
                <Box className={classes.text}>
                  <Box className={classes.fileName}>
                    {acceptedFiles[0]?.name}
                  </Box>
                  <Box className={classes.fileSize}>
                    {acceptedFiles[0]?.size / 1000} Мб
                  </Box>
                </Box>
              </Box>

              <CloseIcon
                onClick={removeFile}
                className={classes.iconClose}
                color='red'
              />
            </Box>
          </Box>
        ) : (
          <Box className={classes.wrapperOuterFlexRow}>
            <Box className={classes.wrapperOuterImage}>
              <Box className={classes.wrapperInner}>
                <Box {...getRootProps({ className: classes.dropzone })}>
                  <input {...getInputProps()} />
                  <DropzoneFileIcon className={classes.iconImage} />
                  <Box className={classes.text}>
                    Перетащите изображение сюда
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.andText}>или</Box>
            <ButtonCustom onClick={open}>Загрузите файл</ButtonCustom>
          </Box>
        )}
      </>
    );

  if (type === 'videoInsert')
    return (
      <>
        {selectState ? (
          <Box className={classes.fileDownloadWrapper}>
            <Box className={classes.fileDownload}>Загружен файл:</Box>
            <Box className={classes.fileSuccessInsert}>
              <Box className={classes.fileSuccessInner}>
                <FileIcon />
                <Box className={classes.text}>
                  <Box className={classes.fileName}>
                    {acceptedFiles[0]?.name}
                  </Box>
                  <Box className={classes.fileSize}>
                    {acceptedFiles[0]?.size / 1000} Мб
                  </Box>
                </Box>
              </Box>

              <CloseIcon
                onClick={removeFile}
                className={classes.iconClose}
                color='red'
              />
            </Box>
          </Box>
        ) : (
          <Box className={classes.wrapperOuterFlex}>
            <Box {...getRootProps({})}>
              <input {...getInputProps()} />
            </Box>
            <TextFieldFormik
              name={'url'}
              placeholder='Введите URL - адрес видео'
              type='text'
              icon={<LinkIcon />}
              rootClass={classes.linkInput}
            />{' '}
            <Box style={{ marginBottom: '7px' }}>или</Box>
            <ButtonCustom onClick={open}>Загрузите файл</ButtonCustom>
          </Box>
        )}
      </>
    );

  return (
    <Box className={classes.wrapperOuter}>
      <Box className={classes.wrapperInner}>
        {selectState ? (
          <Box className={classes.dropzone}>
            <FileIcon className={classes.icon} />
            <Box className={classes.text}>{acceptedFiles[0]?.name}</Box>
            <CloseIcon
              onClick={removeFile}
              className={classes.iconClose}
              color='red'
            />
          </Box>
        ) : (
          <Box {...getRootProps({ className: classes.dropzone })}>
            <input {...getInputProps()} />
            <DropzoneIcon className={classes.icon} />
            <Box className={classes.text}>
              Перетащите обложку книги или нажмите
            </Box>
            <Box className={classes.open} onClick={open}>
              сюда
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

Dropzone.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  validation: PropTypes.string,
};
