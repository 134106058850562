import { GET_INIT_TEXT_BY_TEXT_BOOK_ID } from 'graphql/query/index';
import { useQuery } from '@apollo/client';

export const useGetInitTextByTextbookId = ({ textbookId, skip }) => {
  return useQuery(GET_INIT_TEXT_BY_TEXT_BOOK_ID, {
    fetchPolicy: 'cache-and-network',
    variables: {
      textbookId: textbookId,
    },
    skip: skip,
  });
};
