import { useMutation } from '@apollo/client';
import { DELETE_PARAGRAPH } from 'graphql/mutations';
import { GET_TEXT_BOOK_BY_ID } from 'graphql/query';
import { useParams } from 'react-router';

export const useDeleteParagraph = (chapterId, paragraphId) => {
  const { id } = useParams();

  const updateCache = (cache) => {
    const existingChapters = cache.readQuery({
      query: GET_TEXT_BOOK_BY_ID,
      variables: { textbookId: id },
    });
    const cloneExistingChapters = JSON.parse(JSON.stringify(existingChapters));

    const newChapters = cloneExistingChapters.getTextbookById.chapters.map(
      (e) => {
        if (e.id === chapterId) {
          e?.filter(function (item) {
            return item.id !== paragraphId;
          });
        }
        return e;
      },
    );

    cache.writeQuery({
      query: GET_TEXT_BOOK_BY_ID,
      variables: { textbookId: id },
      data: {
        getTextbookById: newChapters,
      },
    });
  };

  return useMutation(DELETE_PARAGRAPH, {
    update: updateCache,

    onCompleted: (response) => {
      return response;
    },
  });
};
