import { CKEditor } from '@ckeditor/ckeditor5-react';
import PropTypes from 'prop-types';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import { useAuthContext } from 'context';
import { useState } from 'react';
import { wirishApi } from 'utils';

export const Editor = ({ textValue = '', setTextValue }) => {
  const { userData } = useAuthContext();
  const [ready, setReadyInit] = useState(false);

  const ckConfig = {
    cloudServices: {
      tokenUrl:
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_API_HOST_CONSTRUCTOR}/get-token-cke?id=${userData?.id}`,
    },
  };

  return (
    <>
      <CKEditor
        onChange={(event, editor) => {
          setTextValue(editor.getData());
        }}
        editor={ClassicEditor}
        config={ckConfig}
        data={ready ? wirishApi.parseBeforeEditor(textValue) : ''}
        onReady={() => {
          setReadyInit(true);
        }}
      />
    </>
  );
};
Editor.propTypes = {
  textValue: PropTypes.string,
  setTextValue: PropTypes.func,
};
