import { makeStyles } from '@material-ui/core';

export default makeStyles({
  wrapperFilter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '30px',
  },
  year: {
    marginRight: '50px',
  },
  input: {
    width: '536px',
    height: '32px',
  },
  select: {
    width: '200px',
    height: '32px',
  },
  paginationWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },

  dropdownWrappr: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  dropdownWrapprInner: {
    marginLeft: '26px',
  },
  dropdownContent: {
    width: '141px',
    height: '127px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: '15px',
    justifyContent: 'space-around',
  },
  dropdownContentRow: {
    fontSize: '13px',
    lineHeight: '160%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  dropdownContentText: {
    marginLeft: '5px',
  },
});
