import { makeStyles } from '@material-ui/core';

export default makeStyles({
  preloadeWrapper: {
    position: 'relative',
    height: 580,
    backgroundColor: 'white',
    marginRight: '10px',
    minHeight: '100%',
    padding: '20px',
  },
  wrapperOuter: {
    backgroundColor: 'white',
    marginRight: '10px',
    minHeight: '100%',
    padding: '20px',
  },
  inputSong: {
    width: '100%',
    margin: '0px 5px',
  },
  inputDescription: {
    border: '1px solid #C5D7E0',
    borderRadius: '8px',
    padding: '5px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});
