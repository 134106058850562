import { GET_TEXT_BOOK_BY_ID } from 'graphql/query/index';
import { useQuery } from '@apollo/client';

export const useGetTextbookById = (textbookId) => {
  return useQuery(GET_TEXT_BOOK_BY_ID, {
    fetchPolicy: 'cache-and-network',
    variables: { textbookId: textbookId },
    skip: textbookId ? false : true,
  });
};
