import useStyles from './styles';
import { Box } from '@material-ui/core';
import { Doneicon, SaveIcon } from 'icons';
import { useHistory } from 'react-router';
import { ROUTES, theme } from 'constants/index';
import PropTypes from 'prop-types';
import { ButtonCustom } from 'components';
import { useParams } from 'react-router-dom';

export const ShowStepCreate = ({ bookName, onSubmit, formRef }) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  const position = {
    [ROUTES.createBookStepOne]: 0,
    [ROUTES.createBookStepTwo]: 20,
    [ROUTES.createBookStepTree]: 40,
    [ROUTES.createBookStepFour]: 60,
    [ROUTES.createBookStepFive]: 80,
    [ROUTES.createBookStepSix]: 100,
  };

  const currentPath = history.location.pathname.replace(`/${id}`, '/');

  const showButton = (path) => {
    if (
      ROUTES.createBookStepTwo === path ||
      ROUTES.createBookStepFive === path ||
      ROUTES.createBookStepSix === path
    ) {
      return false;
    }
    if (
      ROUTES.createBookStepTree === path ||
      ROUTES.createBookStepFour === path ||
      ROUTES.createBookStepFive === path
    ) {
      return 'сохранить параграф';
    }

    return 'Сохранить книгу';
  };

  return (
    <Box className={classes.wrapperOuter}>
      <Box className={classes.wrapperTitle}>
        {bookName ? (
          showButton(currentPath) ? (
            <>
              <Box style={{ flexBasis: `calc((100% - 900px) / 2)` }}></Box>
              <Box style={{ flexBasis: '900px' }} className={classes.bookName}>
                {bookName}
              </Box>
              <Box
                style={{
                  flexBasis: `calc((100% - 900px) / 2)`,
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <ButtonCustom
                  onClick={() => {
                    if (formRef) {
                      onSubmit(formRef);
                    } else {
                      onSubmit();
                    }
                  }}
                  type='submit'
                  variant='contained'
                >
                  <SaveIcon className={classes.saveIcon} />
                  {showButton(currentPath)}
                </ButtonCustom>
              </Box>
            </>
          ) : (
            <>
              <Box style={{ flexBasis: `calc((100% - 900px) / 2)` }}></Box>
              <Box style={{ flexBasis: '900px' }} className={classes.bookName}>
                {bookName}
              </Box>
              <Box style={{ flexBasis: `calc((100% - 900px) / 2)` }}></Box>
            </>
          )
        ) : (
          <>
            <Box style={{ flexBasis: `calc((100% - 900px) / 2)` }}></Box>
            <Box className={classes.bookName}>Новый учебник</Box>
            <Box
              style={{
                flexBasis: `calc((100% - 900px) / 2)`,
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              <ButtonCustom
                onClick={() => {
                  if (formRef) {
                    onSubmit(formRef);
                  } else {
                    onSubmit();
                  }
                }}
                type='submit'
                variant='contained'
              >
                <SaveIcon className={classes.saveIcon} /> Добавить книгу
              </ButtonCustom>
            </Box>
          </>
        )}
      </Box>
      <Box className={classes.wrapperCircleOuter}>
        <Box className={classes.wrapperCircleInner}>
          <LineGenerate position={position[currentPath]} />
          <Box className={classes.line}>
            <Box
              style={{
                backgroundColor: theme.colors.blue,
                width: `${position[currentPath]}%`,
              }}
            ></Box>
            <Box
              style={{
                backgroundColor: theme.colors.lightBlue,
                width: `${100 - position[currentPath]}%`,
              }}
            ></Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const LineGenerate = ({ position }) => {
  const classes = useStyles();

  const circleList = [
    {
      number: '1',
      state: position === 0 ? 'current' : 'done',
      title: 'Текст',
    },
    {
      number: '2',
      state: position === 20 ? 'current' : position > 20 ? 'done' : 'future',
      title: 'Структура',
    },
    {
      number: '3',
      state: position === 40 ? 'current' : position > 40 ? 'done' : 'future',
      title: 'Редактирование текста',
    },
    {
      number: '4',
      state: position === 60 ? 'current' : position > 60 ? 'done' : 'future',
      title: 'Формулы',
    },
    {
      number: '5',
      state: position === 80 ? 'current' : position > 80 ? 'done' : 'future',
      title: 'Озвучка',
    },
    {
      number: '6',
      state: position === 100 ? 'current' : 'future',
      title: 'Предпросмотр',
    },
  ];

  return (
    <>
      {circleList.map((e) => {
        const circleStyle = (state) => {
          switch (state) {
            case 'done':
              return {
                circle: theme.colors.blue,
                title: theme.colors.blue,
                numberColor: theme.colors.white,
              };
            case 'current':
              return {
                circle: theme.colors.paleBlue,
                title: theme.colors.black,
                numberColor: theme.colors.white,
              };
            case 'future':
              return {
                circle: theme.colors.lightBlue,
                title: 'rgba(0, 0, 0, 0.4)',
                numberColor: theme.colors.black,
              };
          }
        };
        const state = circleStyle(e.state);

        return (
          <Box className={classes.circleOuter} key={e.number}>
            <Box
              style={{
                backgroundColor: state.circle,
                color: state.numberColor,
              }}
              className={classes.circle}
            >
              {e.state === 'done' ? <Doneicon /> : e.number}
            </Box>
            <Box style={{ color: state.title }} className={classes.circleTitle}>
              {e.title}
            </Box>
          </Box>
        );
      })}
    </>
  );
};

LineGenerate.propTypes = {
  position: PropTypes.number,
};

ShowStepCreate.propTypes = {
  bookName: PropTypes.string,
  onSubmit: PropTypes.func,
  formRef: PropTypes.object,
};
