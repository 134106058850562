import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { SelectorCloseIcon, SelectorOpenIcon } from 'icons';
import { useState } from 'react';
import { theme } from 'constants/index';
import { useField, useFormikContext } from 'formik';

export const SelectFormik = ({ items, name, width }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 32,
      width: width,
      minHeight: 32,
      borderRadius: '8px',
      border: `1px solid ${theme.colors.lightBlue}`,
    }),
    indicatorSeparator: () => ({ display: 'none' }),
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {isOpen ? <SelectorCloseIcon /> : <SelectorOpenIcon />}
      </components.DropdownIndicator>
    );
  };

  return (
    <Select
      components={{ DropdownIndicator }}
      value={field.value}
      styles={customStyles}
      options={items}
      onChange={(option) => setFieldValue(name, option)}
      onMenuOpen={() => setIsOpen(true)}
      onMenuClose={() => setIsOpen(false)}
    />
  );
};

SelectFormik.propTypes = {
  items: PropTypes.array,
  name: PropTypes.string,
  width: PropTypes.number,
};
