import { gql } from '@apollo/client';
import { PARAGRAPH } from '../fragment';

export const ADD_OR_UPDATE_PARAGRAPH = gql`
  ${PARAGRAPH}
  mutation addOrUpdateParagraph(
    $id: ID
    $chapterId: ID!
    $name: String!
    $content: String
    $parsed: Boolean
  ) {
    addOrUpdateParagraph(
      id: $id
      chapterId: $chapterId
      name: $name
      content: $content
      parsed: $parsed
    ) {
      ...FragmentParagraphs
    }
  }
`;
