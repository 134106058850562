import { useMutation } from '@apollo/client';
import { PUBLISH_TEXTBOOK } from 'graphql/mutations';

export const usePublishTextbook = () => {
  return useMutation(PUBLISH_TEXTBOOK, {
    onCompleted: (response) => {
      return response;
    },
  });
};
