export const theme = {
  colors: {
    white: '#fff',
    black: '#303030',
    blue: '#0B486B',
    gray: '#5C5C5C',
    paleBlue: '#4D6E7D',
    lightBlue: '#C5D7E0',
    green: '#3ade0033',
    purple: '#0009de33',
    background: {
      primary: '#E5E5E5',
      secondary: '#fdfdf8',
    },
    contentBackground: {
      primary: '#fff',
      secondary: '#F7F7E8',
    },
    textBackground: {
      primary: '#0363',
      secondary: '#f333',
    },
    dropdownBackground: '#EEF9FD',
  },
  fonts: {
    primary: 'Roboto ,sans-serif',
  },
};
