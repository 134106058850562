import PropTypes from 'prop-types';
import React from 'react';

export const VideoIcon = ({
  width = 22,
  height = 16,
  className,
  onClick,
  color = '#4D6E7D',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M20.783 2.23333L16.583 4.6V1.33333C16.583 0.979711 16.4425 0.640573 16.1925 0.390524C15.9424 0.140476 15.6033 0 15.2497 0H3.24967C2.54243 0 1.86415 0.280951 1.36406 0.781048C0.863959 1.28115 0.583008 1.95942 0.583008 2.66667V13.3333C0.583008 14.0406 0.863959 14.7189 1.36406 15.219C1.86415 15.719 2.54243 16 3.24967 16H15.2497C15.6033 16 15.9424 15.8595 16.1925 15.6095C16.4425 15.3594 16.583 15.0203 16.583 14.6667V11.3867L20.783 13.7533C20.8908 13.8237 21.0151 13.8645 21.1437 13.8716C21.2722 13.8787 21.4003 13.8519 21.5152 13.7938C21.63 13.7357 21.7276 13.6484 21.798 13.5406C21.8684 13.4329 21.9092 13.3085 21.9163 13.18V2.8C21.9081 2.67205 21.8665 2.5485 21.7956 2.44165C21.7248 2.33481 21.6271 2.24841 21.5125 2.19107C21.3978 2.13374 21.2701 2.10747 21.1421 2.11488C21.0141 2.1223 20.8903 2.16314 20.783 2.23333Z'
        fill={color}
      />
    </svg>
  );
};

VideoIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
