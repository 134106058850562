export const optionConstructor = (data, defaultValue, keyLabel, keyValue) => {
  let Arr = [];
  data?.map((e) => {
    Arr.push({
      label: keyLabel ? e[keyLabel] : e,
      value: keyValue ? e[keyValue] : e,
    });
  });
  if (defaultValue) Arr.unshift(defaultValue);
  return Arr;
};
