import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  buttonWrapper: {
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '50px',
    width: '664px',
  },
  buttonWrapperOuter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  iconArrow: {
    marginLeft: '15px',
  },
  iconArrowLeft: {
    marginRight: '15px',
  },
});
