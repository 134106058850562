import PropTypes from 'prop-types';
import React from 'react';

export const Doneicon = ({
  width = 18,
  height = 14,
  className,
  color = 'white',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M1 7L7 13L17 1'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

Doneicon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
};
