import { useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { parser } from 'utils';

export const useCurrentText = (currentParagraph, paragraphList, parse) => {
  const [textValue, setTextValue] = useState('');

  useEffect(() => {
    // parse for editor
    // if (parse) {
    //   if (paragraphList[currentParagraph]?.parsed) {
    //     return setTextValue(paragraphList[currentParagraph]?.content);
    //   }
    //   return setTextValue(paragraphList[currentParagraph]?.content);
    // }
    return setTextValue(paragraphList[currentParagraph]?.content);
  }, [currentParagraph, paragraphList, parse]);

  const handleSetTextValue = (value) => {
    setTextValue(value);
  };

  return { textValue, setTextValue: handleSetTextValue };
};
