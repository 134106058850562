import PropTypes from 'prop-types';
import React from 'react';

import preloaderGIF from '../../assets/img/preloader.gif';

export const Preloader = ({ height = 100, width = 100 }) => {
  return (
    <img
      src={preloaderGIF}
      alt='preloader'
      style={{
        width: width,
        height: height,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    />
  );
};

Preloader.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};
