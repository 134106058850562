import { useMutation } from '@apollo/client';
import { ADD_OR_UPDATE_PARAGRAPH } from 'graphql/mutations';
import { GET_TEXT_BOOK_BY_ID } from 'graphql/query';
import { useParams } from 'react-router';

export const useAddOrUpdateParagraph = (setAdd) => {
  const { id } = useParams();

  const updateCache = (cache, { data }) => {
    const existingChapters = cache.readQuery({
      query: GET_TEXT_BOOK_BY_ID,
      variables: { textbookId: id },
    });
    const newParagraph = data.addOrUpdateParagraph;
    const cloneExistingChapters = JSON.parse(JSON.stringify(existingChapters));

    const newChapters = cloneExistingChapters.getTextbookById.chapters.map(
      (e) => {
        if (e.id === newParagraph.chapterId) {
          let index = e.paragraphs.findIndex((x) => x.id === newParagraph.id);
          if (index !== -1) {
            e.paragraphs[index] = newParagraph;
          } else {
            e.paragraphs.push(newParagraph);
          }
        }
        return e;
      },
    );

    cache.writeQuery({
      query: GET_TEXT_BOOK_BY_ID,
      variables: { textbookId: id },
      data: {
        getTextbookById: newChapters,
      },
    });
  };

  return useMutation(ADD_OR_UPDATE_PARAGRAPH, {
    update: updateCache,

    onCompleted: (response) => {
      setAdd(false);
      return response;
    },
  });
};
