/* eslint-disable jsx-a11y/media-has-caption */
import { Box } from '@material-ui/core';
import { useCreateBookContext } from 'context';
import { useEffect, useState } from 'react';

export const usePlayInsertAudio = ({ currentTime, togglePlayPause }) => {
  const { insertList } = useCreateBookContext();
  const [stopList, setStopList] = useState();
  const [searchClass, setSearchClass] = useState();

  useEffect(() => {
    setStopList(
      insertList
        ?.map((e) => {
          return {
            time: Math.floor(e?.audioStart),
            // eslint-disable-next-line no-undef
            link: `${process.env.REACT_APP_API_HOST}/${e?.source}`,
          };
        })
        .sort((a, b) => (a.time > b.time ? 1 : -1)),
    );
  }, [insertList]);

  useEffect(() => {
    stopList?.map((e) => {
      const audio = document.querySelector(`.audio${e.time}`);
      if (e.time === parseInt(currentTime)) {
        togglePlayPause();
        audio.play();
        setSearchClass(`.audio${e.time}`);
      } else {
        audio.pause();
      }
    });
  }, [currentTime]);

  useEffect(() => {
    if (!searchClass) return;
    getCurrentTimeInsert();
  }, [searchClass]);

  const getCurrentTimeInsert = () => {
    const audio = document.querySelector(searchClass);
    if (audio?.currentTime === audio?.duration) {
      setSearchClass('');
      return togglePlayPause();
    }
    setTimeout(getCurrentTimeInsert, 100);
  };

  const ListAudioInsert = (
    <Box>
      {stopList?.map((e) => {
        return (
          <audio
            key={e.time}
            src={`${e.link}`}
            preload='auto'
            className={`audio${e.time}`}
          ></audio>
        );
      })}
    </Box>
  );

  return { ListAudioInsert };
};
