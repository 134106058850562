import { makeStyles } from '@material-ui/core';
import { theme } from 'constants/index';

export default makeStyles({
  wrapperOuter: {
    width: '100%',
    marginBottom: '64px',
  },
  bookName: {
    fontSize: '24px',
    lineHeight: '28px',
    color: theme.colors.black,
    textAlign: 'center',
  },
  wrapperTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '25px',
    marginTop: '32px',
  },
  wrapperCircleOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapperCircleInner: {
    maxWidth: '900px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '48px',
    height: '48px',
    backgroundColor: theme.colors.paleBlue,
    borderRadius: '50%',
    color: theme.colors.white,
    lineHeight: '24px',
    fontSize: '24px',
  },
  line: {
    position: 'absolute',
    width: '100%',
    height: '2px',
    top: '29px',
    zIndex: '-2',
    display: 'flex',
  },
  circleOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    height: '60px',
  },
  circleTitle: {
    position: 'absolute',
    top: '60px',
    textAlign: 'center',
  },
  saveIcon: {
    marginRight: '12px',
    '&:hover': {
      color: theme.colors.blue,
    },
  },
});
