import useStyles from './styles';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formula } from 'components';

export const EditTextPreview = ({ textValue = '' }) => {
  const classes = useStyles();
  return (
    <Box className={classes.wrapperOuter}>
      <Box className={classes.wrapperHead}>Preview</Box>
      <Box className={classes.wrapperText}>
        <Formula arrText={textValue} />
      </Box>
    </Box>
  );
};

EditTextPreview.propTypes = {
  textValue: PropTypes.string,
  setTextValue: PropTypes.func,
};
