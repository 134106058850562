import { Box, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { ErrorIndicator } from 'components';
import useStyles from './style';

export const RequestHandler = ({ loading = false, error, children }) => {
  const classes = useStyles();

  if (loading) {
    return (
      <Box className={classes.wrapper}>
        <CircularProgress size={24} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box className={classes.wrapper}>
        <ErrorIndicator error={error} />
      </Box>
    );
  }

  return children;
};

RequestHandler.propTypes = {
  loading: PropTypes.bool,
  backgroundLoading: PropTypes.bool,
  error: PropTypes.any,
  children: PropTypes.node,
};
