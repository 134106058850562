/* eslint-disable no-unused-vars */
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  split,
  Observable,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import create from 'zustand';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from '@apollo/client/utilities';
import { print } from 'graphql';
import { createClient } from 'graphql-ws';
import { getToken, removeToken } from 'utils';

class WebSocketLink extends ApolloLink {
  constructor(options) {
    super();
    this.client = createClient(options);
  }

  request(operation) {
    return new Observable((sink) => {
      return this.client.subscribe(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (err) => {
            if (Array.isArray(err))
              // GraphQLError[]
              return sink.error(
                new Error(err.map(({ message }) => message).join(', ')),
              );

            if (err instanceof CloseEvent)
              return sink.error(
                new Error(
                  `Socket closed with event ${err.code} ${err.reason || ''}`, // reason will be available on clean closes only
                ),
              );
            return sink.error(err);
          },
        },
      );
    });
  }
}
const wsLink = new WebSocketLink({
  // eslint-disable-next-line no-undef
  url: `${process.env.REACT_APP_API_HOST_WSS}/subscriptions`,
});

export const useErrorsStore = create(() => ({
  hasError: false,
  error: null,
  date: null,
}));

const httpLink = createUploadLink({
  // eslint-disable-next-line no-undef
  uri: `${process.env.REACT_APP_API_HOST}/graphql`,
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError || graphQLErrors[0]) {
    useErrorsStore.setState({
      hasError: true,
      error: networkError || graphQLErrors[0],
      date: Date.now(),
    });
  }
  if (networkError && networkError.statusCode === 401) {
    removeToken();
  }
});

const authLink = setContext(async (_, { headers }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, splitLink]),
  cache: new InMemoryCache(),
});
