import { ButtonCustom } from 'components';
import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import PropTypes from 'prop-types';
import { ArrowLeftDarkIcon, ArrowRightIcon } from 'icons';
import { ROUTES } from 'constants/index';

export const NextButton = ({ back, next }) => {
  const classes = useStyles();
  const { id } = useParams();

  const history = useHistory();

  return (
    <Box className={classes.buttonWrapperOuter}>
      <Box className={classes.buttonWrapper}>
        <ButtonCustom
          variant='contained'
          onClick={() => {
            if (back?.path === ROUTES.home) {
              history.push(back?.path);
            } else {
              history.push(back?.path + id);
            }
          }}
          reverseStyle={true}
          disabled={back.disabled}
        >
          {back?.icon ? (
            back?.icon
          ) : (
            <ArrowLeftDarkIcon className={classes.iconArrowLeft} />
          )}
          {back?.text}
        </ButtonCustom>
        <ButtonCustom
          onClick={() => {
            if (next?.redirect) {
              return window.open(next?.path, '_blank');
            }
            if (id && !next?.noId) {
              history.push(next?.path + id);
            } else {
              history.push(next?.path);
            }
          }}
          type='submit'
          variant='contained'
          disabled={next.disabled}
        >
          {next?.text}
          {next?.icon ? (
            next?.icon
          ) : (
            <ArrowRightIcon className={classes.iconArrow} />
          )}
        </ButtonCustom>
      </Box>
    </Box>
  );
};

NextButton.propTypes = {
  back: PropTypes.object,
  next: PropTypes.object,
};
