import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAsideContext } from 'context/AsideContext';
import {
  ParagraphIcon,
  SearchIcon,
  SelectorCloseIcon,
  SelectorOpenIcon,
} from 'icons/index';
import useStyles from './styles';
import { TextField } from 'components';

export const AsideMenu = () => {
  const classes = useStyles();
  const { chapterList, serchValue, setSearchValue } = useAsideContext();

  return (
    <Box className={classes.menu}>
      <Box className={classes.titleWrapper}>
        <ParagraphIcon className={classes.paragraphIcon} /> Оглавление
      </Box>
      <TextField
        value={serchValue}
        placeholder='Название, автор, тема'
        onChange={setSearchValue}
        filterKey='inputValue'
        className={classes.input}
        classNameOutline={classes.classNameOutline}
        icon={<SearchIcon />}
        name='inputValue'
      />
      {chapterList?.map((chapter) => {
        return <Chapter key={chapter.id} chapter={chapter} />;
      })}
    </Box>
  );
};

const Chapter = (chapter) => {
  const classes = useStyles();

  const [onOpen, setOnOpen] = useState(false);
  const { setLinkParagraph } = useAsideContext();
  return (
    <Box key={chapter?.chapter?.id}>
      <Box className={classes.chapterWrapper}>
        <Box>{chapter?.chapter?.name}</Box>
        <Box>
          {onOpen ? (
            <SelectorCloseIcon
              onClick={() => {
                setOnOpen(false);
              }}
              className={classes.iconArrow}
            />
          ) : (
            <SelectorOpenIcon
              onClick={() => {
                setOnOpen(true);
              }}
              className={classes.iconArrow}
            />
          )}
        </Box>
      </Box>
      {onOpen ? (
        <>
          {chapter?.chapter?.paragraphs?.map((paragraph) => {
            return (
              <Box
                onClick={() => {
                  setLinkParagraph(paragraph?.id);
                }}
                className={classes.paragraphWrapper}
                key={paragraph?.id}
              >
                {paragraph?.name}
              </Box>
            );
          })}
        </>
      ) : null}
    </Box>
  );
};
AsideMenu.propTypes = {
  chapter: PropTypes.object,
};
