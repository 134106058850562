import { useMutation } from '@apollo/client';
import { ADD_OR_UPDATE_CHAPTER } from 'graphql/mutations';
import { GET_TEXT_BOOK_BY_ID } from 'graphql/query';
import { useParams } from 'react-router';

export const useAddOrUpdateChapter = (setAdd) => {
  const { id } = useParams();

  const updateCache = (cache, { data }) => {
    const existingChapters = cache.readQuery({
      query: GET_TEXT_BOOK_BY_ID,
      variables: { textbookId: id },
    });
    const newChapter = data.addOrUpdateChapter;

    cache.writeQuery({
      query: GET_TEXT_BOOK_BY_ID,
      variables: { textbookId: id },
      data: {
        getTextbookById: [
          newChapter,
          ...existingChapters.getTextbookById.chapters,
        ],
      },
    });
  };

  return useMutation(ADD_OR_UPDATE_CHAPTER, {
    update: updateCache,
    onCompleted: (response) => {
      setAdd(false);
      return response;
    },
  });
};
