import { makeStyles } from '@material-ui/core';
import { theme } from 'constants/index';

export default makeStyles({
  wrapperOuter: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  content: {
    position: 'absolute',
    top: '30px',
    right: '-5px',
    zIndex: '2',
    borderRadius: '6px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.25)',
    backgroundColor: theme.colors.white,
  },
  iconCenter: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
});
