export const submitRefForm = (ref) => {
  if (ref.current) {
    ref.current.handleSubmit();
    if (ref.current.isValid) {
      return ref.current.values;
    }
    console.info(ref, 'not valid data');
    return 'NotValid';
  }
  return {};
};
