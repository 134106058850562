import { ButtonCustom } from 'components';
import {
  NextTrackIcon,
  PrevTrackIcon,
  SoundIcon,
  PlayIcon,
  PauseIcon,
} from 'icons';
import { Box, CircularProgress } from '@material-ui/core';
import { useStyles } from './styles';
import PropTypes from 'prop-types';
import { useVoiceParagraph } from 'hooks';
import { nextParagraph } from 'utils';
import { useEffect, useState } from 'react';
import { useCreateBookContext } from 'context';
import { useModalContext } from 'context/ModalContext';

export const SoundPlayerButton = ({
  togglePlayPause,
  isPlaying,
  audioFile,
  refetchBook,
  currentParagraph,
  setCurrentParagraph,
  paragraphList,
}) => {
  const classes = useStyles();
  const { setAwaitVoised } = useCreateBookContext();
  const [mutation] = useVoiceParagraph();
  const [loaderButton, setLoaderButton] = useState(false);

  useEffect(() => {
    if (isPlaying) togglePlayPause();
  }, [currentParagraph]);

  const nextNameChapter = () => {
    const currentIndex = paragraphList?.paragraphList.indexOf(currentParagraph);
    if (currentIndex + 1 === paragraphList?.paragraphList?.length) {
      return '';
    }
    return paragraphList?.[paragraphList?.paragraphList[currentIndex + 1]]
      ?.name;
  };
  const previousNameChapter = () => {
    const currentIndex = paragraphList?.paragraphList.indexOf(currentParagraph);
    if (currentIndex === 0) {
      return '';
    }
    return paragraphList?.[paragraphList?.paragraphList[currentIndex - 1]]
      ?.name;
  };

  return (
    <Box className={classes.soundWrapperButton}>
      <Box>
        <Box
          className={[classes.titleButton, classes.titleButtonStart].join(' ')}
        >
          Предыдущая глава
        </Box>
        <Box className={classes.nextBottonWrapper}>
          <PrevTrackIcon
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setCurrentParagraph(
                nextParagraph(paragraphList, currentParagraph, 'back'),
              );
            }}
          />
          <Box
            className={[classes.nameWrapper, classes.nameWrapperStart].join(
              ' ',
            )}
          >
            {previousNameChapter()}
          </Box>
        </Box>
      </Box>
      <Box>
        {audioFile ? (
          <>
            <ButtonCustom
              classNameCustom={classes.playButton}
              onClick={togglePlayPause}
            >
              {!isPlaying ? (
                <PlayIcon className={classes.playIcon} />
              ) : (
                <PauseIcon />
              )}
            </ButtonCustom>
            <SoundButton
              style={{ marginLeft: '10px' }}
              mutation={mutation}
              currentParagraph={currentParagraph}
              refetchBook={refetchBook}
              setLoaderButton={setLoaderButton}
              loaderButton={loaderButton}
              setAwaitVoised={setAwaitVoised}
            >
              Переозвучить главу <SoundIcon className={classes.soundIcon} />
            </SoundButton>
          </>
        ) : (
          <SoundButton
            mutation={mutation}
            currentParagraph={currentParagraph}
            refetchBook={refetchBook}
            setLoaderButton={setLoaderButton}
            loaderButton={loaderButton}
            setAwaitVoised={setAwaitVoised}
          >
            Озвучить главу <SoundIcon className={classes.soundIcon} />
          </SoundButton>
        )}
      </Box>
      <Box>
        <Box
          className={[classes.titleButton, classes.titleButtonEnd].join(' ')}
        >
          Следующая глава
        </Box>
        <Box className={classes.nextBottonWrapper}>
          <Box
            className={[classes.nameWrapper, classes.nameWrapperEnd].join(' ')}
          >
            {nextNameChapter()}
          </Box>
          <NextTrackIcon
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setCurrentParagraph(
                nextParagraph(paragraphList, currentParagraph, 'next'),
              );
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

SoundPlayerButton.propTypes = {
  togglePlayPause: PropTypes.func,
  isPlaying: PropTypes.bool,
  audioFile: PropTypes.string,
  refetchBook: PropTypes.func,
  currentParagraph: PropTypes.string,
  setCurrentParagraph: PropTypes.func,
  paragraphList: PropTypes.object,
  setAwaitVoised: PropTypes.func,
};

const SoundButton = ({
  mutation,
  currentParagraph,
  refetchBook,
  setLoaderButton,
  style,
  children,
  loaderButton,
  setAwaitVoised,
}) => {
  const classes = useStyles();
  const { handleClickSnack } = useModalContext();

  return (
    <>
      {loaderButton ? (
        <CircularProgress size={24} style={style} />
      ) : (
        <ButtonCustom
          classNameCustom={classes.buttonCustom}
          style={style}
          onClick={() => {
            setAwaitVoised(true);
            mutation({
              variables: {
                id: parseInt(currentParagraph),
              },
            })
              .then(() => {
                setAwaitVoised(false);
                refetchBook();
              })
              .catch((error) => {
                handleClickSnack('Произошла ошибка');
                console.info(error);
                setAwaitVoised(false);
              });
            setLoaderButton(true);
          }}
        >
          {children}
        </ButtonCustom>
      )}
    </>
  );
};

SoundButton.propTypes = {
  mutation: PropTypes.func,
  currentParagraph: PropTypes.string,
  refetchBook: PropTypes.any,
  setLoaderButton: PropTypes.func,
  children: PropTypes.any,
  style: PropTypes.object,
  loaderButton: PropTypes.bool,
  setAwaitVoised: PropTypes.func,
};
