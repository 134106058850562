import { VOICE_PARAGRAPH } from 'graphql/mutations';
import { useMutation } from '@apollo/client';

export const useVoiceParagraph = () => {
  return useMutation(VOICE_PARAGRAPH, {
    onCompleted: (response) => {
      return response;
    },
  });
};
