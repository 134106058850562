import { gql } from '@apollo/client';
import { SUBJECT, CHAPTER } from '../fragment';

export const CREATE_TEXTBOOK = gql`
  ${SUBJECT}
  ${CHAPTER}
  mutation createTextbook($data: creatTextbookInput!) {
    createTextbook(data: $data) {
      id
      title
      authors
      issueYear
      subject {
        ...FragmentSubject
      }
      chapters {
        ...FragmentChapter
      }
      creatorId
      subjectId
      cover
    }
  }
`;
