import { useEffect, useState } from 'react';
import { useCreateBookContext } from 'context';
import { useClickFormula } from 'hooks';
import { textForDuration, replaceHtml } from 'utils';

export const useInsert = ({
  searchText,
  textValue,
  currentParagraph,
  dataInsert,
  refText,
  audio,
}) => {
  const { handleCreateInsert, setInsertList } = useCreateBookContext();
  const { selectValue, setSelectValue } = useClickFormula(
    currentParagraph,
    refText,
  );
  const [textSelectFormula, setTextSelectFormula] = useState('');

  useEffect(() => {
    if (!searchText) return;
    // fix incorrect update using the text of the previous paragraph
    if (textValue.slice(0, 20) !== searchText?.oldText.slice(0, 20)) return;
    handleSetCreateInsert(currentParagraph, searchText);
  }, [searchText?.selectSubstring]);

  useEffect(() => {
    if (!dataInsert) return;
    setInsertList(dataInsert?.getInserts);
  }, [dataInsert, setInsertList]);

  useEffect(() => {
    handleSetCreateInsert('', '');
    handleCreateInsert('paragraphId', currentParagraph);
    handleCreateInsert('audio', audio);
  }, [currentParagraph]);

  useEffect(() => {
    setTextSelectFormula(markerSelectFormula(textValue, selectValue));
  }, [selectValue, textValue]);

  const handleSetCreateInsert = (currentParagraph, searchText) => {
    handleCreateInsert('paragraphId', currentParagraph);
    handleCreateInsert('contentPositionStart', searchText?.startPosition);
    handleCreateInsert('contentPositionEnd', searchText?.endPosition);
    handleCreateInsert(
      'lengthSelectSubstring',
      searchText?.selectSubstring?.length,
    );
  };

  const markerSelectFormula = (text, selectValue) => {
    if (!selectValue) return text;

    const startIndex = text.indexOf(selectValue);
    if (startIndex === -1) {
      handleCreateInsert('contentPositionStart', 0);
      handleCreateInsert('contentPositionEnd', 0);
      handleCreateInsert('paragraphId', currentParagraph);
      return text;
    }
    const endIndex = startIndex + selectValue.length;
    const textBefore = text.slice(0, startIndex);
    const textSelect = text.slice(startIndex, endIndex);
    const textEnd = text.slice(endIndex, text.length);
    handleCreateInsert('contentPositionStart', startIndex);
    handleCreateInsert('contentPositionEnd', endIndex);
    handleCreateInsert('paragraphId', currentParagraph);

    textForDuration({
      handleCreateInsert,
      firstSubstring: replaceHtml(textBefore),
      selectSubstring: replaceHtml(textSelect),
      endSubstring: replaceHtml(textEnd),
    });

    return (
      textBefore +
      `<span style="background-color: #0B486B; pointer-events:none;">` +
      textSelect +
      `</span>` +
      textEnd
    );
  };

  return {
    textSelectFormula: textSelectFormula,
    setSelectValue: setSelectValue,
  };
};
