import { Layout } from 'components/index';
import { Box } from '@material-ui/core';
import useStyles from './styles';
import { useEffect, useState } from 'react';
import { HomeFilter, HomeTable, RequestHandler } from 'components';
import { useHistory } from 'react-router';
import { ROUTES, LIMIT } from 'constants/index';
import { useGetTextBooksList, useGetSelectingList } from 'hooks/use-queries';

export const Home = () => {
  const classes = useStyles();
  const history = useHistory();

  const [filter, setFilter] = useState({
    inputValue: '',
    category: { label: 'Категория', value: '' },
    year: { label: 'Год выпуска', value: '' },
  });
  const [offset, setOffset] = useState(undefined);

  const {
    data,
    loading,
    errors,
    refetch: refetchBook,
  } = useGetTextBooksList({
    searchString: filter.inputValue,
    selectedSubjectId: filter.category.value,
    selectedYear: filter.year.value,
    getOnlyWithStatus:
      history.location.pathname === ROUTES.home ? 'SAVED' : 'PUBLISHED',
    limit: LIMIT,
    offset: offset,
  });

  useEffect(() => {
    setOffset(0);
  }, [filter]);

  const handleSetFilter = (value, key) => {
    setFilter((prevfilter) => ({
      ...prevfilter,
      [key]: value,
    }));
  };

  const {
    data: dataSelect,
    loading: loadingSelect,
    error: loadingError,
  } = useGetSelectingList();

  return (
    <Layout hideAsaid={true}>
      <Box className={classes.wrapper}>
        <Box className={classes.title}>
          Список
          {history.location.pathname === ROUTES.home
            ? ' сохраненных '
            : ' опубликованных '}
          учебников
        </Box>
        <HomeFilter
          filter={filter}
          handleSetFilter={handleSetFilter}
          dataSelect={dataSelect?.selectingsList}
        />
        <RequestHandler
          loading={loading || loadingSelect}
          errors={errors || loadingError}
        >
          <HomeTable
            data={data}
            setOffset={setOffset}
            offset={offset}
            refetchBook={refetchBook}
          />
        </RequestHandler>
      </Box>
    </Layout>
  );
};
