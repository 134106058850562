import PropTypes from 'prop-types';
import React from 'react';

export const ArrowLeftDarkIcon = ({
  width = 11,
  height = 16,
  className,
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 11 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M7.93891 0.938965L0.878906 7.99996L7.93891 15.061L10.0609 12.939L5.12091 7.99996L10.0609 3.06096L7.93891 0.938965Z'
        fill='#0B486B'
      />
    </svg>
  );
};

ArrowLeftDarkIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
