import React from 'react';

export const AddMarkerIcon = () => {
  return (
    <svg
      width='16'
      height='14'
      viewBox='0 0 16 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.3333 0.266602C11.98 0.266602 11.64 0.393268 11.38 0.653268L7.50666 4.53327L11.2733 8.29994L15.1533 4.4266C15.6667 3.9066 15.6667 3.0666 15.1533 2.53993L13.26 0.653268C13 0.393268 12.6667 0.266602 12.3333 0.266602ZM6.86666 5.1666L2.89332 9.13994C2.37332 9.65993 2.37332 10.4999 2.90666 11.0399C2.09332 11.8599 1.26666 12.6799 0.446655 13.4999H4.21999L4.79332 12.9266C5.31332 13.4333 6.14666 13.4266 6.66666 12.9133L10.6333 8.93994'
        fill='#0B486B'
      />
    </svg>
  );
};
