import { Layout, LoginForm } from 'components/index';
import { Box } from '@material-ui/core';
import useStyles from './styles';

export const Login = () => {
  const classes = useStyles();

  return (
    <Layout hideAsaid={true}>
      <Box className={classes.wrapperOuter}>
        <LoginForm />
      </Box>
    </Layout>
  );
};
