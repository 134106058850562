import { useMutation } from '@apollo/client';
import { DELETE_INSERT } from 'graphql/mutations';

export const useDeleteInsert = () => {
  return useMutation(DELETE_INSERT, {
    onCompleted: (response) => {
      return response;
    },
  });
};
