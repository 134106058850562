import PropTypes from 'prop-types';
import React from 'react';

export const PublicIcon = ({ width = 16, height = 15 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.00002 0.166626C3.95002 0.166626 0.666687 3.44996 0.666687 7.49996C0.666687 11.55 3.95002 14.8333 8.00002 14.8333C12.05 14.8333 15.3334 11.55 15.3334 7.49996C15.3334 3.44996 12.05 0.166626 8.00002 0.166626ZM11.1787 6.25996C11.2372 6.19305 11.2818 6.11512 11.3097 6.03074C11.3377 5.94636 11.3485 5.85723 11.3415 5.76862C11.3345 5.68 11.3099 5.59367 11.2691 5.51471C11.2282 5.43575 11.172 5.36576 11.1037 5.30885C11.0354 5.25194 10.9564 5.20927 10.8714 5.18333C10.7864 5.1574 10.697 5.14873 10.6086 5.15784C10.5202 5.16695 10.4345 5.19366 10.3565 5.23638C10.2786 5.27911 10.2099 5.33699 10.1547 5.40663L7.28802 8.84596L5.80469 7.36196C5.67895 7.24052 5.51055 7.17332 5.33575 7.17484C5.16095 7.17636 4.99375 7.24647 4.87014 7.37008C4.74654 7.49369 4.67642 7.66089 4.6749 7.83569C4.67339 8.01049 4.74058 8.17889 4.86202 8.30463L6.86202 10.3046C6.92752 10.3701 7.00595 10.4212 7.0923 10.4547C7.17864 10.4881 7.27102 10.5033 7.36353 10.4991C7.45605 10.4949 7.54667 10.4714 7.62963 10.4303C7.71258 10.3891 7.78605 10.3311 7.84535 10.26L11.1787 6.25996Z'
        fill='#0B486B'
      />
    </svg>
  );
};

PublicIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
