import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  wrapperOuter: {
    width: '100%',
    display: 'flex',
    padding: '0px 24px',
    flexDirection: 'column',
  },
});
