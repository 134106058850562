import React from 'react';
import PropTypes from 'prop-types';

export const SaveIcon = ({ width = 12, height = 12, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M0.399902 2C0.399902 1.57565 0.568473 1.16869 0.868531 0.868627C1.16859 0.568569 1.57556 0.399998 1.9999 0.399998H2.7999V3.2C2.7999 3.51826 2.92633 3.82348 3.15137 4.04853C3.37642 4.27357 3.68164 4.4 3.9999 4.4H7.1999C7.51816 4.4 7.82339 4.27357 8.04843 4.04853C8.27348 3.82348 8.3999 3.51826 8.3999 3.2V0.399998H8.7031C9.12741 0.400088 9.53432 0.568719 9.8343 0.868798L11.1311 2.1656C11.4312 2.46559 11.5998 2.87249 11.5999 3.2968V10C11.5999 10.4243 11.4313 10.8313 11.1313 11.1314C10.8312 11.4314 10.4242 11.6 9.9999 11.6V7.2C9.9999 6.88174 9.87348 6.57651 9.64843 6.35147C9.42339 6.12643 9.11816 6 8.7999 6H3.1999C2.88164 6 2.57642 6.12643 2.35137 6.35147C2.12633 6.57651 1.9999 6.88174 1.9999 7.2V11.6C1.57556 11.6 1.16859 11.4314 0.868531 11.1314C0.568473 10.8313 0.399902 10.4243 0.399902 10V2Z'
        fill='white'
      />
      <path
        d='M7.59986 0.399998H3.59985V3.2C3.59985 3.30608 3.642 3.40783 3.71701 3.48284C3.79203 3.55785 3.89377 3.6 3.99985 3.6H7.19986C7.30594 3.6 7.40768 3.55785 7.4827 3.48284C7.55771 3.40783 7.59986 3.30608 7.59986 3.2V0.399998Z'
        fill='white'
      />
      <path
        d='M9.1998 7.2V11.6H2.7998V7.2C2.7998 7.09391 2.84195 6.99217 2.91696 6.91715C2.99198 6.84214 3.09372 6.8 3.1998 6.8H8.7998C8.90589 6.8 9.00763 6.84214 9.08265 6.91715C9.15766 6.99217 9.1998 7.09391 9.1998 7.2Z'
        fill='white'
      />
    </svg>
  );
};

SaveIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};
