import { Box } from '@material-ui/core';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { InsertFormContent } from './insert-form-content';
import { SearchParagraph } from './search-paragraph';

export const AddInsertForm = ({ height, refetchInsert }) => {
  const [searchStatus, setSearchStatus] = useState(false);

  return (
    <Box>
      {!searchStatus ? (
        <InsertFormContent
          setSearchStatus={setSearchStatus}
          refetchInsert={refetchInsert}
        />
      ) : (
        <SearchParagraph setSearchStatus={setSearchStatus} height={height} />
      )}
    </Box>
  );
};

AddInsertForm.propTypes = {
  height: PropTypes.string,
  refetchInsert: PropTypes.func,
};
