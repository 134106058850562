export const ROUTES = {
  home: '/',
  public: '/public',
  createBookStepOne: '/create-book-step-one/',
  createBookStepTwo: '/create-book-step-two/',
  createBookStepTree: '/create-book-step-tree/',
  createBookStepFour: '/create-book-step-four/',
  createBookStepFive: '/create-book-step-five/',
  createBookStepSix: '/create-book-step-six/',
  login: '/login',
  devUiPage: '/devUiPage',
};
