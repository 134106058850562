import { gql } from '@apollo/client';

export const GET_INSERTS = gql`
  query getInserts($paragraphId: Int!, $types: [TypeInsert]) {
    getInserts(paragraphId: $paragraphId, types: $types) {
      id
      paragraphId
      audioStart
      audioEnd
      contentPositionStart
      contentPositionEnd
      name
      content
      source
      paragraph {
        id
      }
      type
    }
  }
`;
