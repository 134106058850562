import { theme } from 'constants/index';
import { makeStyles } from '@material-ui/core';

export default makeStyles({
  wrapperAvatar: {
    marginLeft: 24,
    position: 'relative',
  },
  avatar: {
    backgroundColor: theme.colors.white,
  },

  navWrapper: {
    display: 'flex',
    alignItems: 'center',
    ['@media (max-width: 780px)']: {
      display: 'block',
    },
    margin: '0px',
  },
  listItem: {
    display: 'inline-block',
    marginLeft: 40,
  },
  listItemAdd: {
    display: 'inline-block',
    marginLeft: 40,
    padding: '4px 16px 4px 4px',
    background: theme.colors.white,
    borderRadius: '16px',
  },
  list: {
    listStyle: 'none',
    marginLeft: -40,
  },
  listLink: {
    color: theme.colors.white,
    fontSize: 16,
    fontWeight: 500,
    textDecoration: 'none',
  },
  listLinkAdd: {
    color: theme.colors.blue,
    fontSize: 16,
    fontWeight: 500,
    textDecoration: 'none',
    alignItems: 'center',
    display: 'flex',
  },
  icon: {
    marginRight: '10px',
  },
  header: {
    background: theme.colors.blue,
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  logoImg: {
    width: 40,
    height: 40,
  },
  logoText: {
    fontWeight: 900,
    color: theme.colors.white,
    marginLeft: 12,
    fontSize: 24,
    lineHeight: 28 / 24,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 12px',
  },
  container: {
    background: theme.colors.blue,
    paddingTop: 12,
    paddingBottom: 12,
  },
  dropdown: {
    position: 'absolute',
    height: '110px',
    width: '180px',
    background: theme.colors.white,
    zIndex: '10',
    right: '0px',
    top: '45px',
    border: ' 1px solid rgba(0, 0, 0, 0.1)',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.25)',
    padding: '16px',
  },
  name: {
    color: 'rgba(0, 0, 0, 0.4);',
    fontSize: '14px',
    lineHeight: '16px',
  },
  line: {
    width: '100%',
    height: '1px',
    backgroundColor: '#C5D7E0',
    margin: '16px 0px',
  },
  logOff: {
    color: '#0B486B',
    fontSize: '14px',
    lineHeight: '16px',
    cursor: 'pointer',
  },
  iconOut: {
    marginRight: '17px',
  },
});
