export const createInsertList = (
  inputTextValue,
  insertList,
  type = 'insert',
) => {
  let textValue = inputTextValue;
  const arr = [];

  const createArray = () => {
    insertList.map((e) => {
      arr.push({
        start: e?.contentPositionStart,
        end: e?.contentPositionEnd,
        id: e?.id,
      });
    });
    arr.sort((a, b) => (a.start > b.start ? 1 : -1));
  };

  createArray();

  const insertStyle =
    'color:#0B486B ;font-weight: 800; text-decoration: underline;';
  const songStyle = 'background-color: #0B486B;';

  const startValue = (id) => {
    const text = `<span id=${id} style="${
      type === 'insert' ? insertStyle : songStyle
    }">`;
    return {
      text: text,
      length: text.length,
    };
  };
  const endValue = () => {
    const text = `</span>`;
    return { text: text, length: text.length };
  };

  let shift = 0;

  const createText = (e, shift, key, callback) => {
    return (
      textValue?.slice(0, e[key] + shift) +
      callback(e.id).text +
      textValue?.slice(e[key] + shift)
    );
  };

  arr.map((e) => {
    textValue = createText(e, shift, 'start', startValue);
    shift = shift + startValue(e.id).length;
    textValue = createText(e, shift, 'end', endValue);
    shift = shift + endValue(e.id).length;
  });

  return textValue;
};
