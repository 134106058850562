import { theme } from 'constants/index';
import { makeStyles } from '@material-ui/core';

export default makeStyles({
  wrapperOuter: {
    paddingRight: '20px',
  },
  hedTitle: {
    color: theme.colors.gray,
    backgroundColor: '#F2F2F2',
    padding: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  parargaphLine: {
    color: theme.colors.paleBlue,
    backgroundColor: theme.colors.white,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    lineHeight: '16px',
    fontSize: '14px',
    fontWeight: 'normal',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  parargaphChildWrapper: {
    backgroundColor: theme.colors.dropdownBackground,
    padding: '20px 20px 20px 40px',
    marginBottom: '20px',
  },
  parargaphChildLine: {
    color: theme.colors.paleBlue,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    lineHeight: '16px',
    fontSize: '14px',
    fontWeight: 'normal',
    marginBottom: '20px',
  },
  parargaphWrapper: {
    backgroundColor: theme.colors.white,
  },
  parargaphDescription: {
    padding: '16px 0px 16px 20px',
    color: 'rgba(0, 0, 0, 0.4)',
  },
  icon: {
    width: '15px',
    height: '15px',
    cursor: 'pointer',
  },
  saveIcon: {
    cursor: 'pointer',
  },
  dropdownWrappr: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  dropdownWrapprInner: {
    marginLeft: '26px',
  },
  dropdownContent: {
    width: '141px',
    height: '90px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: '15px',
    justifyContent: 'space-around',
  },
  dropdownContentRow: {
    fontSize: '13px',
    lineHeight: '160%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  dropdownContentText: {
    marginLeft: '5px',
  },
  addElement: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '146%',
    fontSize: '15px',
    fontWeight: 'bold',
    color: theme.colors.blue,
  },
  addElementIcon: {
    marginRight: '15px',
    cursor: 'pointer',
  },
  addParagraph: {
    paddingBottom: '16px',
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
  ml_10: {
    marginLeft: '10px',
  },
  wrapperInner: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    height: 'calc(544px - 85px)',
    backgroundColor: theme.colors.white,
    overflowY: 'auto',
    scrollbarColor: '#9FB6C1 #EAE8E8',
    scrollbarWidth: 'thin !important',
    '&::-webkit-scrollbar': {
      width: '4px',
      backgroundColor: '#EAE8E8',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#9FB6C1',
      borderRadius: 2,
    },
  },
  formAdd: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});
