import {
  Layout,
  ShowStepCreate,
  NextButton,
  EditFormula,
  RequestHandler,
} from 'components';
import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import { ROUTES } from 'constants/index';
import { useAddOrUpdateParagraph } from 'hooks/use-mutations';
import { useGetTextbookById } from 'hooks/use-queries';
import { useParagraph, useParagraphAsaide, useCurrentText } from 'hooks';
import { useParams } from 'react-router';
import { wirishApi } from 'utils';

export const CreateBookStepFour = () => {
  const classes = useStyles();

  const { id } = useParams();
  const {
    data: dataBook,
    loading: loadingBook,
    error: errorBook,
  } = useGetTextbookById(id);

  const { paragraphList, currentParagraph, setCurrentParagraph } = useParagraph(
    dataBook?.getTextbookById,
  );

  const { textValue, setTextValue } = useCurrentText(
    currentParagraph,
    paragraphList,
    paragraphList[currentParagraph],
  );

  useParagraphAsaide(dataBook, setCurrentParagraph);

  const [
    mutationParagraph,
    { loading: loadingParagraph, error: errorParagraph },
  ] = useAddOrUpdateParagraph(() => {});

  const handleSubmit = () => {
    let paragraph = paragraphList[currentParagraph];
    mutationParagraph({
      variables: {
        id: paragraph.id,
        textbookId: id,
        name: paragraph.name,
        chapterId: paragraph.chapterId,
        content: wirishApi.parseAfterEditor(textValue),
        parsed: true,
      },
    });
  };

  return (
    <Layout hideAsaid={false}>
      <RequestHandler
        loading={loadingBook || loadingParagraph}
        error={errorBook || errorParagraph}
      >
        <Box className={classes.wrapperOuter}>
          <ShowStepCreate
            bookName={dataBook?.getTextbookById?.title}
            onSubmit={handleSubmit}
          />
          <Box className={classes.wrapperInner}>
            <EditFormula
              textValue={textValue}
              setTextValue={setTextValue}
              setCurrentParagraph={setCurrentParagraph}
              paragraphList={paragraphList}
              currentParagraph={currentParagraph}
            />
          </Box>
          <NextButton
            back={{
              path: ROUTES.createBookStepTree,
              text: 'Редактирование текста',
            }}
            next={{
              path: ROUTES.createBookStepFive,
              text: 'Преобразование в речь',
            }}
          />
        </Box>
      </RequestHandler>
    </Layout>
  );
};
