import { Box } from '@material-ui/core';
import React from 'react';

import { ParagraphIcon, SearchIcon } from 'icons/index';
import { AsideMenu } from './aside-menu/aside-menu';
import { CSSTransition } from 'react-transition-group';
import { useAsideContext } from 'context';
import useStyles from './styles';

export const Aside = () => {
  const classes = useStyles();
  const { menuOpen, handleOpen, setMenuOpen } = useAsideContext();

  return (
    <Box
      className={classes.aside}
      component='aside'
      onMouseEnter={handleOpen}
      onMouseLeave={() => setMenuOpen(false)}
    >
      <CSSTransition
        in={menuOpen}
        unmountOnExit
        timeout={200}
        classNames='slide'
      >
        <AsideMenu onClose={() => setMenuOpen(false)} />
      </CSSTransition>

      <Box onClick={handleOpen}>
        <Box className={classes.item}>
          <Box className={classes.itemButton}>
            <ParagraphIcon />
          </Box>
        </Box>
        <Box className={classes.item}>
          <Box className={classes.itemButton}>
            <SearchIcon />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
