import { makeStyles } from '@material-ui/core';

export default makeStyles({
  content: {
    display: 'flex',
    minHeight: 'calc(100% - 64px)',
    bottom: '0px',
  },
  wrapper: {
    height: '100vh',
    minHeight: 400,
  },
  infoWrapper: {
    width: 'calc(100% - 64px)',
    minWidth: 1024,
    padding: '48px 64px 48px 0px',
    backgroundColor: (props) => props.backgroundColor,
    display: 'flex',
    justifyContent: 'center',
  },
});
