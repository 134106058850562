import PropTypes from 'prop-types';
import React from 'react';

export const SelectorOpenIcon = ({
  width = 16,
  height = 11,
  onClick,
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      className={className}
    >
      <path
        d='M12.939 0.938965L8 5.87896L3.061 0.938965L0.938995 3.06096L8 10.121L15.061 3.06096L12.939 0.938965Z'
        fill='#0B486B'
      />
    </svg>
  );
};

SelectorOpenIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
