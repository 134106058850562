import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import useStyles from '../styles';
import { SearchIcon, DropzoneIcon, VideoIcon } from 'icons';
import { useEffect, useState } from 'react';
import { ButtonCustom, Dropzone, TextFieldFormik, Preloader } from 'components';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { ButtonSave } from '../button-save';
import { useAddInsert, useSetAudioStart } from 'hooks';
import { useCreateBookContext } from 'context';
import { addInsert } from 'validation';

export const InsertFormContent = ({ setSearchStatus, refetchInsert }) => {
  const [typeDropzone, setTypeDropzone] = useState('imageInsert');

  const classes = useStyles({
    typeDropzone: typeDropzone,
  });

  const [mutation, { loading }] = useAddInsert();
  const { createInsert, handleCreateInsert } = useCreateBookContext();

  useEffect(() => {
    if (!createInsert?.source) return;
    setTypeDropzone('videoInsert');
  }, [createInsert?.source]);

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const { audioDurationElement, audioEnd, audioStart } = useSetAudioStart();
  const typeValue = (value) => {
    if (!value) return 0;
    return parseInt(value);
  };

  if (loading) {
    return (
      <Box className={classes.preloadeWrapper}>
        <Preloader />
      </Box>
    );
  }

  return (
    <Box>
      <Box className={classes.textTitle}>
        Выделите фрагмент книги, на протяжении которого будет отображаться этот
        пример и выберите готовый вариант из Базы знаний
      </Box>
      <Box className={classes.buttonSearchWrapper}>
        <ButtonCustom
          classNameCustom={classes.buttonCustom}
          style={{ width: '100%' }}
          onClick={() => setSearchStatus(true)}
        >
          <SearchIcon className={classes.iconButton} width={20} height={20} />
          Поиск готовых примеров в Базе Знаний
        </ButtonCustom>
      </Box>
      <Formik
        enableReinitialize={true}
        validationSchema={addInsert}
        initialValues={{
          fileBook: undefined,
          name: createInsert?.name ? createInsert?.name : '',
          url: createInsert?.source ? createInsert?.source : '',
          description: createInsert?.content ? createInsert?.content : '',
          audioDuration: audioEnd,
          audioStart: audioStart,
        }}
        onSubmit={(data) => {
          mutation({
            variables: {
              data: {
                paragraphId: parseInt(createInsert.paragraphId),
                audioStart: parseFloat(data.audioStart.toFixed(2)),
                audioEnd: parseFloat(data.audioDuration.toFixed(2)),
                contentPositionStart: checked
                  ? 0
                  : parseInt(typeValue(createInsert.contentPositionStart)),
                contentPositionEnd: checked
                  ? 0
                  : parseInt(typeValue(createInsert.contentPositionEnd)),
                name: data.name,
                content: data.description,
                url: data?.url,
                file: data?.fileBook,
                isMuted: checked,
                type: typeDropzone === 'imageInsert' ? 'IMAGE' : 'VIDEO',
              },
            },
          })
            .then(() => {
              handleCreateInsert('content', '');
              handleCreateInsert('name', '');
              handleCreateInsert('source', '');
              refetchInsert();
            })
            .catch((error) => {
              console.info(error);
            });
        }}
      >
        <Form>
          <Box className={classes.textTitle}>...или создайте собственный</Box>
          <TextFieldFormik
            name='name'
            placeholder='Заголовок примера (необязательно)'
            rootClass={classes.input}
            type='text'
          />
          <Box className={classes.buttonSearchWrapper}>
            <ButtonCustom
              onClick={() => {
                setTypeDropzone('imageInsert');
              }}
              classNameCustom={classes.buttonCustomLeft}
            >
              <DropzoneIcon
                className={classes.iconButton}
                color={typeDropzone === 'imageInsert' ? 'white' : '#4D6E7D'}
                width={20}
                height={20}
              />
              Изображение
            </ButtonCustom>
            <ButtonCustom
              onClick={() => {
                setTypeDropzone('videoInsert');
              }}
              classNameCustom={classes.buttonCustomRight}
            >
              <VideoIcon
                className={classes.iconButton}
                width={20}
                height={20}
                color={typeDropzone === 'videoInsert' ? 'white' : '#4D6E7D'}
              />
              Видео
            </ButtonCustom>
          </Box>
          <Dropzone type={typeDropzone} name='fileBook' />
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '10px 0px',
            }}
          >
            <TextFieldFormik
              name='audioStart'
              placeholder='начало на аудиодорожке (сек)'
              rootClass={classes.inputSong}
              type='number'
            />
            <TextFieldFormik
              name='audioDuration'
              placeholder='конец на аудиодорожке (сек)'
              rootClass={classes.inputSong}
              type='number'
            />
          </Box>

          {typeDropzone === 'videoInsert' && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  label='test'
                />
              }
              label='Видео со звуком'
            />
          )}

          <TextFieldFormik
            className={classes.inputDescription}
            name='description'
            placeholder='Краткое описание'
            multiline
            rows={14}
          />
          <ButtonSave />
        </Form>
      </Formik>
      <Box>{audioDurationElement}</Box>
    </Box>
  );
};

InsertFormContent.propTypes = {
  setSearchStatus: PropTypes.func,
  refetchInsert: PropTypes.func,
};
