import { gql } from '@apollo/client';
import { PARAGRAPH } from '../fragment';

export const ADD_OR_UPDATE_CHAPTER = gql`
  ${PARAGRAPH}
  mutation addOrUpdateChapter($id: ID, $textbookId: ID!, $name: String!) {
    addOrUpdateChapter(id: $id, textbookId: $textbookId, name: $name) {
      id
      name
      paragraphs {
        ...FragmentParagraphs
      }
    }
  }
`;
