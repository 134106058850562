import {
  Layout,
  ShowStepCreate,
  NextButton,
  SoundChapter,
  RequestHandler,
  SoundList,
  Preloader,
} from 'components';
import { useStyles } from './styles';
import { Box, Grid } from '@material-ui/core';
import { ROUTES } from 'constants/index';
import { useParams } from 'react-router';
import {
  useParagraph,
  useParagraphAsaide,
  useGetInserts,
  useInsert,
} from 'hooks';
import { useGetTextbookById } from 'hooks/use-queries';
import { useRef } from 'react';
import { useCreateBookContext } from 'context';

export const CreateBookStepFive = () => {
  const classes = useStyles();
  const { id } = useParams();
  const refText = useRef();
  const { awaitVoised } = useCreateBookContext();

  const {
    data: dataBook,
    loading: loadingBook,
    error: errorBook,
    refetch: refetchBook,
  } = useGetTextbookById(id);

  const { paragraphList, currentParagraph, setCurrentParagraph } = useParagraph(
    dataBook?.getTextbookById,
  );

  useParagraphAsaide(dataBook, setCurrentParagraph);

  const { data: dataInsert, refetch: refetchInsert } = useGetInserts(
    currentParagraph,
    ['AUDIO'],
  );

  const { textSelectFormula, setSelectValue } = useInsert({
    searchText: null,
    textValue: paragraphList[currentParagraph]?.content,
    currentParagraph,
    dataInsert,
    refText,
    audio: paragraphList[currentParagraph]?.audio,
  });

  return (
    <Layout hideAsaid={false}>
      <Box className={classes.wrapperOuter}>
        <RequestHandler loading={loadingBook} error={errorBook}>
          <ShowStepCreate bookName={dataBook?.getTextbookById?.title} />
          <Box
            className={classes.wrapperInner}
            style={{ position: 'relative' }}
          >
            <Grid container>
              <Grid item xs={5} md={5}>
                <SoundList
                  refetchInsert={refetchInsert}
                  insertSoundList={dataInsert?.getInserts}
                  setSelectValue={setSelectValue}
                />
              </Grid>
              <Grid item xs={7} md={7}>
                <SoundChapter
                  setCurrentParagraph={setCurrentParagraph}
                  paragraph={paragraphList[currentParagraph]}
                  paragraphList={paragraphList}
                  currentParagraph={currentParagraph}
                  refetchBook={refetchBook}
                  refText={refText}
                  textSelectFormula={textSelectFormula}
                />
              </Grid>
            </Grid>
            {awaitVoised ? (
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  bottom: '0',
                  left: '0',
                  right: '0',
                  backgroundColor: 'white',
                  zIndex: '10',
                  opacity: '0.45',
                }}
              >
                <Preloader />
              </div>
            ) : null}
          </Box>
          <NextButton
            back={{
              path: ROUTES.createBookStepFour,
              text: 'Формулы',
            }}
            next={{
              path: ROUTES.createBookStepSix,
              text: 'Добавление примеров',
            }}
          />
        </RequestHandler>
      </Box>
    </Layout>
  );
};
