import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  textErrorCenter: {
    textAlign: 'center',
  },
  wrapper: {
    height: `calc(100vh - 64px)`,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '24px',
  },
});
