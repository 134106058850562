import { Form, Formik } from 'formik';
import useStyles from 'components/table-of-contents/styles';
import { TextFieldFormik, ButtonCustom, RequestHandler } from 'components';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  useAddOrUpdateChapter,
  useAddOrUpdateParagraph,
} from 'hooks/use-mutations';
import { useParams } from 'react-router';
import { addValidationSchema } from 'validation';

export const AddForm = ({
  setAdd,
  type = 'Chapter',
  chapterId,
  paragraphId,
}) => {
  const classes = useStyles();
  const { id } = useParams();

  const [mutationChapter, { loading: loadingChapter, error: errorChapter }] =
    useAddOrUpdateChapter(setAdd);

  const [
    mutationParagraph,
    { loading: loadingParagraph, error: errorParagraph },
  ] = useAddOrUpdateParagraph(setAdd);

  const onSubmit = (data) => {
    if (type === 'Chapter') {
      mutationChapter({
        variables: {
          id: chapterId ? chapterId : undefined,
          textbookId: id,
          name: data?.name,
        },
      });
    }
    if (type === 'Paragraph') {
      mutationParagraph({
        variables: {
          textbookId: id,
          name: data?.name,
          chapterId: chapterId,
          content: '',
          id: paragraphId ? paragraphId : undefined,
        },
      });
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ name: '' }}
      validationSchema={addValidationSchema}
      onSubmit={onSubmit}
    >
      <RequestHandler
        loading={loadingChapter || loadingParagraph}
        error={errorChapter || errorParagraph}
      >
        <Form className={classes.formAdd}>
          <Box className={classes.formLine}>
            <TextFieldFormik
              name='name'
              placeholder='название'
              rootClass={classes.input}
              type='text'
            />
          </Box>
          <Box className={classes.buttonWrapper}>
            <ButtonCustom type='submit' variant='contained'>
              создать
            </ButtonCustom>
            <ButtonCustom
              variant='contained'
              style={{ marginLeft: '5px' }}
              onClick={() => {
                setAdd(false);
              }}
            >
              отменить
            </ButtonCustom>
          </Box>
        </Form>
      </RequestHandler>
    </Formik>
  );
};

AddForm.propTypes = {
  setAdd: PropTypes.any,
  type: PropTypes.string,
  chapterId: PropTypes.string,
  paragraphId: PropTypes.string,
};
