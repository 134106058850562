import { gql } from '@apollo/client';

export const SIGN_IN = gql`
  query signIn($email: String!, $password: String!, $role: Role) {
    signIn(email: $email, password: $password, role: $role) {
      token
      user {
        id
        email
        fullName
      }
    }
  }
`;
