import React from 'react';
import PropTypes from 'prop-types';

export const EditIcon = ({ width = 14, height = 13 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.75 11.5625H1.25C0.973437 11.5625 0.75 11.7859 0.75 12.0625V12.625C0.75 12.6938 0.80625 12.75 0.875 12.75H13.125C13.1938 12.75 13.25 12.6938 13.25 12.625V12.0625C13.25 11.7859 13.0266 11.5625 12.75 11.5625ZM3.02656 10.25C3.05781 10.25 3.08906 10.2469 3.12031 10.2422L5.74844 9.78125C5.77969 9.775 5.80938 9.76094 5.83125 9.7375L12.4547 3.11406C12.4692 3.09961 12.4807 3.08244 12.4885 3.06354C12.4963 3.04463 12.5004 3.02437 12.5004 3.00391C12.5004 2.98344 12.4963 2.96318 12.4885 2.94428C12.4807 2.92538 12.4692 2.90821 12.4547 2.89375L9.85781 0.295313C9.82812 0.265625 9.78906 0.25 9.74687 0.25C9.70469 0.25 9.66563 0.265625 9.63594 0.295313L3.0125 6.91875C2.98906 6.94219 2.975 6.97031 2.96875 7.00156L2.50781 9.62969C2.49261 9.71339 2.49804 9.79954 2.52364 9.88067C2.54923 9.96181 2.59421 10.0355 2.65469 10.0953C2.75781 10.1953 2.8875 10.25 3.02656 10.25Z'
        fill='#0B486B'
      />
    </svg>
  );
};

EditIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
