import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

export const AsideContext = createContext();
export const useAsideContext = () => {
  const context = useContext(AsideContext);
  if (!context) {
    throw new Error('useAsideContext must be used within a AsideProvider');
  }
  return context;
};

export const AsideProvider = ({ children }) => {
  const [serchValue, setSearchValue] = useState('');

  const [menuOpen, setMenuOpen] = useState(false);

  const [chapterList, setChapterList] = useState([]);

  const [linkParagraph, setLinkParagraph] = useState(null);

  const handleOpen = () => {
    setMenuOpen(true);
  };

  return (
    <AsideContext.Provider
      value={{
        serchValue: serchValue,
        setSearchValue: setSearchValue,
        handleOpen: handleOpen,
        menuOpen: menuOpen,
        setMenuOpen: setMenuOpen,
        setChapterList: setChapterList,
        chapterList: chapterList,
        linkParagraph: linkParagraph,
        setLinkParagraph: setLinkParagraph,
      }}
    >
      {children}
    </AsideContext.Provider>
  );
};

AsideProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
