import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import { ArrowRightDarkIcon, ArrowLeftDarkIcon, InfoIcon } from 'icons';
import { Editor } from './ckeditor/editor';
import PropTypes from 'prop-types';
import { nextParagraph } from 'utils';

export const EditFormula = ({
  textValue,
  setTextValue,
  setCurrentParagraph,
  paragraphList,
  currentParagraph,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.wrapperOuter}>
      <Box className={classes.navigationWrapper}>
        <ArrowLeftDarkIcon
          onClick={() => {
            setCurrentParagraph(
              nextParagraph(paragraphList, currentParagraph, 'back'),
            );
          }}
          className={classes.iconWrapper}
        />
        <Box className={classes.navigationText}>
          {paragraphList[currentParagraph]?.name}
        </Box>
        <ArrowRightDarkIcon
          onClick={() => {
            setCurrentParagraph(
              nextParagraph(paragraphList, currentParagraph, 'next'),
            );
          }}
          className={classes.iconWrapper}
        />
      </Box>
      <Box className={classes.line}></Box>
      <Box className={classes.textWrapper}>
        <Editor textValue={textValue} setTextValue={setTextValue} />
      </Box>
      <Box className={classes.infoWrapper}>
        <InfoIcon />
        <Box className={classes.infoText}>
          Кликните дважды на формулу для редактирования
        </Box>
      </Box>
    </Box>
  );
};

EditFormula.propTypes = {
  textValue: PropTypes.string,
  setTextValue: PropTypes.func,
  setCurrentParagraph: PropTypes.func,
  paragraphList: PropTypes.object,
  currentParagraph: PropTypes.string,
};
