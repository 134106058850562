/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { useEffect, useState, useRef } from 'react';
import { theme } from 'constants/index';
import { useCreateBookContext } from 'context';
import { textForDuration, replaceHtml } from 'utils';

export const useSelectText = (loading, text, searchInHtml = false) => {
  const { handleCreateInsert } = useCreateBookContext();

  const event = () => {
    if (window.getSelection().toString().length === 0) return;
    setSearctText(
      finDSelectText(text, getSelectionHtml(), range, handleCreateInsert),
    );
  };

  const [searchText, setSearctText] = useState();
  const [range, setRange] = useState(100);

  const handleSetRange = (value) => {
    return setRange(parseInt(value));
  };

  const refWraper = useRef(null);
  useEffect(() => {
    if (refWraper && refWraper.current) {
      refWraper.current.addEventListener('mouseup', event);
      return function cleanupListener() {
        refWraper?.current?.removeEventListener('mouseup', event);
      };
    }
  }, [loading, range, text]);

  return { searchText, refWraper, setRange: handleSetRange };
};

const findStartIndex = (text, textSearch, index, iteration, range) => {
  let startIndex;
  let shift;
  let shiftTextSearch = textSearch.slice(index, range);
  startIndex = text?.indexOf(shiftTextSearch);
  if (startIndex === -1) {
    iteration++;
    if (iteration === textSearch.range) return;
    return findStartIndex(
      text,
      textSearch,
      index + iteration,
      iteration,
      range,
    );
  } else {
    shift = textSearch.indexOf(shiftTextSearch);
    if (shift > 0) {
      shift = shift + 1;
    }
    if (shift === 7) {
      //Фикс ложного смещения на определенном диапазоне
      //длины строки поиска. Смещается на 1 тег.Всегда 7.
      shift = 1;
    }
  }

  return startIndex - shift;
};

const nextTextPosition = (text, textSearch, range, shift, startPosition) => {
  let endindex = text?.indexOf(
    textSearch.slice(
      textSearch.length - range - shift,
      textSearch?.length - shift,
    ),
  );
  if (endindex < startPosition) {
    let newText = text.slice(startPosition, text.length);
    endindex = newText?.indexOf(
      textSearch.slice(
        textSearch.length - range - shift,
        textSearch?.length - shift,
      ),
    );
  }

  return startPosition + endindex;
};

const findEndIndex = (text, textSearch, range, startPosition, shift = 0) => {
  // debugger;
  let endindex =
    text?.indexOf(
      textSearch.slice(textSearch.length - range, textSearch?.length),
    ) + range;
  if (endindex < startPosition) {
    const newText = text.slice(startPosition, text.length);
    let newIndex = newText.indexOf(
      textSearch.slice(
        textSearch.length - range - shift,
        textSearch?.length - shift,
      ),
    );
    if (newIndex === -1) {
      endindex = -1;
    } else {
      endindex = startPosition + newIndex + shift + range;
    }
  }

  if (endindex !== -1) {
    return endindex;
  }

  shift = shift + 1;
  return findEndIndex(text, textSearch, range, startPosition, shift);
};

export const finDSelectText = (
  text,
  textSearch,
  range = 20,
  handleCreateInsert,
) => {
  if (!text) return '';
  // console.log({ range });

  const defaultStartPosition = 0;
  const positionNotFound = -1;
  let startPosition;
  let endPosition;
  let iteration = 0;
  if (textSearch.length > range) {
    startPosition = findStartIndex(
      text,
      textSearch,
      defaultStartPosition,
      iteration,
      range,
    );
    endPosition = findEndIndex(text, textSearch, range, startPosition);
  } else {
    startPosition = text?.indexOf(textSearch);
    endPosition = startPosition + textSearch?.length;
  }

  handleCreateInsert('contentPositionStart', startPosition);
  handleCreateInsert('contentPositionEnd', endPosition);

  const firstSubstring = text.slice(
    defaultStartPosition,
    startPosition === positionNotFound ? defaultStartPosition : startPosition,
  );
  const selectSubstring = text.slice(startPosition, endPosition);
  const endSubstring = text.slice(endPosition, text.length);

  const fullText =
    firstSubstring +
    `<span style="color:${theme.colors.blue} ">${selectSubstring} <span id="button-id" style="position:relative" ></span> </span> ${endSubstring}`;
  textForDuration({
    handleCreateInsert,
    firstSubstring: replaceHtml(firstSubstring),
    selectSubstring: replaceHtml(selectSubstring),
    endSubstring: replaceHtml(endSubstring),
  });

  return {
    firstSubstring,
    selectSubstring,
    endSubstring,
    fullText,
    oldText: text,
    startPosition,
    endPosition,
  };
};

function getSelectionHtml() {
  var html = '';
  if (typeof window.getSelection != 'undefined') {
    var sel = window.getSelection();
    if (sel.rangeCount) {
      var container = document.createElement('div');
      for (var i = 0, len = sel.rangeCount; i < len; ++i) {
        container.appendChild(sel.getRangeAt(i).cloneContents());
      }
      html = container.innerHTML;
    }
  } else if (typeof document.selection != 'undefined') {
    if (document.selection.type == 'Text') {
      html = document.selection.createRange().htmlText;
    }
  }
  return `${html}`;
}
