import PropTypes from 'prop-types';
import React from 'react';

export const PlayIcon = ({ width = 14, height = 16, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 14 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.394 9.04553L2.8495 14.5835C2.0395 15.053 1 14.4845 1 13.538V2.46203C1 1.51703 2.038 0.947034 2.8495 1.41803L12.394 6.95603C12.5783 7.06122 12.7314 7.21326 12.8379 7.39674C12.9445 7.58023 13.0006 7.78862 13.0006 8.00078C13.0006 8.21295 12.9445 8.42134 12.8379 8.60482C12.7314 8.7883 12.5783 8.94035 12.394 9.04553Z'
        fill='white'
      />
      <path
        d='M3.10024 15.0161L3.10043 15.016L12.6419 9.47976C12.6424 9.47944 12.643 9.47913 12.6435 9.47881C12.9036 9.32994 13.1199 9.11507 13.2703 8.85588C13.4212 8.59614 13.5006 8.30113 13.5006 8.00078C13.5006 7.70044 13.4212 7.40543 13.2704 7.14569L12.8379 7.39674L13.2703 7.14569C13.1199 6.88652 12.9037 6.67166 12.6436 6.52279C12.643 6.52246 12.6425 6.52213 12.6419 6.52181L3.10049 0.985594C3.10047 0.985583 3.10045 0.985572 3.10043 0.985561C1.9704 0.329739 0.5 1.11467 0.5 2.46203V13.538C0.5 14.8875 1.97229 15.6699 3.10024 15.0161Z'
        stroke='black'
        strokeOpacity='0.1'
      />
    </svg>
  );
};

PlayIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};
