import { Box } from '@material-ui/core';
import useStyles from 'components/table-of-contents/styles';
import { SelectorOpenIcon, SelectorCloseIcon, Addicon } from 'icons';
import { useState } from 'react';
import { Chapter } from './chapter/chapter';
import { AddForm } from 'components/table-of-contents/paragraph/add-form';
import PropTypes from 'prop-types';

export const TableOfContents = ({ chapters }) => {
  const classes = useStyles();
  const [addParagraph, setAddParagraph] = useState(false);

  const [openTableOfContents, setTableOfContents] = useState(true);

  return (
    <Box className={classes.wrapperOuter}>
      <Box className={classes.hedTitle}>
        Двухуровневое оглавление
        {openTableOfContents ? (
          <SelectorOpenIcon
            onClick={() => {
              setTableOfContents(false);
            }}
            className={classes.icon}
          />
        ) : (
          <SelectorCloseIcon
            onClick={() => {
              setTableOfContents(true);
            }}
            className={classes.icon}
          />
        )}
      </Box>
      <Box className={classes.wrapperInner}>
        <Box
          className={classes.parargaphWrapper}
          style={{ display: openTableOfContents ? 'block' : 'none' }}
        >
          <Box className={classes.parargaphDescription}>Оглавление</Box>
          {chapters.map((chapterItem, index) => {
            if (!chapterItem?.id) return;
            return (
              <Chapter
                key={index + chapterItem.name}
                chapterItem={chapterItem}
                index={index}
              />
            );
          })}
          <Box
            className={[classes.parargaphLine, classes.addParagraph].join(' ')}
          >
            {addParagraph ? (
              <AddForm setAdd={setAddParagraph} type='Chapter' />
            ) : (
              <Box
                className={classes.addElement}
                onClick={() => setAddParagraph(!addParagraph)}
              >
                <Addicon
                  className={classes.addElementIcon}
                  onClick={() => setAddParagraph(!addParagraph)}
                />
                Добавить раздел
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

TableOfContents.propTypes = {
  chapters: PropTypes.array,
};
