import { NavLink } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { ROUTES } from 'constants/index';
import { Addicon } from 'icons';
import useStyles from './styles';

export const HeaderMenu = () => {
  const classes = useStyles();

  const nav = [
    {
      name: 'Создать учебник',
      path: ROUTES.createBookStepOne,
      classes: classes.listItemAdd,
      addItem: true,
    },
    { name: 'Опубликованные', path: ROUTES.public, addItem: false },
    { name: 'Сохранённые', path: ROUTES.home, addItem: false },
  ].map((navItem) => (
    <Box
      component='li'
      key={navItem.name}
      className={navItem.addItem ? classes.listItemAdd : classes.listItem}
    >
      <NavLink
        className={navItem.addItem ? classes.listLinkAdd : classes.listLink}
        to={navItem.path}
      >
        {navItem.addItem ? <Addicon className={classes.icon} /> : null}
        {navItem.name}
      </NavLink>
    </Box>
  ));

  return (
    <Box component='nav'>
      <Box className={classes.navWrapper} component='ul'>
        {nav}
      </Box>
    </Box>
  );
};
