import { useMutation } from '@apollo/client';
import { SET_VOICE_PARAGRAPH_DURATIOM } from 'graphql/mutations';

export const useSetVoiceParagraphDuration = () => {
  return useMutation(SET_VOICE_PARAGRAPH_DURATIOM, {
    onCompleted: (response) => {
      return response;
    },
  });
};
