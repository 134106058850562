/* eslint-disable no-debugger */
import { useMutation } from '@apollo/client';
import { UPDATE_INIT_TEXT } from 'graphql/mutations';
import { GET_INIT_TEXT_BY_TEXT_BOOK_ID } from 'graphql/query';
import { useParams } from 'react-router';

export const useUpdateInitText = () => {
  const { id } = useParams();

  const updateCache = (cache, { data }) => {
    const newText = data;

    cache.writeQuery({
      query: GET_INIT_TEXT_BY_TEXT_BOOK_ID,
      variables: { textbookId: id },
      data: {
        getInitTextByTextbookId: {
          text: newText,
        },
      },
    });
  };
  return useMutation(UPDATE_INIT_TEXT, {
    update: updateCache,
    onCompleted: (response) => {
      return response;
    },
  });
};
