import { FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { SERVER_ERROR_LIST } from 'constants/index';

export const ErrorIndicator = ({ error }) => {
  const message = SERVER_ERROR_LIST.find(
    (item) => item.type === error?.message,
  );

  return (
    <FormHelperText error>
      {message?.text || 'Произошла ошибка :('}
    </FormHelperText>
  );
};

ErrorIndicator.propTypes = {
  error: PropTypes.obj,
};
