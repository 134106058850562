import React from 'react';

export const BookIcon = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.02 30H35V6.66671C35 4.82837 33.505 3.33337 31.6667 3.33337H10C7.99 3.33337 5 4.66504 5 8.33337V31.6667C5 35.335 7.99 36.6667 10 36.6667H35V33.3334H10.02C9.25 33.3134 8.33333 33.01 8.33333 31.6667C8.33333 31.4984 8.34833 31.3484 8.37333 31.2117C8.56 30.2517 9.34667 30.0167 10.02 30ZM14.4133 12.7367C15.1071 12.0518 16.0427 11.6678 17.0175 11.6678C17.9923 11.6678 18.9279 12.0518 19.6217 12.7367L19.995 13.1017L20.3667 12.7367C21.0607 12.0517 21.9965 11.6676 22.9717 11.6676C23.9468 11.6676 24.8827 12.0517 25.5767 12.7367C25.9209 13.0678 26.1948 13.465 26.3819 13.9044C26.5689 14.3439 26.6654 14.8166 26.6654 15.2942C26.6654 15.7718 26.5689 16.2445 26.3819 16.684C26.1948 17.1235 25.9209 17.5206 25.5767 17.8517L19.9967 23.3334L14.415 17.8517C14.0704 17.5209 13.7962 17.1238 13.6089 16.6844C13.4215 16.245 13.3249 15.7723 13.3247 15.2946C13.3246 14.8169 13.4209 14.3441 13.608 13.9045C13.7951 13.465 14.069 13.0678 14.4133 12.7367Z'
        fill='white'
      />
    </svg>
  );
};
