import {
  Layout,
  ShowStepCreate,
  NextButton,
  RequestHandler,
  AddExamples,
  AddList,
  TextField,
} from 'components';
import { Box, Grid } from '@material-ui/core';
import { ROUTES } from 'constants/index';
import { useParams } from 'react-router';
import { useStyles } from './styles';
import { useGetTextbookById } from 'hooks/use-queries';
import {
  useParagraph,
  useParagraphAsaide,
  useCurrentText,
  useSelectText,
  useGetInserts,
  useInsert,
} from 'hooks';

export const CreateBookStepSix = () => {
  const { id } = useParams();

  const classes = useStyles();

  const {
    data: dataBook,
    loading: loadingBook,
    error: errorBook,
  } = useGetTextbookById(id);

  const { paragraphList, currentParagraph, setCurrentParagraph } = useParagraph(
    dataBook?.getTextbookById,
  );

  const { textValue } = useCurrentText(currentParagraph, paragraphList, true);

  useParagraphAsaide(dataBook, setCurrentParagraph);

  const { searchText, refWraper, range, setRange } = useSelectText(
    loadingBook,
    textValue,
    paragraphList[currentParagraph]?.parsed,
  );

  const { data: dataInsert, refetch: refetchInsert } = useGetInserts(
    currentParagraph,
    ['VIDEO', 'TEXT', 'IMAGE'],
  );
  useInsert({
    searchText,
    textValue,
    currentParagraph,
    dataInsert,
    audio: paragraphList[currentParagraph]?.audio,
  });

  return (
    <Layout>
      <Box className={classes.wrapperOuter}>
        <RequestHandler loading={loadingBook} error={errorBook}>
          <ShowStepCreate
            bookName={dataBook?.getTextbookById?.title}
            onSubmit={() => {}}
          />
          <Box className={classes.wrapperInner} sx={{ flexGrow: 1 }}>
            <Grid container>
              <Grid item xs={12}>
                <Box
                  style={{
                    backgroundColor: 'white',
                    width: '100%',
                    height: '100px',
                    marginBottom: '5px',
                    padding: '5px',
                    overflow: 'auto',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: searchText?.selectSubstring,
                  }}
                />
                <TextField
                  style={{ display: 'none' }}
                  value={range}
                  onChange={setRange}
                  typeFilter={false}
                  name='range'
                  placeholder='точность поиска (количество совпадающих символов)'
                />
              </Grid>
              <Grid item xs={5} md={5}>
                <AddList refetchInsert={refetchInsert} />
              </Grid>
              <Grid ref={refWraper} item xs={7} md={7}>
                <AddExamples
                  textValue={textValue}
                  setCurrentParagraph={setCurrentParagraph}
                  paragraphList={paragraphList}
                  currentParagraph={currentParagraph}
                />
              </Grid>
            </Grid>
          </Box>
          <NextButton
            back={{
              path: ROUTES.createBookStepFive,
              text: 'Озвучка',
            }}
            next={{
              path:
                // eslint-disable-next-line no-undef
                `${process.env.REACT_APP_API_HOST_READER}` + '/book/' + id,
              text: 'Предпросмотр',
              noId: true,
              redirect: true,
            }}
          />
        </RequestHandler>
      </Box>
    </Layout>
  );
};
