import { makeStyles } from '@material-ui/core';

export default makeStyles({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: '24px',
    backgroundColor: '#d5c4c4',
    justifyContent: 'space-between',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '28px',
    marginTop: '10px',
    marginBottom: '24px',
  },
});
