import { DropdownIcon } from 'icons';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import useStyles from './styles';
import { useComponentVisible } from 'hooks/useComponentVisible';
import { getCoords } from 'utils/getCoords';
import { useEffect, useRef, useState } from 'react';

export const Dropdown = ({ classOuter, children, outsideRef }) => {
  const classes = useStyles();
  const { ref, setIsComponentVisible, isComponentVisible } =
    useComponentVisible();
  const [styleInner, setStyleInner] = useState({});

  const wrapperRef = useRef();

  useEffect(() => {
    if (!outsideRef) return;
    const coordDropdown = getCoords(wrapperRef.current);
    const coordWrapper = getCoords(outsideRef.current);
    let top = undefined;
    if (coordWrapper.bottom - coordDropdown.bottom < 150) {
      top = 120 - (coordWrapper.bottom - coordDropdown.bottom);
      if (top < 0) {
        top = 30;
      }
    }
    setStyleInner({
      top: `-${top}px`,
    });
  }, [isComponentVisible]);

  return (
    <Box
      ref={wrapperRef}
      component='span'
      className={[classOuter, classes.wrapperOuter].join(' ')}
    >
      <Box
        className={classes.iconCenter}
        component='span'
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        <DropdownIcon width={24} height={24} />
      </Box>
      {isComponentVisible ? (
        <Box ref={ref} className={classes.content} style={styleInner}>
          {children}
        </Box>
      ) : null}
    </Box>
  );
};

Dropdown.propTypes = {
  classOuter: PropTypes.string,
  children: PropTypes.node,
  outsideRef: PropTypes.any,
};
