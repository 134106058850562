/* eslint-disable no-unused-vars */
import { MathpixMarkdown, MathpixLoader } from 'mathpix-markdown-it';
import { parser } from 'utils';

import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

export const Formula = ({ arrText }) => {
  return (
    <Box component='span' dangerouslySetInnerHTML={{ __html: arrText }}>
      {/* <MathpixLoader>
        <MathpixMarkdown
          text={String.raw`${arrText}`}
          style={{ whiteSpace: 'pre-wrap' }}
          htmlTags={true}
          xhtmlOut={true}
          display={'inline-block'}
        />
      </MathpixLoader> */}
    </Box>
  );
};

Formula.propTypes = {
  arrText: PropTypes.any,
};
