import PropTypes from 'prop-types';
import React from 'react';

export const TrashIcon = ({ width = 12, height = 15, onClick }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M2 4.16671H1.33333V12.8334C1.33333 13.187 1.47381 13.5261 1.72386 13.7762C1.97391 14.0262 2.31304 14.1667 2.66667 14.1667H9.33333C9.68696 14.1667 10.0261 14.0262 10.2761 13.7762C10.5262 13.5261 10.6667 13.187 10.6667 12.8334V4.16671H2ZM9.07867 2.16671L8 0.833374H4L2.92133 2.16671H0V3.50004H12V2.16671H9.07867Z'
        fill='#0B486B'
      />
    </svg>
  );
};

TrashIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
};
