import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Snackbar from '@material-ui/core/Snackbar';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  maxWidth: '80vw',
  maxHeight: '80vh',
  // overflowY: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const ModalContext = createContext();
export const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModalContext must be used within a ModalProvider');
  }
  return context;
};

export const ModalProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalContent, setModalContent] = useState('');
  const {
    vertical,
    horizontal,
    openSnack,
    message,
    handleClickSnack,
    handleCloseSnack,
  } = useSnack();

  return (
    <ModalContext.Provider
      value={{
        openModal: handleOpen,
        closeModal: handleClose,
        setModalContent: setModalContent,
        handleClickSnack: handleClickSnack,
      }}
    >
      {children}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>{modalContent}</Box>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSnack}
        onClose={handleCloseSnack}
        message={message}
        key={vertical + horizontal}
      />
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useSnack = () => {
  const [state, setState] = useState({
    openSnack: false,
    vertical: 'top',
    horizontal: 'center',
    message: '',
  });

  const { vertical, horizontal, openSnack, message } = state;

  const handleClickSnack = (message = '') => {
    setState({
      openSnack: true,
      vertical: 'bottom',
      horizontal: 'right',
      message: message,
    });
  };

  const handleCloseSnack = () => {
    setState({ ...state, openSnack: false, message: '' });
  };
  return {
    vertical,
    horizontal,
    openSnack,
    message,
    handleClickSnack,
    handleCloseSnack,
  };
};
