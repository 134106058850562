import PropTypes from 'prop-types';
import React from 'react';

export const SoundIcon = ({ width = 16, height = 16, className, onClick }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M10.667 14C13.0183 12.9687 14.6663 10.7273 14.6663 8C14.6663 5.27267 13.0183 3.03133 10.667 2V3.33333C12.2583 4.25733 13.333 6.03133 13.333 8C13.333 9.96867 12.2583 11.7427 10.667 12.6667V14Z'
        fill='white'
      />
      <path
        d='M10.6663 4.66672V11.3334C11.483 10.6001 11.9997 9.18072 11.9997 8.00005C11.9997 6.81939 11.483 5.40005 10.6663 4.66672ZM2.66634 11.3334H4.46434L9.33301 14.5787V1.42139L4.46434 4.66672H2.66634C1.93101 4.66672 1.33301 5.26472 1.33301 6.00005V10.0001C1.33301 10.7354 1.93101 11.3334 2.66634 11.3334Z'
        fill='white'
      />
    </svg>
  );
};

SoundIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
