import PropTypes from 'prop-types';
import React from 'react';

export const ArrowRightDarkIcon = ({
  width = 11,
  height = 16,
  className,
  onClick,
  style,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 11 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      style={style}
    >
      <path
        d='M3.06096 15.061L10.121 7.99996L3.06096 0.938965L0.938965 3.06096L5.87896 7.99996L0.938965 12.939L3.06096 15.061Z'
        fill='#0B486B'
      />
    </svg>
  );
};

ArrowRightDarkIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
};
