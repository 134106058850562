import { gql } from '@apollo/client';

export const GET_INIT_TEXT_BY_TEXT_BOOK_ID = gql`
  query getInitTextByTextbookId($textbookId: ID!) {
    getInitTextByTextbookId(textbookId: $textbookId) {
      id
      textbookId
      text
      completed
      reConverted
    }
  }
`;
