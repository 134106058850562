import { useEffect, useState } from 'react';

export const useClickFormula = (currentParagraph, refText) => {
  const [selectValue, setSelectValue] = useState();

  const event = (event) => {
    setSelectValue(event.target.outerHTML);
  };
  useEffect(() => {
    if (!refText?.current) return;
    refText.current.addEventListener('click', event);
    return () => {
      refText?.current?.removeEventListener('click', event);
    };
  }, [currentParagraph, refText?.current]);

  return { selectValue, setSelectValue };
};
