import { Box, Grid } from '@material-ui/core';
import { ArrowRightDarkIcon, TrashIcon } from 'icons';
import useStyles from '../styles';
import { ButtonSave } from '../button-save';
import PropTypes from 'prop-types';
import { useCreateBookContext } from 'context';
import { useDeleteInsert } from 'hooks';

export const InsertList = ({ page = 'insert' }) => {
  const classes = useStyles();
  const { insertList, setInsertList } = useCreateBookContext();
  const [mutation] = useDeleteInsert();

  return (
    <>
      <Box>
        <Box className={classes.wrapperHead}>
          {page === 'insert' ? 'Примеры' : 'Озвучка формул'}{' '}
        </Box>
        <Box className={classes.wrapperText}>
          {insertList?.map((insert, index) => {
            return (
              <Grid className={classes.wrapperInsert} key={insert.id} container>
                <Grid item md={1}>
                  {index + 1}.
                </Grid>
                <Grid item md={7}>
                  {insert.name}
                </Grid>
                <Grid item md={2}>
                  {insert.audioStart}
                </Grid>
                <Grid
                  item
                  md={1}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <TrashIcon
                    onClick={() => {
                      mutation({
                        variables: {
                          id: insert.id,
                        },
                      })
                        .then(() => {
                          setInsertList(
                            insertList.filter((item) => item.id !== insert.id),
                          );
                        })
                        .catch((error) => console.info(error));
                    }}
                  />
                </Grid>
                <Grid item md={1}>
                  <a href={`#${insert.id}`}>
                    <ArrowRightDarkIcon />
                  </a>
                </Grid>
              </Grid>
            );
          })}
        </Box>
      </Box>
      <ButtonSave />
    </>
  );
};

InsertList.propTypes = {
  page: PropTypes.string,
};
