import { useMutation } from '@apollo/client';
import { DELETE_CHAPTER } from 'graphql/mutations';
import { GET_TEXT_BOOK_BY_ID } from 'graphql/query';
import { useParams } from 'react-router';

export const useDeleteChapter = (chapterId) => {
  const { id } = useParams();

  const updateCache = (cache) => {
    const existingChapters = cache.readQuery({
      query: GET_TEXT_BOOK_BY_ID,
      variables: { textbookId: id },
    });
    const arr = existingChapters?.getTextbookById?.chapters?.filter(function (
      item,
    ) {
      return item.id !== chapterId;
    });

    cache.writeQuery({
      query: GET_TEXT_BOOK_BY_ID,
      variables: { textbookId: id },
      data: {
        getTextbookById: {
          chapters: arr,
        },
      },
    });
  };

  return useMutation(DELETE_CHAPTER, {
    update: updateCache,

    onCompleted: (response) => {
      return response;
    },
  });
};
