import { useMutation } from '@apollo/client';
import { ADD_INSERT } from 'graphql/mutations';
import { useCreateBookContext } from 'context';

export const useAddInsert = () => {
  const { setAddInsert } = useCreateBookContext();

  return useMutation(ADD_INSERT, {
    onCompleted: (response) => {
      setAddInsert(false);
      return response;
    },
  });
};
