import PropTypes from 'prop-types';
import React from 'react';

export const DropzoneIcon = ({
  width = 34,
  height = 27,
  className,
  color = '#0B486B',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 34 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M30.3317 0.166687H3.665C1.82667 0.166687 0.331665 1.66169 0.331665 3.50002V23.5C0.331665 25.3384 1.82667 26.8334 3.665 26.8334H30.3317C32.17 26.8334 33.665 25.3384 33.665 23.5V3.50002C33.665 1.66169 32.17 0.166687 30.3317 0.166687ZM7.83167 5.16669C8.49471 5.16669 9.13059 5.43008 9.59943 5.89892C10.0683 6.36776 10.3317 7.00364 10.3317 7.66669C10.3317 8.32973 10.0683 8.96561 9.59943 9.43445C9.13059 9.90329 8.49471 10.1667 7.83167 10.1667C7.16862 10.1667 6.53274 9.90329 6.0639 9.43445C5.59506 8.96561 5.33167 8.32973 5.33167 7.66669C5.33167 7.00364 5.59506 6.36776 6.0639 5.89892C6.53274 5.43008 7.16862 5.16669 7.83167 5.16669ZM16.9983 21.8334H5.33167L11.9983 13.5L14.4983 16.8334L19.4983 10.1667L28.665 21.8334H16.9983Z'
        fill={color}
      />
    </svg>
  );
};

DropzoneIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
};
