import {
  Typography,
  Box,
  TextField as MaterialTextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

export const TextField = ({
  onChange,
  value,
  filterKey,
  name,
  label,
  nativeLabel,
  icon,
  className,
  classNameOutline,
  typeFilter = true,
  multiline = false,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {!nativeLabel && label ? (
        <label htmlFor={name}>
          <Typography className={classes.label}>{label}</Typography>
        </label>
      ) : null}

      <MaterialTextField
        id={name}
        name={name}
        value={value}
        multiline={multiline}
        label={nativeLabel || ''}
        onChange={(e) => {
          if (typeFilter) {
            onChange(e.target.value, filterKey);
          } else {
            onChange(e.target.value);
          }
        }}
        fullWidth
        size='small'
        variant='outlined'
        InputProps={{
          className: className ? className : classes.input,
          classes: {
            notchedOutline: classNameOutline
              ? classNameOutline
              : classes.notchedOutline,
          },
          endAdornment: icon,
        }}
        {...props}
      />
    </Box>
  );
};

TextField.defaultProps = {
  required: false,
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  nativeLabel: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  filterKey: PropTypes.string,
  icon: PropTypes.node,
  className: PropTypes.string,
  classNameOutline: PropTypes.string,
  multiline: PropTypes.bool,
  typeFilter: PropTypes.bool,
};
