import { useEffect } from 'react';

import { useAsideContext } from 'context';
import { searchParagrapInTextbook } from 'hooks';
import { useParams } from 'react-router-dom';

export const useParagraphAsaide = (dataBook, setCurrentParagraph) => {
  const { setChapterList, linkParagraph, serchValue } = useAsideContext();

  const { id } = useParams();
  const { data: paragraph } = searchParagrapInTextbook(id, serchValue);

  useEffect(() => {
    // set Init text chapterList
    if (!dataBook) return;
    if (paragraph && serchValue) {
      return setChapterList(paragraph?.searchParagrapInTextbook);
    }
    setChapterList(dataBook?.getTextbookById.chapters);
  }, [dataBook, paragraph, setChapterList]);

  useEffect(() => {
    // watch asaide click link paragraph
    if (!linkParagraph) return;
    setCurrentParagraph(linkParagraph);
  }, [linkParagraph, setCurrentParagraph]);

  return {
    setChapterList,
    linkParagraph,
  };
};
