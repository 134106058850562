import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  wrapperOuter: {
    width: '100%',
    display: 'flex',
    padding: '0px 24px',
    flexDirection: 'column',
    whiteSpace: 'pre-wrap',
  },
  wrapperInner: {
    // marginTop: '40px',
  },
  wrapperConverting: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
  },
  convertingStatus: {
    marginTop: '10px',
    fontSize: '24px',
  },
  alert: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    width: '100%',
  },
});
