import { Box } from '@material-ui/core';
import useStyles from './styles';
import PropTypes from 'prop-types';
import { startParagraph, endParagraph } from 'constants/index';
import { Formula } from 'components';
import { useMemo } from 'react';

export const EditStructure = ({ textDefaultRequest = '' }) => {
  const classes = useStyles();

  const parseArr = chapterArray(textDefaultRequest);

  const FormulaMemo = useMemo(() => {
    return parseArr.map((e, index) => {
      if (e.type === 'chapter') {
        return (
          <Box
            key={index}
            style={{
              backgroundColor: '#C5D7E0',
              userSelect: 'none',
              display: 'inline-block',
            }}
            component='span'
            id={e.id}
          >
            <Formula arrText={e.text} />
          </Box>
        );
      }
      return (
        <Box id={e.id} component='span' key={index}>
          <Formula arrText={e.text} />
        </Box>
      );
    });
  }, [textDefaultRequest]);

  return (
    <Box className={classes.wrapperOuter}>
      <Box className={classes.wprapperInner}>
        <Box className={classes.wrapper}>{FormulaMemo}</Box>
      </Box>
    </Box>
  );
};

EditStructure.propTypes = {
  textSearch: PropTypes.string,
  textDefaultRequest: PropTypes.string,
};
const chapterArray = (text) => {
  const chapterList = [];
  let i = 0;
  let indexesStart = getAllIndexes(text, startParagraph);
  let indexesEnd = getAllIndexes(text, endParagraph);
  parseParagraph(text, chapterList, indexesStart, indexesEnd, i);
  return chapterList;
};

function getAllIndexes(arr, val) {
  var indexes = [],
    i = -1;
  while ((i = arr.indexOf(val, i + 1)) != -1) {
    indexes.push(i);
  }
  return indexes;
}

const parseParagraph = (text, chapterList, indexesStart, indexesEnd, i) => {
  let newArr = [];
  if (!indexesStart?.length) {
    chapterList.push({ type: 'any', text: text });
    return;
  }
  indexesStart.map((e, index) => {
    text.slice(e, indexesEnd[index]);
    let name = text.slice(e, indexesEnd[index] + 2);
    let type = '';
    if (name.indexOf('start') !== -1) {
      type = 'start';
    } else {
      type = 'end';
    }
    let id = name
      .replace(startParagraph, '')
      .replace(endParagraph, '')
      .replace('start', '')
      .replace('end', '')
      .replaceAll('\n', '')
      .replaceAll(' ', '');

    newArr.push({
      text: name,
      type: type,
      id: id,
      coordinate: { start: e, end: indexesEnd[index] + 2 },
    });
  });

  const before = text.slice(
    newArr[i - 1]?.coordinate?.end,
    newArr[i].coordinate.start,
  );
  chapterList.push({ type: 'any', text: before });
  const chanter = text.slice(
    newArr[i]?.coordinate.end,
    newArr[i + 1]?.coordinate.start,
  );
  chapterList.push({ type: 'chapter', text: chanter, id: newArr[i].id });
  if (!indexesEnd[i + 2]) {
    const after = text.slice(newArr[i + 1].coordinate.end, text.length);
    chapterList.push({ type: 'any', text: after });

    return;
  }
  parseParagraph(text, chapterList, indexesStart, indexesEnd, i + 2);
};
