import { useMemo, useEffect, useState } from 'react';

const createParagraphList = (chapters) => {
  let list = { paragraphList: [] };
  chapters?.map((e) => {
    e?.paragraphs?.map((e) => {
      list[e.id] = e;
      list.paragraphList.push(e.id);
    });
  });

  return list;
};

export const useParagraph = (book) => {
  const paragraphList = useMemo(() => {
    return createParagraphList(book?.chapters);
  }, [book]);

  const [currentParagraph, setCurrentParagraph] = useState(null);

  useEffect(() => {
    //init current paragraph
    if (currentParagraph) {
      return;
    }
    if (paragraphList.paragraphList[0]) {
      setCurrentParagraph(paragraphList.paragraphList[0]);
    }
  }, [paragraphList, currentParagraph]);

  return {
    paragraphList,
    currentParagraph,
    setCurrentParagraph,
  };
};
