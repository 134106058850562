import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { theme } from 'constants/theme';
import { Header, Aside } from 'components';
import { useAsideContext } from 'context';
import useStyles from './styles';

export const Layout = ({ children, hideAsaid = false }) => {
  const { menuOpen, handleOpen, setMenuOpen } = useAsideContext();

  const classes = useStyles({
    backgroundColor: theme.colors.background.secondary,
  });

  return (
    <Box className={classes.wrapper}>
      <Header />
      <Box className={classes.content} component='main'>
        {hideAsaid ? null : (
          <Aside
            menuOpen={menuOpen}
            handleOpen={handleOpen}
            setMenuOpen={setMenuOpen}
          />
        )}
        {children}
      </Box>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  hideAsaid: PropTypes.bool,
};
