import { gql } from '@apollo/client';

export const TEXT_BOOKS_LIST = gql`
  query getTextbooks($options: optionsGettingTextbooks) {
    getTextbooks(options: $options) {
      count
      textbooks {
        id
        title
        authors
        issueYear
        subject {
          id
          name
        }
        creatorId
        subjectId
        status
      }
    }
  }
`;
