import PropTypes from 'prop-types';
import React from 'react';

export const FileIcon = ({ width = 28, height = 37, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 28 37'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M26.9772 8.944C27.2045 9.17838 27.3333 9.49478 27.3333 9.82681V35.5833C27.3333 36.2747 26.7916 36.8333 26.1212 36.8333H1.87875C1.20829 36.8333 0.666626 36.2747 0.666626 35.5833V1.41666C0.666626 0.72525 1.20829 0.166656 1.87875 0.166656H17.9659C18.2878 0.166656 18.5984 0.299469 18.8257 0.533844L26.9772 8.944ZM24.5378 10.401L17.409 3.04947V10.401H24.5378ZM6.72723 16.4948C6.64686 16.4948 6.56979 16.5277 6.51296 16.5863C6.45613 16.6449 6.4242 16.7244 6.4242 16.8073V18.6823C6.4242 18.7652 6.45613 18.8446 6.51296 18.9033C6.56979 18.9619 6.64686 18.9948 6.72723 18.9948H21.2727C21.3531 18.9948 21.4301 18.9619 21.487 18.9033C21.5438 18.8446 21.5757 18.7652 21.5757 18.6823V16.8073C21.5757 16.7244 21.5438 16.6449 21.487 16.5863C21.4301 16.5277 21.3531 16.4948 21.2727 16.4948H6.72723ZM6.72723 21.8073C6.64686 21.8073 6.56979 21.8402 6.51296 21.8988C6.45613 21.9574 6.4242 22.0369 6.4242 22.1198V23.9948C6.4242 24.0777 6.45613 24.1571 6.51296 24.2157C6.56979 24.2744 6.64686 24.3073 6.72723 24.3073H13.6969C13.7773 24.3073 13.8544 24.2744 13.9112 24.2157C13.968 24.1571 14 24.0777 14 23.9948V22.1198C14 22.0369 13.968 21.9574 13.9112 21.8988C13.8544 21.8402 13.7773 21.8073 13.6969 21.8073H6.72723Z'
        fill='#0B486B'
      />
    </svg>
  );
};

FileIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
};
