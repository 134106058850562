import { Box } from '@material-ui/core';
import React, { useRef } from 'react';
import logoImg from 'assets/img/logo.png';
import { HeaderAvatar, HeaderMenu } from 'components';
import { useAuthContext } from 'context';
import useStyles from './styles';
import { useHistory } from 'react-router';
import { ROUTES } from 'constants/index';

export const Header = () => {
  const classes = useStyles();
  const headerRef = useRef();
  const { isAuth } = useAuthContext();
  const history = useHistory();

  return (
    <Box ref={headerRef} className={classes.header} component='header'>
      <Box className={['container', classes.container].join(' ')}>
        <Box className={classes.wrapper}>
          <Box
            className={classes.logo}
            onClick={() => history.push(ROUTES.home)}
          >
            <img src={logoImg} className={classes.logoImg} alt='Parta' />
            <Box className={classes.logoText} component='span'>
              Parta
            </Box>
          </Box>
          <Box className={classes.logo}>
            {isAuth ? (
              <>
                <HeaderMenu />
                <HeaderAvatar />
              </>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
