import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell as MuiTableCell,
  TableBody,
  withStyles,
  Box,
} from '@material-ui/core';
import { PaginationCustom } from './pagination';
import { Dropdown } from 'components';
import useStyles from './styles';
import { EditBooksDropdown } from './edit-books-dropdown';
import { theme, ROUTES } from 'constants/index';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';

export const HomeTable = ({ data, setOffset, offset, refetchBook }) => {
  const classes = useStyles();

  const ref = useRef();
  const history = useHistory();
  const statusTable = history.location.pathname !== ROUTES.home;

  return (
    <>
      <TableContainer
        component={Paper}
        style={{
          backgroundColor: theme.colors.background.primary,
        }}
      >
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCellHead>Название</TableCellHead>
              <TableCellHead align='left'>Предмет</TableCellHead>
              <TableCellHead align='left'>Автор</TableCellHead>
              {statusTable ? (
                <TableCellHead align='left'>Статус</TableCellHead>
              ) : null}
              <TableCellHead align='right'>
                <Box className={classes.year} component='span'>
                  Год выпуска
                </Box>
              </TableCellHead>
            </TableRow>
            <TableRow>
              <Box
                style={{
                  // fix min height in table
                  opacity: 0,
                  backgroundColor: theme.colors.background.primary,
                  minHeight:
                    data?.getTextbooks?.textbooks.length < 2 ? '40px' : '0px',
                }}
              >
                none
              </Box>
            </TableRow>
          </TableHead>
          <TableBody id='homeTableID' ref={ref}>
            {data?.getTextbooks?.textbooks?.map((row, id) => (
              <TableRow key={row.title + id}>
                <TableCell>
                  <a
                    // eslint-disable-next-line no-undef
                    href={`${process.env.REACT_APP_API_HOST_READER}/book/${row.id}`}
                    target={'_blank'}
                    rel='noreferrer'
                  >
                    {row.title}
                  </a>
                </TableCell>
                <TableCell align='left'>{row.subject.name}</TableCell>
                <TableCell align='left'>{row.authors}</TableCell>
                {statusTable ? (
                  <TableCell align='left'>{row.status}</TableCell>
                ) : null}

                <TableCell align='right'>
                  <Box className={classes.dropdownWrappr} component='span'>
                    {row.issueYear}
                    {!statusTable ? (
                      <Dropdown
                        classOuter={classes.dropdownWrapprInner}
                        outsideRef={ref}
                      >
                        <EditBooksDropdown
                          id={row.id}
                          refetchBook={refetchBook}
                        />
                      </Dropdown>
                    ) : null}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationCustom
        itemsPerPage={data?.getTextbooks?.count}
        setOffset={setOffset}
        offset={offset}
      />
    </>
  );
};

HomeTable.propTypes = {
  data: PropTypes.object,
  setOffset: PropTypes.func,
  offset: PropTypes.number,
  refetchBook: PropTypes.func,
};

const TableCell = withStyles({
  root: {
    borderBottom: '1px solid #C5D7E0;',
    color: '#303030',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '146%',
    letterSpacing: '0.03em',
    fontFamily: theme.fonts.primary,
    backgroundColor: theme.colors.background.primary,
  },
})(MuiTableCell);

const TableCellHead = withStyles({
  root: {
    borderBottom: 'none',
    color: '#5C5C5C',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    fontFamily: theme.fonts.primary,
    backgroundColor: theme.colors.background.primary,
  },
})(MuiTableCell);
