import React from 'react';
import PropTypes from 'prop-types';

export const SaveDarkIcon = ({
  width = 24,
  height = 24,
  className,
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M3.6001 5.99998C3.6001 5.36346 3.85295 4.75301 4.30304 4.30292C4.75313 3.85283 5.36358 3.59998 6.0001 3.59998H7.2001V7.79998C7.2001 8.27737 7.38974 8.7352 7.72731 9.07277C8.06487 9.41033 8.52271 9.59998 9.0001 9.59998H13.8001C14.2775 9.59998 14.7353 9.41033 15.0729 9.07277C15.4105 8.7352 15.6001 8.27737 15.6001 7.79998V3.59998H16.0549C16.6914 3.60011 17.3017 3.85306 17.7517 4.30318L19.6969 6.24838C20.147 6.69836 20.4 7.30871 20.4001 7.94518V18C20.4001 18.6365 20.1472 19.2469 19.6972 19.697C19.2471 20.1471 18.6366 20.4 18.0001 20.4V13.8C18.0001 13.3226 17.8105 12.8647 17.4729 12.5272C17.1353 12.1896 16.6775 12 16.2001 12H7.8001C7.32271 12 6.86487 12.1896 6.52731 12.5272C6.18974 12.8647 6.0001 13.3226 6.0001 13.8V20.4C5.36358 20.4 4.75313 20.1471 4.30304 19.697C3.85295 19.2469 3.6001 18.6365 3.6001 18V5.99998Z'
        fill='#0B486B'
      />
      <path
        d='M14.4001 3.59998H8.40015V7.79997C8.40015 7.95911 8.46336 8.11172 8.57588 8.22424C8.6884 8.33676 8.84102 8.39997 9.00015 8.39997H13.8001C13.9593 8.39997 14.1119 8.33676 14.2244 8.22424C14.3369 8.11172 14.4001 7.95911 14.4001 7.79997V3.59998Z'
        fill='#0B486B'
      />
      <path
        d='M16.8002 13.8V20.4H7.2002V13.8C7.2002 13.6408 7.26341 13.4882 7.37593 13.3757C7.48845 13.2632 7.64107 13.2 7.8002 13.2H16.2002C16.3593 13.2 16.5119 13.2632 16.6245 13.3757C16.737 13.4882 16.8002 13.6408 16.8002 13.8Z'
        fill='#0B486B'
      />
    </svg>
  );
};

SaveDarkIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
