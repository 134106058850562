import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import { ArrowRightDarkIcon, ArrowLeftDarkIcon } from 'icons';
import { SoundPlayer } from './sound-palyer/sound-player';
import PropTypes from 'prop-types';
import { nextParagraph, createInsertList } from 'utils';
import { useCreateBookContext } from 'context';
import { useState } from 'react';

export const SoundChapter = ({
  paragraph,
  paragraphList,
  currentParagraph,
  setCurrentParagraph,
  refetchBook,
  refText,
  textSelectFormula,
}) => {
  const classes = useStyles();

  const { insertList } = useCreateBookContext();

  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <Box className={classes.wrapperOuter}>
      <Box className={classes.navigationWrapper}>
        <ArrowLeftDarkIcon
          onClick={() => {
            setCurrentParagraph(
              nextParagraph(paragraphList, currentParagraph, 'back'),
            );
          }}
          className={classes.iconWrapper}
        />
        <Box className={classes.navigationText}>{paragraph?.name}</Box>
        <ArrowRightDarkIcon
          onClick={() => {
            setCurrentParagraph(
              nextParagraph(paragraphList, currentParagraph, 'next'),
            );
          }}
          className={classes.iconWrapper}
        />
      </Box>
      <Box className={classes.line}></Box>
      <Box
        className={[
          classes.textWrapper,
          isPlaying ? 'offPointerEventChild' : '',
        ].join(' ')}
      >
        <Box
          ref={refText}
          dangerouslySetInnerHTML={{
            __html: createInsertList(textSelectFormula, insertList, 'sound'),
          }}
        />
      </Box>
      <SoundPlayer
        audioFile={paragraph?.audio}
        refetchBook={refetchBook}
        currentParagraph={currentParagraph}
        setCurrentParagraph={setCurrentParagraph}
        paragraphList={paragraphList}
        refText={refText}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
      />
    </Box>
  );
};

SoundChapter.propTypes = {
  paragraph: PropTypes.object,
  paragraphList: PropTypes.object,
  currentParagraph: PropTypes.string,
  setCurrentParagraph: PropTypes.func,
  refetchBook: PropTypes.func,
  refText: PropTypes.any,
  textSelectFormula: PropTypes.string,
};
