import { Box } from '@mui/material';
import useStyles from './style';
import { addInsertSound } from 'validation';
import { useCreateBookContext } from 'context';
import { Form, Formik } from 'formik';
import { useAddInsert, useSetAudioStart } from 'hooks';
import PropTypes from 'prop-types';
import {
  Dropzone,
  TextFieldFormik,
  ButtonCustom,
  InsertList,
  Preloader,
} from 'components';

export const SoundList = ({ refetchInsert, setSelectValue }) => {
  const classes = useStyles();

  const { createInsert, setAddInsert, addInsetr } = useCreateBookContext();
  const [mutation, { loading }] = useAddInsert();
  const { audioDurationElement, audioEnd, audioStart } = useSetAudioStart();

  if (!addInsetr) {
    return (
      <Box className={classes.wrapperOuter}>
        <InsertList page='sound' />
        {audioDurationElement}
      </Box>
    );
  }

  if (loading && addInsetr) {
    return (
      <Box className={classes.preloadeWrapper}>
        <Preloader />
      </Box>
    );
  }

  return (
    <Box className={classes.wrapperOuter}>
      <Formik
        enableReinitialize={true}
        validationSchema={addInsertSound}
        initialValues={{
          fileBook: '',
          name: createInsert?.name ? createInsert?.name : '',
          url: createInsert?.source ? createInsert?.source : '',
          description: '',
          audioDuration: audioEnd,
          audioStart: audioStart,
        }}
        onSubmit={(data) => {
          mutation({
            variables: {
              data: {
                paragraphId: parseInt(createInsert.paragraphId),
                audioStart: parseFloat(data.audioStart.toFixed(2)),
                audioEnd: parseFloat((0).toFixed(2)),
                contentPositionStart: parseInt(
                  createInsert.contentPositionStart,
                ),
                contentPositionEnd: parseInt(createInsert.contentPositionEnd),
                name: data.name,
                content: data.description,
                file: data?.fileBook,
                type: 'AUDIO',
              },
            },
          })
            .then(() => {
              setSelectValue(null);
              refetchInsert();
            })
            .catch((error) => {
              console.info(error);
            });
        }}
      >
        <Form>
          {/* <Box className={classes.textTitle}>...или создайте собственный</Box> */}
          <TextFieldFormik
            name='name'
            placeholder='Заголовок озвучки (необязательно)'
            type='text'
          />

          <Dropzone type='audioInsert' name='fileBook' />
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '10px 0px',
            }}
          >
            <TextFieldFormik
              name='audioStart'
              placeholder='начало на аудиодорожке (сек)'
              rootClass={classes.inputSong}
              type='number'
            />
          </Box>
          <Box style={{ height: '200px' }}></Box>
          <Box className={classes.buttonWrapper}>
            <ButtonCustom
              onClick={() => {
                setAddInsert(false);
              }}
            >
              к списку
            </ButtonCustom>
            <ButtonCustom type='submit'>Добавить</ButtonCustom>
          </Box>
        </Form>
      </Formik>
    </Box>
  );
};

SoundList.propTypes = {
  setSearchStatus: PropTypes.func,
  refetchInsert: PropTypes.func,
  setSelectValue: PropTypes.func,
};
