/* eslint-disable jsx-a11y/media-has-caption */
import { useCreateBookContext } from 'context';
import { useEffect, useState } from 'react';

export const useSetAudioStart = () => {
  const { createInsert, handleCreateInsert } = useCreateBookContext();
  const [audioStart, setAudioStart] = useState('');
  const handleSetAudioStart = (value) => {
    if (!value) return;
    setAudioStart(parseFloat(value));
  };
  const [audioEnd, setAudioEnd] = useState('');
  const handleSetAudioEnd = (value) => {
    if (!value) return;
    setAudioEnd(parseFloat(value));
  };

  const mathAudioPosition = () => {
    const textLength = createInsert.textLength;
    const startIndex = createInsert.cleanContentPositionStart;
    const endIndex = createInsert.cleanContentPositionEnd;
    const audioDuration = createInsert.audioDuration;

    const textOneProcent = textLength / 100;
    const startProcentText = startIndex / textOneProcent;
    const endProcentText = endIndex / textOneProcent;

    const audioOneProcent = audioDuration / 100;

    const startAudioValueSec = audioOneProcent * startProcentText;
    handleSetAudioStart(parseFloat(startAudioValueSec.toFixed(2)));

    const endAudioValueSec = audioOneProcent * endProcentText;
    handleSetAudioEnd(parseFloat(endAudioValueSec.toFixed(2)));
  };

  useEffect(mathAudioPosition, [createInsert]);
  useEffect(mathAudioPosition, []);

  useEffect(() => {
    let au = document.getElementById('insertAudioDuration');
    if (!au) return;
    au.onloadedmetadata = function () {
      handleCreateInsert('audioDuration', au.duration);
    };
  }, [createInsert?.textLength]);

  const audioDurationElement = (
    <audio
      // eslint-disable-next-line no-undef
      src={`${process.env.REACT_APP_API_HOST}/${createInsert?.audio}`}
      preload='metadata'
      id='insertAudioDuration'
    ></audio>
  );

  return { audioDurationElement, audioStart, audioEnd };
};
