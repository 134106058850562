import { makeStyles } from '@material-ui/core/styles';
import { theme } from 'constants/index';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '16px 0',
    borderRadius: '8px',
  },
  input: {
    background: theme.colors.white,
    borderRadius: '8px',
    boxSizing: 'border-box',
    height: '32px',
  },
  inputMultiline: {
    background: theme.colors.white,
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '10px',
  },
  label: {
    width: '200px',
  },
  required: {
    color: 'red',
  },
  notchedOutline: {
    border: `1px solid ${theme.colors.lightBlue}`,
  },
  notchedOutlineMultiline: {
    border: '1px solid white',
    padding: '10px',
  },
});
