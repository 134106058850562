import { gql } from '@apollo/client';

export const GET_SERCH_IN_BASE_INSERTS = gql`
  query getSerchInBaseInserts($search: String!) {
    serchInBaseInserts(search: $search) {
      id
      name
      content
      source
      type
    }
  }
`;
