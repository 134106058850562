import { theme } from 'constants/index';
import { makeStyles } from '@material-ui/core';

export default makeStyles({
  wrapperOuter: {
    marginLeft: '20px',
  },
  wprapperInner: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.colors.white,
    padding: '20px',
  },
  wrapper: {
    height: '460px',
    overflowY: 'auto',
    scrollbarColor: '#9FB6C1 #EAE8E8',
    scrollbarWidth: 'thin !important',
    '&::-webkit-scrollbar': {
      width: '4px',
      backgroundColor: '#EAE8E8',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#9FB6C1',
      borderRadius: 2,
    },
    '&::selection': {
      color: theme.colors.black,
      background: theme.colors.lightBlue,
    },
  },
  text: {
    color: theme.colors.black,
    lineHeight: '146%',
    letterSpacing: '0.03em',
    overflowY: 'auto',
    scrollbarColor: '#9FB6C1 #EAE8E8',
    scrollbarWidth: 'thin !important',
    '&::-webkit-scrollbar': {
      width: '4px',
      backgroundColor: '#EAE8E8',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#9FB6C1',
      borderRadius: 2,
    },
    '&::selection': {
      color: theme.colors.black,
      background: theme.colors.lightBlue,
    },
  },
  selectText: {
    color: theme.colors.black,
    background: theme.colors.lightBlue,
    position: 'relative',
  },
  noSelect: {
    userSelect: 'none',
  },
});
