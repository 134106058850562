import PropTypes from 'prop-types';
import useStyles from './styles';
import { Box } from '@material-ui/core';
import { EditIcon, PublicIcon, TrashIcon } from 'icons';
import { useHistory } from 'react-router';
import { ROUTES } from 'constants/index';
import { usePublishTextbook, useDeleteTextbook } from 'hooks';
import { RequestHandler } from 'components';

export const EditBooksDropdown = ({ id, refetchBook }) => {
  const classes = useStyles();
  const history = useHistory();

  const [mutationPublish, { loading: loadingPublish, error: errorPublich }] =
    usePublishTextbook();

  const [mutationDelete, { loading: loadingDelete, error: errorDelete }] =
    useDeleteTextbook();

  return (
    <Box className={classes.dropdownContent}>
      <Box
        className={classes.dropdownContentRow}
        onClick={() => {
          history.push(ROUTES.createBookStepOne + id);
        }}
      >
        <EditIcon width={15} height={15} />
        <Box component='span' className={classes.dropdownContentText}>
          редактировать
        </Box>
      </Box>
      <Box
        onClick={() => {
          mutationPublish({
            variables: {
              id: parseInt(id),
            },
          });
        }}
        className={classes.dropdownContentRow}
      >
        <PublicIcon width={15} height={15} />
        <RequestHandler loading={loadingPublish} error={errorPublich}>
          <Box component='span' className={classes.dropdownContentText}>
            опубликовать
          </Box>
        </RequestHandler>
      </Box>
      <Box
        onClick={() => {
          mutationDelete({
            variables: {
              id: parseInt(id),
            },
          })
            .then(() => refetchBook())
            .catch((error) => {
              console.info(error);
            });
        }}
        className={classes.dropdownContentRow}
      >
        <TrashIcon width={15} height={15} />
        <RequestHandler loading={loadingDelete} error={errorDelete}>
          <Box component='span' className={classes.dropdownContentText}>
            удалить
          </Box>
        </RequestHandler>
      </Box>
    </Box>
  );
};

EditBooksDropdown.propTypes = {
  id: PropTypes.string,
  refetchBook: PropTypes.func,
};
