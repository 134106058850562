import React from 'react';
import PropTypes from 'prop-types';

export const LogOffIcon = ({ width = 14, height = 12, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M10.3333 2.66667L9.39331 3.60667L11.1133 5.33333H4.33331V6.66667H11.1133L9.39331 8.38667L10.3333 9.33333L13.6666 6L10.3333 2.66667ZM1.66665 1.33333H6.99998V0H1.66665C0.933313 0 0.333313 0.6 0.333313 1.33333V10.6667C0.333313 11.4 0.933313 12 1.66665 12H6.99998V10.6667H1.66665V1.33333Z'
        fill='#0B486B'
      />
    </svg>
  );
};

LogOffIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};
