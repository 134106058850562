import { gql } from '@apollo/client';
import { SUBJECT, CHAPTER } from '../fragment';

export const GET_TEXT_BOOK_BY_ID = gql`
  ${SUBJECT}
  ${CHAPTER}
  query getTextbookById($textbookId: ID!) {
    getTextbookById(textbookId: $textbookId) {
      id
      title
      authors
      issueYear
      subject {
        ...FragmentSubject
      }
      chapters {
        ...FragmentChapter
      }
      creatorId
      subjectId
      cover
      description
      audioDuration
    }
  }
`;
