import {
  Layout,
  ShowStepCreate,
  NextButton,
  TableOfContents,
  EditStructure,
  RequestHandler,
  TextField,
} from 'components';
import { useStyles } from './styles';
import { Box, Grid } from '@material-ui/core';
import { ROUTES } from 'constants/index';
import { useSelectText, useControlInitText } from 'hooks';
import {
  useGetInitTextByTextbookId,
  useGetTextbookById,
} from 'hooks/use-queries';
import { useGetSelectingList } from 'hooks/use-subscription';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCreateBookContext } from 'context';

export const CreateBookStepTwo = () => {
  const classes = useStyles();
  const [completed, setCompleted] = useState(true);
  const [conversionComplete, setConversionComplete] = useState(false);

  const { id } = useParams();

  const {
    data: dataInit,
    loading: loadingInit,
    error: errorInit,
    refetch,
  } = useGetInitTextByTextbookId({
    textbookId: id,
    skip: !completed,
  });
  const {
    data: dataBook,
    loading: loadingBook,
    error: errorBook,
  } = useGetTextbookById(id);

  const { data: dataSubs } = useGetSelectingList(id, completed);

  const { searchText, refWraper, setRange, range } = useSelectText(
    loadingInit || loadingBook,
    dataInit?.getInitTextByTextbookId?.text,
  );

  const { setSearchText, setTextbookId, setFullText } = useCreateBookContext();

  const { text } = useControlInitText(dataInit?.getInitTextByTextbookId?.text);

  useEffect(() => {
    // set for update text delete paragraph
    setFullText(text);
  }, [text]);

  useEffect(() => {
    // set search data in context
    setSearchText(searchText);
  }, [searchText, setSearchText]);

  useEffect(() => {
    // check conversion status
    if (!dataInit) return;
    if (dataInit?.getInitTextByTextbookId.completed) {
      setCompleted(true);
      setTextbookId(dataInit?.getInitTextByTextbookId.id);
      return;
    } else {
      if (dataSubs || conversionComplete) {
        refetch();
        return;
      }
      setCompleted(false);
    }
  }, [dataInit]);

  useEffect(() => {
    // end convertion
    if (dataSubs?.convertingInitTextStatus?.finished) {
      setCompleted(true);
      setConversionComplete(true);
    }
  }, [dataSubs]);

  return (
    <Layout hideAsaid={true}>
      <RequestHandler
        loading={loadingInit || loadingBook}
        error={errorInit || errorBook}
      >
        <Box className={classes.wrapperOuter}>
          <ShowStepCreate bookName={dataBook?.getTextbookById?.title} />
          <Box className={classes.wrapperInner} sx={{ flexGrow: 1 }}>
            {!completed ? (
              <Box className={classes.wrapperConverting}>
                <Box>{dataSubs?.convertingInitTextStatus?.msg}</Box>
                <Box className={classes.convertingStatus}>
                  {dataSubs?.convertingInitTextStatus?.percent_done}
                  {'% conversion'}
                </Box>
              </Box>
            ) : (
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    style={{
                      backgroundColor: 'white',
                      width: '100%',
                      height: '100px',
                      marginBottom: '5px',
                      padding: '5px',
                      overflow: 'auto',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: searchText?.selectSubstring,
                    }}
                  ></Box>
                  <TextField
                    value={range}
                    onChange={setRange}
                    typeFilter={false}
                    style={{ display: 'none' }}
                    name='range'
                    placeholder='точность поиска (количество совпадающих символов)'
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <Box>
                    <TableOfContents
                      chapters={dataBook?.getTextbookById?.chapters}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={8}>
                  <Box ref={refWraper}>
                    <EditStructure textDefaultRequest={text} />
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
          <NextButton
            back={{
              path: ROUTES.createBookStepOne,
              text: 'Основная информация',
            }}
            next={{
              path: ROUTES.createBookStepTree,
              text: 'Редактирование текста',
            }}
          />
        </Box>
      </RequestHandler>
    </Layout>
  );
};
