import {
  Typography,
  Box,
  TextField as MaterialTextField,
} from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { useStyles } from './styles';

export const TextFieldFormik = ({
  name,
  label,
  nativeLabel,
  icon,
  type = 'text',
  rootClass,
  multiline,
  className,
  rows,
  ...props
}) => {
  const classes = useStyles();
  const [field, meta] = useField(name);
  const { handleChange } = useFormikContext();

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassWord = () => {
    if (type !== 'password') return;
    setShowPassword(!showPassword);
  };

  const hasError = Boolean(meta.error && meta.touched);

  if (multiline)
    return (
      <Box className={[classes.root, rootClass].join(' ')}>
        {!nativeLabel && label ? (
          <label htmlFor={name}>
            <Typography className={classes.label}>{label}</Typography>
          </label>
        ) : null}

        <MaterialTextField
          id={name}
          name={name}
          value={field.value}
          label={nativeLabel || ''}
          onChange={handleChange(name)}
          fullWidth
          size='small'
          type={showPassword ? 'text' : type}
          variant='standard'
          multiline={multiline}
          rows={rows}
          InputProps={{
            classes: {
              input: className ? className : classes.inputMultiline,
            },
            disableUnderline: true,
          }}
          error={hasError}
          helperText={hasError ? meta.error : ''}
          {...props}
        />
      </Box>
    );

  return (
    <Box className={[classes.root, rootClass].join(' ')}>
      {!nativeLabel && label ? (
        <label htmlFor={name}>
          <Typography className={classes.label}>{label}</Typography>
        </label>
      ) : null}

      <MaterialTextField
        id={name}
        name={name}
        value={field.value}
        label={nativeLabel || ''}
        onChange={handleChange(name)}
        fullWidth
        size='small'
        type={showPassword ? 'text' : type}
        variant='outlined'
        InputProps={{
          classes: {
            notchedOutline: classes.notchedOutline,
            input: classes.input,
          },
          endAdornment:
            type === 'password' ? (
              <Box
                component='span'
                onClick={handleShowPassWord}
                style={{ cursor: 'pointer', marginTop: '3px' }}
              >
                {icon}
              </Box>
            ) : (
              icon
            ),
        }}
        error={hasError}
        helperText={hasError ? meta.error : ''}
        {...props}
      />
    </Box>
  );
};

TextFieldFormik.defaultProps = {
  required: false,
};

TextFieldFormik.propTypes = {
  name: PropTypes.string.isRequired,
  nativeLabel: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.node,
  rootClass: PropTypes.string,
  type: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  className: PropTypes.string,
};
