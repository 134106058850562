import { makeStyles } from '@material-ui/core/styles';
import { theme } from 'constants/index';

export const useStyles = makeStyles({
  wrapperOuter: {
    width: '430px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '32px 24px 24px 24px',
    background: theme.colors.white,
    border: '1px solid rgba(0, 0, 0, 0.1)',
    marginTop: '-64px',
  },
  line: {
    display: 'flex',
  },
  inputTop: {
    margin: '10px 0px !important',
    color: 'red',
  },
  input: {
    marginBottom: '25px !important',
  },
  form: {
    width: '100%',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '50px',
  },
  title: {
    width: '100%',
    textAlign: 'left',
    marginBottom: '40px',
    color: theme.colors.gray,
    fontSize: '24px',
    lineHeight: '28px',
  },
});
