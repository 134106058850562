import { gql } from '@apollo/client';
import { SUBJECT } from '../fragment';

export const SELECTINGS_LIST = gql`
  ${SUBJECT}
  query selectingsList {
    selectingsList {
      subjects {
        ...FragmentSubject
      }
      years
    }
  }
`;
