import { useEffect, useState } from 'react';

export const useControlInitText = (textInit) => {
  const [text, setText] = useState('');

  useEffect(() => {
    if (!textInit) return;
    setText(textInit);
  }, [textInit]);

  return { text, setText };
};
