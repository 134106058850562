import { makeStyles } from '@material-ui/core';

export default makeStyles({
  wrapperOuter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
