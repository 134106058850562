import Cookies from 'js-cookie';

// eslint-disable-next-line no-undef
const domain = process.env.REACT_APP_API_HOST.replace('https://', '');

export const getToken = () => {
  const token = localStorage.getItem('token');
  if (token) return token;
  const tokenValues = Cookies.get('token', { domain: domain });
  if (tokenValues) {
    localStorage.setItem('token', tokenValues);
    return tokenValues;
  } else return null;
};

export const setToken = (token) => {
  localStorage.setItem('token', token);
  Cookies.set('token', `${token}`, { domain: domain });
};

export const removeToken = () => {
  localStorage.removeItem('token');
  Cookies.remove('token', { domain: domain });
};
