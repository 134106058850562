import useStyles from './styles';
import Pagination from '@mui/material/Pagination';
import { Box } from '@material-ui/core';
import { LIMIT } from 'constants/index';
import PropTypes from 'prop-types';

export const PaginationCustom = ({ itemsPerPage, setOffset, offset }) => {
  const classes = useStyles();
  const handleChange = (event, value) => {
    setOffset((value - 1) * LIMIT);
  };

  const findPage = (offset = 0, LIMIT) => {
    let page = Math.ceil(offset / LIMIT);
    if (page === 1 && offset === 1) return page;
    return page + 1;
  };

  return (
    <Box className={classes.paginationWrapper}>
      <Pagination
        count={Math.ceil(itemsPerPage / LIMIT)}
        page={findPage(offset, LIMIT)}
        onChange={handleChange}
      />
    </Box>
  );
};

PaginationCustom.propTypes = {
  itemsPerPage: PropTypes.number,
  setOffset: PropTypes.func,
  offset: PropTypes.number,
};
