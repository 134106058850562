import { GET_SERCH_IN_BASE_INSERTS } from 'graphql/query/index';
import { useQuery } from '@apollo/client';

export const useGetSerchInBaseInserts = (search) => {
  return useQuery(GET_SERCH_IN_BASE_INSERTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      search: search,
    },
    skip: search ? false : true,
  });
};
