/* eslint-disable no-unused-vars */
import * as yup from 'yup';
import { mixed } from 'yup/lib/locale';

import { messages as M } from './messages';

export const addValidationSchema = yup.object().shape({
  name: yup.string(M.PROMPT_MAIL).required(M.REQUIRE_MESSAGE),
});

export const loginValidationSchema = yup.object().shape({
  email: yup
    .string(M.PROMPT_MAIL)
    .email(M.PROMPT_MAIL)
    .required(M.REQUIRE_MESSAGE),
  password: yup.string(M.PROMPT_PASSWORD).required(M.REQUIRE_MESSAGE),
});

export const bookStep0ne = yup.object().shape({
  bookName: yup.string(M.INCORRECT_SYMBOL).required(M.REQUIRE_MESSAGE),
  authors: yup.string(M.INCORRECT_SYMBOL).required(M.REQUIRE_MESSAGE),
  year: yup.string(M.INCORRECT_SYMBOL).required(M.REQUIRE_MESSAGE),

  subject: yup.object().shape({
    value: yup.string(M.INCORRECT_SYMBOL).required(M.REQUIRE_MESSAGE),
    label: yup.string(M.INCORRECT_SYMBOL).required(M.REQUIRE_MESSAGE),
  }),

  description: yup.string(M.INCORRECT_SYMBOL).required(M.REQUIRE_MESSAGE),

  titleImage: yup.mixed().required(M.REQUIRE_MESSAGE),
  fileBook: yup.mixed().required(M.REQUIRE_MESSAGE),
});

export const editbookStep0ne = yup.object().shape({
  bookName: yup.string(M.INCORRECT_SYMBOL).required(M.REQUIRE_MESSAGE),
  authors: yup.string(M.INCORRECT_SYMBOL).required(M.REQUIRE_MESSAGE),
  year: yup.string(M.INCORRECT_SYMBOL).required(M.REQUIRE_MESSAGE),

  subject: yup.object().shape({
    value: yup.string(M.INCORRECT_SYMBOL).required(M.REQUIRE_MESSAGE),
    label: yup.string(M.INCORRECT_SYMBOL).required(M.REQUIRE_MESSAGE),
  }),

  description: yup.string(M.INCORRECT_SYMBOL).required(M.REQUIRE_MESSAGE),

  titleImage: yup.mixed(),
});

export const addInsert = yup.object().shape({
  fileBook: yup.mixed(M.INCORRECT_SYMBOL),
  name: yup.string(M.INCORRECT_SYMBOL).required(M.REQUIRE_MESSAGE),
  description: yup.string(M.INCORRECT_SYMBOL),
  url: yup.string(M.INCORRECT_SYMBOL),
  audioStart: yup.number(M.INCORRECT_SYMBOL).required(M.REQUIRE_MESSAGE),
  audioDuration: yup.number(M.INCORRECT_SYMBOL).required(M.REQUIRE_MESSAGE),
});

export const addInsertSound = yup.object().shape({
  fileBook: yup.mixed(M.INCORRECT_SYMBOL),
  name: yup.string(M.INCORRECT_SYMBOL).required(M.REQUIRE_MESSAGE),
  url: yup.string(M.INCORRECT_SYMBOL),
  audioStart: yup.number(M.INCORRECT_SYMBOL).required(M.REQUIRE_MESSAGE),
});
