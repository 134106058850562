import { useMutation } from '@apollo/client';
import { CREATE_TEXTBOOK } from 'graphql/mutations';

export const useCreateTextbook = (setNextId) => {
  return useMutation(CREATE_TEXTBOOK, {
    onCompleted: (response) => {
      setNextId(response?.createTextbook?.id);
    },
  });
};
