/* eslint-disable jsx-a11y/media-has-caption */
import { useStyles } from './styles';
import { Box, CircularProgress } from '@material-ui/core';
import { SoundPlayerButton } from './sound-player-button';
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  useLuminator,
  usePlayInsertAudio,
  useSetVoiceParagraphDuration,
} from 'hooks';

export const SoundPlayer = ({
  audioFile,
  refetchBook,
  currentParagraph,
  setCurrentParagraph,
  paragraphList,
  refText,
  isPlaying,
  setIsPlaying,
}) => {
  const classes = useStyles();

  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const audioPlayer = useRef();
  const progressBar = useRef();
  const animationRef = useRef();

  const [mutationDuration] = useSetVoiceParagraphDuration();

  const { setLuminatorProgress } = useLuminator(
    refText,
    currentParagraph,
    isPlaying,
    duration,
    currentTime,
    audioPlayer,
  );

  useEffect(() => {
    if (!audioPlayer?.current?.duration) {
      // force update
      setTimeout(calculateDuration, 1000);
      return;
    }
    calculateDuration();
  }, [
    audioPlayer?.current?.loadedmetadata,
    audioPlayer?.current?.readyState,
    audioPlayer?.current?.duration,
  ]);

  const calculateDuration = () => {
    if (!audioPlayer?.current?.duration) return;
    const seconds = Math.floor(audioPlayer?.current?.duration);
    setDuration(seconds);
    progressBar.current.max = seconds;
  };

  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math?.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  // eslint-disable-next-line no-unused-vars
  const calculateTimeFloat = (secs) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = (secs % 60).toFixed(2);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  };
  const { ListAudioInsert } = usePlayInsertAudio({
    currentTime,
    togglePlayPause,
  });

  const whilePlaying = () => {
    if (!audioPlayer?.current) return;
    progressBar.current.value = audioPlayer?.current?.currentTime;
    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  };

  const changeRange = () => {
    if (!audioPlayer?.current) return;
    audioPlayer.current.currentTime = progressBar?.current?.value;
    setLuminatorProgress(progressBar?.current?.value);
    changePlayerCurrentTime();
  };

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty(
      '--seek-before-width',
      `${(progressBar.current?.value / duration) * 100}%`,
    );
    setCurrentTime(progressBar?.current?.value);
  };

  useEffect(() => {
    let au = document.getElementById('soundGenerealValue');
    au.onloadedmetadata = function () {
      setDuration(Math.floor(au.duration));
      if (paragraphList[currentParagraph]?.audioDuration) return;
      mutationDuration({
        variables: {
          id: parseInt(currentParagraph),
          duration: Math.floor(au.duration),
        },
      }).catch((error) => {
        console.info(error);
      });
    };
  }, [currentParagraph]);

  return (
    <Box className={classes.soundWrapper}>
      <Box style={{ opacity: audioFile ? '1' : '0' }}>
        <audio
          ref={audioPlayer}
          // eslint-disable-next-line no-undef
          src={`${process.env.REACT_APP_API_HOST}/${audioFile}`}
          preload='metadata'
          id='soundGenerealValue'
        ></audio>
        <Box>
          <input
            type='range'
            defaultValue='0'
            ref={progressBar}
            onChange={changeRange}
            style={{ width: '100%' }}
          />
        </Box>
        <Box className={classes.timeWrapper}>
          {/* <Box>{calculateTimeFloat(audioPlayer.current?.currentTime)}</Box> */}
          <Box>{calculateTime(currentTime)}</Box>
          {audioPlayer.current?.duration ? (
            <Box>{duration && !isNaN(duration) && calculateTime(duration)}</Box>
          ) : (
            <CircularProgress />
          )}
        </Box>
      </Box>
      <SoundPlayerButton
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        togglePlayPause={togglePlayPause}
        audioFile={audioFile}
        refetchBook={refetchBook}
        currentParagraph={currentParagraph}
        setCurrentParagraph={setCurrentParagraph}
        paragraphList={paragraphList}
      />
      <Box>{ListAudioInsert}</Box>
    </Box>
  );
};

SoundPlayer.propTypes = {
  audioFile: PropTypes.string,
  refetchBook: PropTypes.func,
  currentParagraph: PropTypes.string,
  setCurrentParagraph: PropTypes.func,
  paragraphList: PropTypes.object,
  refText: PropTypes.any,
  isPlaying: PropTypes.bool,
  setIsPlaying: PropTypes.func,
};
