import { useMutation } from '@apollo/client';
import { DELETE_TEXTBOOK } from 'graphql/mutations';

export const useDeleteTextbook = () => {
  return useMutation(DELETE_TEXTBOOK, {
    onCompleted: (response) => {
      return response;
    },
  });
};
