import { Box, Grid } from '@material-ui/core';
import { ArrowRightDarkIcon, SearchIcon2 } from 'icons';
import PropTypes from 'prop-types';
import useStyles from '../styles';
import { TextField, ButtonCustom } from 'components';
import { useState } from 'react';
import { ButtonSave } from '../button-save';
import { useGetSerchInBaseInserts } from 'hooks';
import { useCreateBookContext, useModalContext } from 'context';
import ReactPlayer from 'react-player/lazy';
import ReactAudioPlayer from 'react-audio-player';

export const SearchParagraph = ({ height, setSearchStatus }) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const { data } = useGetSerchInBaseInserts(value);
  const { handleCreateInsert } = useCreateBookContext();
  const { openModal, closeModal, setModalContent } = useModalContext();

  const handleSelectInsert = (insert) => {
    handleCreateInsert('content', insert.content);
    handleCreateInsert('name', insert.name);
    handleCreateInsert('source', insert.source);
    setSearchStatus(false);
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: `calc(${height} - 40px)`,
      }}
    >
      <Box>
        <Box className={classes.titleSearch}>Поиск по Базе Знаний</Box>
        <TextField
          value={value}
          placeholder='Начните вводить'
          onChange={setValue}
          filterKey='inputValue'
          className={classes.input}
          icon={<SearchIcon2 />}
          name='inputValue'
        />
        <Box className={classes.wrapperText}>
          {data?.serchInBaseInserts?.map((insert, index) => {
            return (
              <Grid className={classes.wrapperInsert} key={insert.id} container>
                <Grid item md={1}>
                  {index + 1}.
                </Grid>
                <Grid item md={7}>
                  {insert.name}
                </Grid>
                <Grid item md={3}>
                  {insert.audioStart}
                </Grid>

                <Grid item md={1}>
                  <ArrowRightDarkIcon
                    onClick={() => {
                      openModal();
                      setModalContent(
                        <InsertModalContent
                          insert={insert}
                          handleSelectInsert={handleSelectInsert}
                          closeModal={closeModal}
                        />,
                      );
                    }}
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Box>
      </Box>
      <ButtonSave />
    </Box>
  );
};

SearchParagraph.propTypes = {
  height: PropTypes.string,
  setSearchStatus: PropTypes.func,
};

const InsertModalContent = ({ insert, closeModal, handleSelectInsert }) => {
  return (
    <div>
      {insert?.type === 'VIDEO' ? (
        <ReactPlayer
          // eslint-disable-next-line no-undef
          url={`${process.env.REACT_APP_API_HOST}/${insert?.source}`}
          width='100%'
          maxHeight='50vh'
          playing={true}
          controls={true}
        />
      ) : null}
      {insert?.type === 'IMAGE' ? (
        <img
          // eslint-disable-next-line no-undef
          src={`${process.env.REACT_APP_API_HOST}/${insert?.source}`}
          alt='insert'
          style={{
            width: '100%',
            maxHeight: '50vh',
          }}
        />
      ) : null}
      {insert?.type === 'AUDIO' ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ReactAudioPlayer
            // eslint-disable-next-line no-undef
            src={`${process.env.REACT_APP_API_HOST}/${insert?.source}`}
            controls
          />
        </Box>
      ) : null}
      {insert?.type === 'TEXT' ? <Box>{insert?.content}</Box> : null}

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
        }}
      >
        <ButtonCustom
          onClick={() => {
            closeModal();
          }}
          reverseStyle={true}
        >
          Отменить
        </ButtonCustom>
        <ButtonCustom
          onClick={() => {
            handleSelectInsert(insert);
            closeModal();
          }}
        >
          Добавить
        </ButtonCustom>
      </Box>
    </div>
  );
};

InsertModalContent.propTypes = {
  insert: PropTypes.object,
  closeModal: PropTypes.func,
  handleSelectInsert: PropTypes.func,
};
