export const wirishApi = {
  parseAfterEditor: (textValue) => {
    const createDomElement = (value) => {
      var newEL = document.createElement('span');
      newEL.innerHTML = value;
      return newEL.outerHTML;
    };
    return createDomElement(window?.WirisPlugin?.Parser?.initParse(textValue));
  },
  parseBeforeEditor: (textValue) => {
    return window?.WirisPlugin?.Parser.endParse(textValue);
  },
};
