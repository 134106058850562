import './App.css';
import { ApolloProvider } from '@apollo/client';

import {
  RouterProvider,
  AsideProvider,
  AuthContextProvider,
  CreateBookProvider,
  ModalProvider,
} from './context';
import { client } from './graphql';

function App() {
  deleteDevEditorIframe();
  return (
    <ApolloProvider client={client}>
      <ModalProvider>
        <AuthContextProvider>
          <CreateBookProvider>
            <AsideProvider>
              <RouterProvider />
            </AsideProvider>
          </CreateBookProvider>
        </AuthContextProvider>
      </ModalProvider>
    </ApolloProvider>
  );
}

export default App;

const deleteDevEditorIframe = () => {
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-use-before-define
    let iframe = document.querySelector('iframe');
    iframe?.remove();
  }
};
