import { TEXT_BOOKS_LIST } from 'graphql/query/index';
import { useQuery } from '@apollo/client';

export const useGetTextBooksList = ({
  searchString,
  selectedSubjectId,
  selectedYear,
  limit,
  offset,
  getOnlyWithStatus,
}) => {
  return useQuery(TEXT_BOOKS_LIST, {
    fetchPolicy: 'cache-and-network',
    variables: {
      options: {
        searchString: searchString,
        selectedSubjectId: selectedSubjectId,
        selectedYear: selectedYear,
        limit,
        offset,
        getOnlyWithStatus,
      },
    },
  });
};
