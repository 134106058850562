import PropTypes from 'prop-types';
import React, { useContext, useReducer, useEffect } from 'react';
import { useGetCurrentUserQuery } from 'hooks/use-queries';
import { client } from 'graphql/client';
import { Box, CircularProgress } from '@material-ui/core';
import { getToken, setToken, removeToken } from 'utils';

export const AuthContext = React.createContext();

export function useAuthContext() {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return authContext;
}

export function AuthContextProvider({ children }) {
  const [getCurrentUser] = useGetCurrentUserQuery({
    onCompleted: (response) => {
      if (response?.currentUser) {
        dispatch({
          type: actionTypes.SUCCESS_AUTH,
          data: response?.currentUser,
        });
      } else {
        dispatch({ type: actionTypes.INIT });
      }
    },
    onError: () => {
      removeToken();
      dispatch({ type: actionTypes.INIT });
    },
  });

  const [state, dispatch] = useReducer(authReducer, {
    isAuth: false,
    isInit: false,
    firstName: '',
    lastName: '',
  });

  useEffect(() => {
    const token = getToken();

    if (token) {
      getCurrentUser();
    } else {
      dispatch({ type: actionTypes.INIT });
    }
  }, [getCurrentUser]);

  const handleSuccessAuth = (token) => {
    setToken(token);
    getCurrentUser();
  };

  const handleLogout = async () => {
    await client.clearStore();
    removeToken();
    dispatch({ type: actionTypes.LOGOUT });
  };

  if (!state.isInit) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isAuth: state.isAuth,
        onSuccessAuth: handleSuccessAuth,
        onLogout: handleLogout,
        userData: state.userData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthContextProvider.propTypes = {
  children: PropTypes.node,
};

const actionTypes = {
  SUCCESS_AUTH: 'SUCCESS_AUTH',
  LOGOUT: 'LOGOUT',
  INIT: 'INIT',
};

function authReducer(state, action) {
  switch (action.type) {
    case actionTypes.INIT: {
      return { ...state, isInit: true };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        isAuth: false,
      };
    }
    case actionTypes.SUCCESS_AUTH: {
      return {
        ...state,
        isAuth: true,
        isInit: true,
        userData: action.data,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
