export const nextParagraph = (paragraphList, currentParagraph, type) => {
  let currentIndex = paragraphList?.paragraphList?.indexOf(currentParagraph);
  if (currentIndex === -1) return 0;
  const length = paragraphList?.paragraphList?.length;
  let newValue;
  if (type === 'next') {
    newValue = currentIndex + 1;
    if (newValue < length) {
      return paragraphList?.paragraphList[newValue];
    }
  } else if (type === 'back') {
    newValue = currentIndex - 1;
    if (newValue >= 0) {
      return paragraphList?.paragraphList[newValue];
    }
  }
  return currentParagraph;
};
