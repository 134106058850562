import PropTypes from 'prop-types';
import React from 'react';

export const PrevTrackIcon = ({ width = 14, height = 14, onClick, style }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      style={style}
    >
      <path
        d='M0.291687 14.6667C0.291687 14.8877 0.379484 15.0996 0.535765 15.2559C0.692045 15.4122 0.904007 15.5 1.12502 15.5C1.34603 15.5 1.558 15.4122 1.71428 15.2559C1.87056 15.0996 1.95835 14.8877 1.95835 14.6667V1.33333C1.95835 1.11232 1.87056 0.900358 1.71428 0.744078C1.558 0.587797 1.34603 0.5 1.12502 0.5C0.904007 0.5 0.692045 0.587797 0.535765 0.744078C0.379484 0.900358 0.291687 1.11232 0.291687 1.33333V14.6667Z'
        fill='#0B486B'
      />
      <path
        d='M15.2917 13.8775C15.2917 15.0642 13.9483 15.7542 12.9833 15.0625L4.25835 8.80666C4.06492 8.66792 3.90839 8.48395 3.80243 8.2708C3.69646 8.05765 3.64429 7.8218 3.65045 7.58385C3.65662 7.34589 3.72094 7.11306 3.83779 6.90568C3.95465 6.69831 4.12049 6.52269 4.32085 6.39416L13.0458 0.79749C14.0167 0.174157 15.2917 0.871656 15.2917 2.02499V13.8775Z'
        fill='#0B486B'
      />
    </svg>
  );
};

PrevTrackIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
  style: PropTypes.object,
};
