import { SIGN_IN } from 'graphql/query/index';
import { useLazyQuery } from '@apollo/client';
import { useAuthContext } from 'context';

export const useSignInQuery = () => {
  const { onSuccessAuth } = useAuthContext();

  return useLazyQuery(SIGN_IN, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      onSuccessAuth(response.signIn.token);
    },
  });
};
