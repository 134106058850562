import { makeStyles } from '@material-ui/core/styles';
import { theme } from 'constants/index';

export const useStyles = makeStyles({
  wrapperOuter: {
    width: '100%',
    display: 'flex',
    padding: '0px 24px',
    flexDirection: 'column',
  },
  wrapperInner: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // marginTop: '40px',
  },
  form: {
    width: '664px',
  },
  twoline: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  selectorWrappr: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: '15px',
  },
  tnputAuthors: {
    width: '435px',
  },
  inputYear: { width: '205px' },
  buttonWrapper: {
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '50px',
  },
  inputDescription: {
    width: '435px',
    height: '280px',
    border: 'none',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    padding: '5px',
  },
  line: {
    marginTop: '10px',
    width: '100%',
    backgroundColor: theme.colors.lightBlue,
    height: '1px',
  },
  iconArrow: {
    marginLeft: '15px',
  },
});
